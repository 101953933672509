import { Injectable } from '@angular/core';
import { AuthService, FeatureFlagManagerService, UserService } from '@services';
import { forkJoin, Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { UserContext } from '@models';

@Injectable({
  providedIn: 'root',
})
export class InitService {
  constructor(
    public authService: AuthService,
    private featureFlagManagerService: FeatureFlagManagerService,
    private userService: UserService
  ) {}

  init() {
    return this.authService.isAuthenticated$
      .pipe(
        switchMap(res => {
          if (!res) {
            return of(false);
          }

          return this.initUser();
        })
      )
      .toPromise();
  }

  initUser(): Observable<[any, any, unknown, UserContext[]]> {
    return this.authService.initializeUserProfile().pipe(
      switchMap(() =>
        forkJoin([
          this.authService.setPermissions(),
          this.authService.setRoles(),
          this.authService.userProfile$.pipe(
            first(),
            switchMap(userProfile =>
              this.featureFlagManagerService.initialize(userProfile)
            )
          ),
          this.userService.getUserContexts(),
        ])
      )
    );
  }
}
