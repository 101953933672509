import gql from 'graphql-tag';

export const projectInfrastructureSummaryQuery = gql`
  query projectInfrastructureSummary($projectName: String!) {
    projectInfrastructureSummary(projectName: $projectName) {
      domains {
        name
        state
        primary
      }
      aliases {
        alias
        recipients
        status
      }
      mailingLists {
        title
        description
        tag
        url
        alias
      }
    }
  }
`;
