import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDropdownConfig, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgPipesModule } from 'angular-pipes';
import { LinkyModule } from 'ngx-linky';
import { TimeAgoPipe } from 'time-ago-pipe';
import { NgxPermissionsModule } from 'ngx-permissions';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgSelectModule } from '@ng-select/ng-select';

import { AlertComponent } from './components/alert/alert.component';
import { BadgeComponent } from './components/badge/badge.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CoverMiniCardComponent } from './components/cover-mini-card/cover-mini-card.component';
import { FoundationProjectCardComponent } from './components/foundation-project-card/foundation-project-card.component';
import { FullCardComponent } from './components/full-card/full-card.component';
import { GroupComponent } from './components/group/group.component';
import { LogoListComponent } from './components/logo-list/logo-list.component';
import { MiniCardComponent } from './components/mini-card/mini-card.component';
import { MiniProjectCardComponent } from './components/mini-project-card/mini-project-card.component';
import { MiniTagsComponent } from './components/mini-tags/mini-tags.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PanelComponent } from './components/panel/panel.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { QuickNavComponent } from './components/quick-nav/quick-nav.component';
import { SectionComponent } from './components/section/section.component';
import { DevAnalyticsFoundationCardComponent } from './components/statistics/dev-analytics-foundation-card/dev-analytics-foundation-card.component';
import { DevAnalyticsLineChartComponent } from './components/statistics/dev-analytics-line-chart/dev-analytics-line-chart.component';
import { DevAnalyticsProjectCardComponent } from './components/statistics/dev-analytics-project-card/dev-analytics-project-card.component';
import { DevAnalyticsSummaryComponent } from './components/statistics/dev-analytics-summary/dev-analytics-summary.component';
import { TogglePillsComponent } from './components/toggle-pills/toggle-pills.component';
import { DraggableDirective } from './directives/draggable.directive';
import { MovableDirective } from './directives/movable.directive';
import { SanitizeHtml } from './pipes/sanitize.pipe';
import { TextShortenPipe } from './pipes/textshorten.pipe';
import { ClickOutsideDirective } from './directives/click-outside';
import { TopBtnComponent } from './components/top-btn/top-btn.component';
import { StatusIndicatorComponent } from './components/status-indicator/status-indicator.component';
import { CrowdfundingListCardComponent } from './components/crowdfunding-list-card/crowdfunding-list-card.component';
import { SliderComponent } from './components/slider/slider.component';
import { CrowdfundingMentorshipCardComponent } from './components/crowdfunding-mentorship-card/crowdfunding-mentorship-card.component';
import { CrowdfundingMeetupCardComponent } from './components/crowdfunding-meetup-card/crowdfunding-meetup-card.component';
import { CrowdfundingFundingCardComponent } from './components/crowdfunding-funding-card/crowdfunding-funding-card.component';
import { FullAddress } from '@lfx/shared/pipes/full-address.pipe';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { SortArrayOfObjectsPipe } from './pipes/sort-array-of-objects.pipe';
import { CrowdfundingBugBountyCardComponent } from './components/crowdfunding-bug-bounty-card/crowdfunding-bug-bounty-card.component';
import { CrowdfundingScholarshipCardComponent } from './components/crowdfunding-scholarship-card/crowdfunding-scholarship-card.component';
import { CrowdfundingMiniCardComponent } from './components/crowdfunding-mini-card/crowdfunding-mini-card.component';
import { BarChartComponent } from './components/charts/bar-chart/bar-chart.component';
import { LineChartComponent } from './components/charts/line-chart/line-chart.component';
import { PieChartComponent } from './components/charts/pie-chart/pie-chart.component';
import { DoughnutChartComponent } from './components/charts/doughnut-chart/doughnut-chart.component';
import { GaugeChartComponent } from './components/charts/gauge-chart/gauge-chart.component';
import { NumberWidgetComponent } from './components/charts/number-widget/number-widget.component';
import { PreviewDocumentModalComponent } from './components/preview-document-modal/preview-document-modal.component';
import { DataGridWidgetComponent } from './components/charts/data-grid-widget/data-grid-widget.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { LfxPermissionsAllDirective } from './directives/lfx-permissions-all.directive';
import { NgxSpinnerModule } from 'ngx-spinner';
import { GridsterModule } from 'angular-gridster2';
import { UtcDatePipe } from './pipes/utc-date.pipe';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { CodeContributionSectionComponent } from '@lfx/modules/company/components/code-contributions-section/code-contributions-section.component';
import { CascadingRowTableComponent } from './components/cascading-row-table/cascading-row-table.component';
import { BubbleChartComponent } from './components/charts/bubble-chart/bubble-chart.component';
import { HighlightPipe } from './pipes/highlight.pipe';
import { AutoCompleteSearchComponent } from './components/autocomplete-search/autocomplete-search.component';
import { DateRangeToggleNavbarComponent } from './components/date-range-toggle-navbar/date-range-toggle-navbar.component';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { AcknowledgementComponent } from './components/popups/acknowledgement/acknowledgement.component';
import { ConfirmationModalComponent } from './components/popups/confirmation/confirmation.component';
import { ConfirmationModalWithoutHeadersComponent } from './components/popups/confirmation-without-headers/confirmation-without-headers.component';
import { InsightsCardComponent } from './components/insights-card/insights-card.component';
import { IconStatusDialogComponent } from './components/icon-status-dialog/icon-status-dialog.component';
import { FilterDropdownComponent } from './components/filter-dropdown/filter-dropdown.component';
import { FilterArrayOfObjectsPipe } from './pipes/filter-array-of-objects.pipe';
import { DomainFormatPipe } from './pipes/domain-format.pipe';
import { IntersectionDirective } from './directives/intersection.directive';

@NgModule({
  declarations: [
    PanelComponent,
    PageHeaderComponent,
    BreadcrumbComponent,
    AcknowledgementComponent,
    AlertComponent,
    SectionComponent,
    GroupComponent,
    MiniCardComponent,
    FullCardComponent,
    TogglePillsComponent,
    LogoListComponent,
    BadgeComponent,
    MiniTagsComponent,
    ProgressBarComponent,
    TimeAgoPipe,
    FoundationProjectCardComponent,
    CoverMiniCardComponent,
    PaginationComponent,
    SanitizeHtml,
    TextShortenPipe,
    MovableDirective,
    DraggableDirective,
    QuickNavComponent,
    MiniProjectCardComponent,
    DevAnalyticsLineChartComponent,
    DevAnalyticsProjectCardComponent,
    DevAnalyticsSummaryComponent,
    DevAnalyticsFoundationCardComponent,
    TopBtnComponent,
    StatusIndicatorComponent,
    ClickOutsideDirective,
    TopBtnComponent,
    CrowdfundingListCardComponent,
    SliderComponent,
    CrowdfundingMentorshipCardComponent,
    CrowdfundingMeetupCardComponent,
    CrowdfundingFundingCardComponent,
    FullAddress,
    ShortNumberPipe,
    SortArrayOfObjectsPipe,
    CrowdfundingBugBountyCardComponent,
    CrowdfundingScholarshipCardComponent,
    CrowdfundingMiniCardComponent,
    BarChartComponent,
    LineChartComponent,
    PieChartComponent,
    DoughnutChartComponent,
    GaugeChartComponent,
    NumberWidgetComponent,
    PreviewDocumentModalComponent,
    DataGridWidgetComponent,
    DateFormatPipe,
    DomainFormatPipe,
    LfxPermissionsAllDirective,
    UtcDatePipe,
    PaginatorComponent,
    CodeContributionSectionComponent,
    CascadingRowTableComponent,
    BubbleChartComponent,
    HighlightPipe,
    AutoCompleteSearchComponent,
    DateRangeToggleNavbarComponent,
    EllipsisPipe,
    ConfirmationModalComponent,
    ConfirmationModalWithoutHeadersComponent,
    InsightsCardComponent,
    IconStatusDialogComponent,
    FilterDropdownComponent,
    FilterArrayOfObjectsPipe,
    IntersectionDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgPipesModule,
    LinkyModule,
    RouterModule,
    NgxPermissionsModule.forChild(),
    PdfViewerModule,
    NgxDatatableModule,
    NgxSpinnerModule,
    GridsterModule,
    NgSelectModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    PanelComponent,
    NgbModule,
    PageHeaderComponent,
    BreadcrumbComponent,
    AlertComponent,
    SectionComponent,
    GroupComponent,
    MiniCardComponent,
    FullCardComponent,
    TogglePillsComponent,
    LogoListComponent,
    FoundationProjectCardComponent,
    BadgeComponent,
    MiniTagsComponent,
    ProgressBarComponent,
    NgPipesModule,
    TimeAgoPipe,
    CoverMiniCardComponent,
    PaginationComponent,
    SanitizeHtml,
    TextShortenPipe,
    MovableDirective,
    QuickNavComponent,
    MiniProjectCardComponent,
    DevAnalyticsLineChartComponent,
    DevAnalyticsProjectCardComponent,
    DevAnalyticsSummaryComponent,
    DevAnalyticsFoundationCardComponent,
    TopBtnComponent,
    StatusIndicatorComponent,
    CrowdfundingListCardComponent,
    SliderComponent,
    CrowdfundingScholarshipCardComponent,
    CrowdfundingMentorshipCardComponent,
    CrowdfundingMeetupCardComponent,
    CrowdfundingFundingCardComponent,
    ClickOutsideDirective,
    FullAddress,
    ShortNumberPipe,
    SortArrayOfObjectsPipe,
    CrowdfundingBugBountyCardComponent,
    CrowdfundingMiniCardComponent,
    NgxPermissionsModule,
    BarChartComponent,
    LineChartComponent,
    PieChartComponent,
    DoughnutChartComponent,
    GaugeChartComponent,
    NumberWidgetComponent,
    PreviewDocumentModalComponent,
    DataGridWidgetComponent,
    NgxDatatableModule,
    LfxPermissionsAllDirective,
    NgxSpinnerModule,
    GridsterModule,
    UtcDatePipe,
    PaginatorComponent,
    CodeContributionSectionComponent,
    CascadingRowTableComponent,
    BubbleChartComponent,
    HighlightPipe,
    AutoCompleteSearchComponent,
    DateRangeToggleNavbarComponent,
    EllipsisPipe,
    InsightsCardComponent,
    IconStatusDialogComponent,
    DomainFormatPipe,
    FilterDropdownComponent,
    FilterArrayOfObjectsPipe,
    IntersectionDirective,
  ],
  providers: [NgbDropdownConfig, SortArrayOfObjectsPipe],
  entryComponents: [
    AcknowledgementComponent,
    ConfirmationModalComponent,
    ConfirmationModalWithoutHeadersComponent,
  ],
})
export class SharedModule {}
