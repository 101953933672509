import gql from 'graphql-tag';

export const foundationProjectVulnerabilityPackages = gql`
  query repositoriesVulnerabilityPackagesDetails(
    $input: VulnerabilityPackagesDetailsInput!
  ) {
    repositoriesVulnerabilityPackagesDetails(input: $input) {
      dependencyName
      dependencyVersion
      licenses
      repositoryName
      issueHighCount
      issueLowCount
      issueMediumCount
      issueTotalCount
      repositories {
        dependencyId
        dependencyName
        dependencyVersion
        rootDependency
        licenses
        repositoryId
        repositoryName
        issueHighCount
        issueLowCount
        issueMediumCount
        issueTotalCount
        rootDependency
      }
    }
  }
`;
