import gql from 'graphql-tag';

export const unlinkSocialIdentityMutation = gql`
  mutation unlinkUserIdentity($input: LinkUserIdentityInput!) {
    unlinkUserIndentity(input: $input) {
      code
      message
      success
      identities {
        userId
        provider
        connection
        isSocial
      }
    }
  }
`;
