import gql from 'graphql-tag';

export const projectSecurityDetailsQuery = gql`
  query rootSecurityProjectDetails($input: SecurityProjectDetailsInput) {
    rootSecurityProjectDetails(input: $input) {
      repositories {
        dependencies {
          dependencyId
          licenses
          name
          repositoryId
          repositoryFolderpathId
          rootDependency
          version
        }
        folderPaths
        name
        repositoryId
        url
      }
    }
  }
`;
