import gql from 'graphql-tag';

export const addressesQuery = gql`
  query OrganizationAdresses($organizationId: ID!) {
    organizationAdresses(organizationId: $organizationId) {
      id
      title
      city
      country
      postalCode
      state
      street
    }
  }
`;
