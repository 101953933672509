import gql from 'graphql-tag';

export const updateProjectCommitteeMutation = gql`
  mutation updateProjectCommittee(
    $committeeInfo: UpdateProjectCommitteeInput!
  ) {
    updateProjectCommittee(input: $committeeInfo) {
      code
      message
      success
      committee {
        id
        collaborationName
        name
        description
        category
        url
      }
    }
  }
`;
