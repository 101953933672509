import { TemplateRef } from '@angular/core';

export * from './autocomplete.interfaces';

export interface DataCell {
  text?: string;
  subtext?: string;
  bold?: boolean;
  image?: string;
  expandAction?: boolean;
  username?: string;
  color?: string;
  border?: boolean;
  defaultIcon?: string;
  tooltipText?: string;
  extraText?: {
    text: string;
    style?: extraTextStyle;
    bold?: boolean;
  };
  callToAction?: {
    prefixText?: string;
    actionText: string;
  };
}
export interface DataRow {
  [key: string]: DataCell | string | number | boolean | DataRow[];
  children?: DataRow[];
}
export interface DataActionEvent {
  item: DataRow;
  parent?: DataRow | null;
}
export interface CallToActionEvent {
  column: Column;
  row: DataRow;
}
export interface Column {
  name: string;
  prop: string;
  type?: string;
  hideInChild?: boolean;
  expandAction?: boolean;
  subTextEllipsis?: number;
  textEllipsis?: number;
  flex?: number;
  flexBasis?: string;
  template?: TemplateRef<any>;
  filterData?: FilterInput;
}

export interface FilterData {
  id: string;
  label: string;
  image?: string;
  checked?: boolean;
}

export interface FilterInput {
  data: FilterData[];
  title?: string;
}

export interface FilterOutput {
  column: Column;
  filter: null | FilterData[];
}

export enum extraTextStyle {
  warning = 'warning',
  gray = 'gray',
}
