import gql from 'graphql-tag';

export const createAddressMutation = gql`
  mutation createAddress($input: CreateAddressInput!) {
    createAddress(input: $input) {
      code
      message
      success
      address {
        id
        city
        country
        postalCode
        state
        street
      }
    }
  }
`;
