import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { projectInfrastructureSummaryQuery } from '../queries';
import { addEmailAliasMutation } from '../mutations';

import { AddAliasInput } from './inputs';

@Injectable({
  providedIn: 'root',
})
export class ProjectInfrastructureServiceGql {
  constructor(private apollo: Apollo) {}

  getProjectInfrastructureSummary(projectName: string): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: projectInfrastructureSummaryQuery,
        variables: {
          projectName,
        },
      })
      .valueChanges.pipe(map(res => res.data.projectInfrastructureSummary));
  }

  addEmailAlias(input: AddAliasInput): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: addEmailAliasMutation,
        variables: {
          input,
        },
      })
      .pipe(map(res => res.data.addEmailAlias));
  }
}
