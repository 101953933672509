import gql from 'graphql-tag';

export const uploadFileMutation = gql`
  mutation uploadContractDoc($file: Upload!) {
    uploadContractDoc(file: $file) {
      filename
      fileUrl
    }
  }
`;
