import gql from 'graphql-tag';

export const foundationActivityLogsQuery = gql`
  query claActivityLogs($input: FoundationActivityLogsInput) {
    claActivityLogs(input: $input) {
      message
      tip
      name
      logoUrl
      createdAt
    }
  }
`;
