import { NgModule } from '@angular/core';
import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';
import { parse, stringify } from 'flatted';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { Apollo, ApolloModule } from 'apollo-angular';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { environment } from '@environment';
import { HttpHeaders } from '@angular/common/http';
import introspectionQueryResultData from './core/gql/fragmentTypes';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

@NgModule({
  declarations: [],
  imports: [ApolloModule, HttpLinkModule],
})
export class ApolloClientsModule {
  constructor(private apollo: Apollo, private httpLink: HttpLink) {
    const error = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, path }) => {
          // eslint-disable-next-line no-console
          console.log(`[GraphQL error]: Message: ${message}, Path: ${path}`);
        });
      }

      if (networkError) {
        // eslint-disable-next-line no-console
        console.log(`[Network error]: ${networkError}`);
      }
    });

    // Because of https://github.com/apollographql/apollo-client/issues/1913
    // I have added this Apollo Link
    // It will run with each request to clean __typename or typename properties
    // They are injected by Apollo in our variables during mutations which make them fail
    // So, this is a workaround to clear them up during mutation call
    // It will handle also the upload mutations as well
    // Solution taken from https://stackoverflow.com/a/62348728
    // It can be disabled in MemoryCache but this will affect apollo performance!

    const cleanTypeName = new ApolloLink((operation, forward) => {
      const omitTypename = (key, value) =>
        key === '__typename' || key === 'typename' ? undefined : value;

      if (operation.variables && !operation.getContext().hasUpload) {
        operation.variables = parse(
          stringify(operation.variables),
          omitTypename
        );
      }

      return forward(operation);
    });

    apollo.create(
      {
        link: ApolloLink.from([
          cleanTypeName,
          error,
          httpLink.create({
            uri: environment.graphqlUrl,
            headers: new HttpHeaders({
              'Time-Zone':
                Intl &&
                Intl.DateTimeFormat &&
                Intl.DateTimeFormat().resolvedOptions().timeZone,
            }),
          }),
        ]),
        cache: new InMemoryCache({ fragmentMatcher }),
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
          },
          query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
          },
        },
      },
      'default'
    );
  }
}
