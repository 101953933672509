import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { EventDetails } from '@models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  constructor(private httpClient: HttpClient) {}

  public getEventDetails(): Observable<EventDetails> {
    const route = `${environment.apiUrl}/dashboard/events/events-details`;

    return this.httpClient.get<EventDetails>(route);
  }
}
