import gql from 'graphql-tag';

export const claEnabledFoundationsQuery = gql`
  query claEnabledFoundations {
    claEnabledFoundations {
      id
      name
      logoUrl
      projectType
      parent
      foundation {
        id
        name
      }
      projects {
        id
        name
        logoUrl
        projectType
      }
    }
  }
`;
