import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { pageSettings } from '@config';

@Component({
  selector: 'lfx-help-support-frame',
  templateUrl: './help-support-frame.component.html',
  styleUrls: ['./help-support-frame.component.scss'],
})
export class HelpSupportFrameComponent implements OnInit, OnChanges {
  @Input() iframeSrc: string;
  url: SafeResourceUrl;
  pageSettings = pageSettings;

  constructor(public sanitizer: DomSanitizer) {}

  ngOnInit() {}

  ngOnChanges(changes) {
    if (changes.iframeSrc && this.iframeSrc) {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeSrc);
    }
  }

  closeHelpSupportFrame(): void {
    this.pageSettings.pageHelpSupportFrame = false;
    this.pageSettings.pageHelpMenu = false;
  }
}
