<!-- begin #sidebar -->
<div id="sidebar" class="sidebar"
     [ngClass]="{ 'sidebar-transparent': this.pageSidebarTransparent, 'isCollapsed': isCollapsed}">
  <!-- begin sidebar scrollbar -->
  <div class="height-full" #sidebarScrollbar *ngIf="desktopMode" (scroll)="onScroll($event)">
    <ng-container *ngTemplateOutlet="sidebarNav"></ng-container>
  </div>
  <div class="mobile-scroll" *ngIf="mobileMode" (scroll)="onScroll($event)">
    <ng-container *ngTemplateOutlet="sidebarNav"></ng-container>
  </div>
  <!-- end sidebar scrollbar -->
  <ng-template #sidebarNav>
    <ngx-spinner bdColor="#f2f2f2" color="#17a2b8"  size="large"></ngx-spinner>
    <ul class="nav fixed-infobox">
      <li class="nav-company-details" [ngClass]="{ 'active' : (navProfileState == 'expand') }" *ngIf="isLoggedIn">
        <div [ngSwitch]="currentContext">
          <ng-template [ngSwitchCase]="userContextEnum.Admin">
            <img class="context-image" src="https://lfx-dev-mocks.s3.amazonaws.com/admin.jpg">
            <div class="context-name">{{ profile.firstName }} {{ profile.lastName }}}</div>
            <div class="context-title">{{ currentContext | titlecase }}</div>
          </ng-template>
          <ng-template *ngIf="myCompany$ | async as myCompany" ngSwitchDefault>
            <span *ngIf="myCompany.name !== individualNoAccount">
              <img *ngIf="myCompany.logoUrl" class="company-image" [src]="myCompany.logoUrl">
            </span>
            <div *ngIf="myCompany.name === individualNoAccount; else nonIndividualNoAccount"
                 class="company-title">{{ currentContext | titlecase }}</div>
            <ng-template #nonIndividualNoAccount>
              <div class="company-title">{{ myCompany.name }}</div>
            </ng-template>
          </ng-template>
        </div>
      </li>
      <li class="nav-profile" [ngClass]="{ 'active' : (navProfileState == 'expand') }" *ngIf="!isLoggedIn">
        <div class="image image-icon bg-white text-grey-darker float-left">
          <img src="/assets/img/anonymous.jpeg">
        </div>
        <div class="info anonymous-info">
          <div class="context-name current-context">Visitor</div>
          <div>Anonymous User</div>
        </div>
      </li>
    </ul>
    <div class="scrollable-content">
      <!-- begin sidebar nav -->
      <ul class="nav nav-header-top">
        <!-- render menu item -->
        <!-- sidebar menu -->
        <ng-container *ngFor="let menu of menus">
          <ng-template [lfxPermissionsAll]="menu.permissions?.all" [ngxPermissionsOnly]="menu.permissions?.any"
                       [ngxPermissionsExcept]="menu.permissions?.except">
            <li [routerLinkActive]="menu.submenu ? 'none' : 'active'"
                #rla1="routerLinkActive"
                [routerLinkActiveOptions]="{exact: false}"
                [ngClass]="{'expand': (rla1.isActive && !menu.state) || menu.state === 'expand'}">
              <a
                *ngIf="!menu.url"
                (click)="(menu.submenu || menu.autoComplete) ? toggleSubmenu(menu, rla1) : '';">
                <ng-container *ngTemplateOutlet="sidebarMenu; context: {menu: menu}"></ng-container>
              </a>
              <a *ngIf="menu.url" [routerLink]="menu.url"
                 (click)="(menu.submenu || menu.autoComplete) ? toggleSubmenu(menu, rla1) : ''; (menu.updateMenu && menu.id)? updateProjectMenu(menu.id): ''">
                <ng-container *ngTemplateOutlet="sidebarMenu; context: {menu: menu}"></ng-container>
              </a>

              <!-- sidebar submenu lvl-1 -->
              <div [class.overflow-visible]="menu.state === 'expand' && menu.autoComplete"
                   [@expandCollapse]="(menu.state) ? menu.state : expandCollapse(menu, rla1)"
                   *ngIf="menu.submenu || menu.autoComplete">
                <div class="form-group" *ngIf="menu.autoComplete">
                  <lfx-autocomplete-search
                    [textThreshold]="2"
                    [placeholderText]="'Search'"
                    [usedFor]="'search-project'"
                    (searchInput)="onAutoCompleteSearchInput($event, menu)"
                    [searchResult$]="getAutoCompleteSearchResultObservable(menu)"
                    (selectedItem)="autoCompleteSelection($event, menu)"
                  ></lfx-autocomplete-search>
                </div>
                <div class="height-100 position-relative" *ngIf="checkIsLoading(menu)">
                  <ngx-spinner [fullScreen]="false" [name]="'menu-'+menu.title+'-spinner'" color="#17a2b8" bdColor="rgba(0,0,0,0)" size="medium"></ngx-spinner>
                </div>
                <ul *ngIf="!checkIsLoading(menu) && menu.submenu" class="sub-menu level-1"
                    [ngStyle]="{'margin-top.px': (this.pageSettings.pageSidebarMinified) ? - (scrollTop) : 0 }">
                  <ng-container *ngFor="let sub1 of menu.submenu">
                    <ng-template [lfxPermissionsAll]="sub1.permissions?.all"
                                 [ngxPermissionsOnly]="sub1.permissions?.any"
                                 [ngxPermissionsExcept]="sub1.permissions?.except">
                      <li [routerLinkActive]="sub1.submenu ? 'none' : 'active'" #rla2="routerLinkActive"
                          [ngClass]="{'expand': (rla2.isActive && !sub1.state) || sub1.state === 'expand', 'last-child': !sub1.submenu }">
                        <a *ngIf="!sub1.url" (click)="(sub1.submenu) ? toggleSubmenu(sub1, rla2) : ''">
                          <ng-container *ngTemplateOutlet="sidebarSubMenu; context: {menu: sub1}"></ng-container>
                        </a>
                        <a *ngIf="sub1.url" [routerLink]="sub1.url"
                           (click)="(sub1.submenu) ? toggleSubmenu(sub1, rla2) : ''; (sub1.updateMenu && sub1.id)? updateProjectMenu(sub1.id): ''">
                          <ng-container
                            *ngTemplateOutlet="sidebarSubMenu; context: {menu: sub1, parentTitle: menu.title}">
                          </ng-container>
                        </a>
                        <!-- sidebar submenu lvl-2 -->
                        <div [@expandCollapse]="(sub1.state) ? sub1.state : expandCollapse(sub1, rla2)"
                             *ngIf="sub1.submenu">
                          <ul class="sub-menu level-2">
                            <ng-container *ngFor="let sub2 of sub1.submenu">
                              <ng-template [lfxPermissionsAll]="sub2.permissions?.all"
                                           [ngxPermissionsOnly]="sub2.permissions?.any"
                                           [ngxPermissionsExcept]="sub2.permissions?.except">
                                <li [routerLinkActive]="sub2.submenu ? 'none' : 'active'" #rla3="routerLinkActive"
                                    [ngClass]="{ 'expand': (sub2.state == 'expand'), 'last-child': !sub2.submenu }">
                                  <a *ngIf="!sub2.url" (click)="(sub2.submenu) ? toggleSubmenu(sub2, rla3) : ''">
                                    <ng-container
                                      *ngTemplateOutlet="sidebarSubMenu; context: {menu: sub2}"></ng-container>
                                  </a>
                                  <a *ngIf="sub2.url" [routerLink]="sub2.url">
                                    <ng-container
                                      *ngTemplateOutlet="sidebarSubMenu; context: {menu: sub2, parentTitle: menu.title}"></ng-container>
                                  </a>

                                  <!-- sidebar submenu lvl-3 -->
                                  <div
                                    [@expandCollapse]="(sub2.state) ? sub2.state : ((rla3.isActive && !sub2.state) ? 'active' : 'collapse')"
                                    *ngIf="sub2.submenu">
                                    <ul class="sub-menu level-3">
                                      <ng-container *ngFor="let sub3 of sub2.submenu">
                                        <ng-template [lfxPermissionsAll]="sub3.permissions?.all"
                                                     [ngxPermissionsOnly]="sub3.permissions?.any"
                                                     [ngxPermissionsExcept]="sub3.permissions?.except">
                                          <li [routerLinkActive]="sub3.submenu ? 'none' : 'active'" #rla4="routerLinkActive"
                                              [ngClass]="{ 'expand': (sub3.state == 'expand'), 'last-child': !sub3.submenu }">
                                            <a *ngIf="!sub3.url"
                                               (click)="(sub3.submenu) ? toggleSubmenu(sub3, rla4) : ''">
                                              <ng-container *ngTemplateOutlet="sidebarSubMenu; context: {menu: sub3}">
                                              </ng-container>
                                            </a>
                                            <a *ngIf="sub3.url" [routerLink]="sub3.url">
                                              <ng-container *ngTemplateOutlet="sidebarSubMenu; context: {menu: sub3}">
                                              </ng-container>
                                            </a>
                                          </li>
                                        </ng-template>
                                      </ng-container>
                                    </ul>
                                  </div>
                                </li>
                              </ng-template>
                            </ng-container>
                          </ul>
                        </div>
                      </li>
                    </ng-template>
                  </ng-container>
                </ul>
              </div>
            </li>
          </ng-template>
        </ng-container>

        <!-- begin sidebar minify button -->
        <!-- <li><a href="javascript:;" class="sidebar-minify-btn" (click)="toggleMinified()"><i
              class="fas fa-angle-double-left"></i></a></li> -->
        <!-- end sidebar minify button -->
      </ul>
    </div>
    <!-- end sidebar nav -->
  </ng-template>
</div>
<div class="collapse-btn" [class.isCollapsed]="isCollapsed" (click)="isCollapsed = !isCollapsed">
  <i class="fas fa-arrow-right" [class.d-none]="!isCollapsed"></i>
  <i class="fas fa-arrow-left" [class.d-none]="isCollapsed"></i>
</div>
<!-- end #sidebar -->

<ng-template #sidebarMenu let-menu="menu">
  <span class="badge pull-right" *ngIf="menu.badge">{{ menu.badge }}</span>
  <b class="fas fa-angle-right menu-arrow" [class.expanded]="menu.state === 'expand'" *ngIf="menu.caret"></b>
  <div class="icon-img" *ngIf="menu.img"><img src="{{ menu.img }}"/></div>
  <i class="menu-icon {{ menu.icon }}" *ngIf="menu.icon"></i>
  <span class="menu-title" [title]="menu.title || ''" *ngIf="menu.title" [ngClass]="{'title-width': !(minified)}">
            {{ menu.title }}
    <i class="fas fa-info-circle tooltip-icon"
       *ngIf="menu.toolTipText && menu.state === 'expand'"
       [ngbPopover]="menu.toolTipText"
       triggers="mouseenter:mouseleave"
       popoverClass="tooltip-popover"
       placement="right"
    ></i>
    <span class="label label-theme m-l-5" *ngIf="menu.label">{{ menu.label }}</span>
          </span>
</ng-template>

<!-- render submenu item -->
<ng-template #sidebarSubMenu let-menu="menu">
  <i class="fas fa-star" *ngIf="menu.favorite"></i>
  <b class="fas fa-angle-right menu-arrow mt-1" [class.expanded]="menu.state === 'expand'"
     *ngIf="menu.caret"></b>
  <b ngbDropdown #tooltipMenu="ngbDropdown" class="d-inline-block tooltip-menu-wrapper" placement="right-top">
    <span ngbDropdownAnchor (mouseenter)="tooltipMenu.toggle()" class="menu-tooltip-text"
          *ngIf="menu.hoverList && menu.hoverList.text">{{menu.hoverList.text}}</span>
    <span ngbDropdownMenu class="tooltip-menu">
      <ng-container *ngIf="menu.hoverList && menu.hoverList.list">
        <ng-container *ngTemplateOutlet="tooltipList; context: {list: menu.hoverList.list}"></ng-container>
      </ng-container>
    </span>
  </b>
  <i class="menu-icon {{ menu.icon }}" *ngIf="menu.icon"></i>
  <span class="menu-title" [title]="menu.title || ''">
              {{ menu.title }}
          </span>
  <i class="fas fa-paper-plane text-theme m-l-5" *ngIf="menu.highlight"></i>
</ng-template>

<ng-template #tooltipList let-list="list">
  <div class="tooltip-list" (click)="$event.stopPropagation();" *ngIf="{showMore: true, slice: 7}; let localVars">
    <div >
      <a
        [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{exact: true}"
        class="tooltip-list-title"
        #titleLink='routerLinkActive'
        [title]="list.parent.title"
        [routerLink]="list.parent.url"
        (click)="!titleLink.isActive? updateProjectMenu(list.parent.id): ''"
      >
        {{list.parent.title}}
      </a>
      <ul>
        <li
          class="tooltip-list-item"
          [title]="item.title"
          *ngFor="let item of list.list | slice:0:localVars.slice"
          >
          <a
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{exact: true}"
            [routerLink]="item.url"
            #itemLink='routerLinkActive'
            (click)="!itemLink.isActive?updateProjectMenu(item.id): ''"
          >
            {{item.title}}
          </a>
        </li>
        <li class="show-more-btn" *ngIf="localVars.showMore && list.list.length > localVars.slice">
          <span class="action-link cursor-pointer" (click)="localVars.slice = list.list.length; localVars.showMore = false">
            Show More...
          </span>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
