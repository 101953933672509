import gql from 'graphql-tag';

export const projectSecurityIssuesQuery = gql`
  query rootSecurityIssues($id: ID!) {
    rootSecurityIssues(projectId: $id) {
      cveIdentifiers
      cweIdentifiers
      dependencyId
      dependencyName
      overview
      references
      remediation
      severity
      snykId
      status
      title
      repositoryId
      isFixable
    }
  }
`;
