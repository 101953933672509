<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ title }}</h4>
</div>
<div class="modal-body">
  <div class="modal-confirm">
    {{ message }}
  </div>
</div>
<div class="modal-footer">
  <div class="button-modal btn-danger" (click)="confirm()">
    <span class="mr-2">{{ confirmLabel }}</span>
    <i class="fa fa-spinner fa-spin" *ngIf="isConfirmLoading"></i>
  </div>
  <div class="button-modal" (click)="reject()">
    <span class="mr-2">{{ rejectLabel }}</span>
    <i class="fa fa-spinner fa-spin" *ngIf="isRejectLoading"></i>
  </div>
</div>
