import gql from 'graphql-tag';

export const foundationMeetingsQuery = gql`
  query foundationMeeting($id: ID!) {
    foundationMeeting(id: $id) {
      pastMeeting {
        name
        date
      }
      upcoming {
        name
        date
      }
    }
  }
`;
