// tslint:disable: max-line-length
import { mockList } from '@lfx/core/mocks/generator';
import faker from 'faker';

import { convertObjectToArray } from '../helper';
import { DataSet } from '@lfx/shared/components/charts/models';
import { imagePlaceholders } from '@config';

export interface StatisticsDashboardNav {
  trends: StatisticsDashboard[];
  pipelines: StatisticsDashboard[];
}

export interface StatisticsFilter {
  name: string;
  options: StatisticsFilterOption[];
}

export interface StatisticsFilterOption {
  key: string;
  value: string;
  networkValue?: string;
  default?: boolean;
}
export interface StatisticsDashboard {
  id: string;
  title: string;
  filter?: StatisticsFilter;
  visualizations: (
    | StatisticsVisualization
    | NetworkVisualization
    | ChartsVisualization
    | GaugeVisualization
    | NumberVisualization
    | DataGridVisualization
  )[];
}

export interface Visualizations {
  id: string;
  type: string;
  title: string;
  width?: WidthOptions;
  rows: number; // i.e. 1,2,3
}

export interface StatisticsVisualization extends Visualizations {
  url: string;
  drilldownDashboardId?: string;
}

export interface NetworkVisualization extends Visualizations {
  dbUrl: string;
  username: string;
  password: string;
  query: string;
}
export interface ChartsVisualization extends Visualizations {
  labels: (number | string)[];
  dataSets: DataSet[];
}
interface GaugeZone {
  strokeStyle: string; // hex color
  min: number;
  max: number;
}
export interface GaugeVisualization extends Visualizations {
  value: number;
  min?: number;
  max?: number;
  zones?: GaugeZone[];
  labelsDivision?: number;
}
export interface NumberVisualization extends Visualizations {
  value: number;
  prefix?: string;
  suffix?: string;
  color?: string;
}
export interface DataGridVisualization extends Visualizations {
  columns: {
    prop: string;
    name: string;
    type?: string;
    pipe?: any;
  }[];
  data: {
    [key: string]: any;
  }[];
}

export enum WidthOptions {
  Fill = 'fill',
  OneThird = 'onethird',
  TwoThird = 'twothird',
  OneFourth = 'onefourth',
  Half = 'half',
  ThreeFourth = 'threefourth',
}

export const widthOptionsToClass = (width: WidthOptions): string => {
  switch (width) {
    case WidthOptions.Fill:
      return 'col-12';
    case WidthOptions.OneThird:
      return 'col-4';
    case WidthOptions.OneFourth:
      return 'col-3';
    case WidthOptions.TwoThird:
      return 'col-8';
    case WidthOptions.Half:
      return 'col-6';
    case WidthOptions.ThreeFourth:
      return 'col-9';
  }
};

const buildDataForDataGrid = (): DataGridVisualization => {
  const m = {
    type: 'data-grid',
    id: 'data-grid-sample',
    title: 'DataGrid sample',
    rows: 4,
    width: WidthOptions.Fill,
    columns: [
      {
        prop: 'name',
        name: 'Name',
      },
      {
        prop: 'value',
        name: 'Number of visits',
        type: 'currency',
      },
      {
        prop: 'created',
        name: 'Date',
        type: 'date',
      },
      {
        prop: 'created',
        name: 'Time',
        type: 'time',
      },
      {
        prop: 'created',
        name: 'DateTime',
        type: 'datetime',
      },
    ],
    data: [],
  };

  for (let i = 0; i < 35; i++) {
    m.data.push({
      name: faker.name.findName(),
      value: faker.random.number(),
      created: faker.date.past(),
    });
  }

  return m;
};

export const demoStatisticsDashboards: Record<string, StatisticsDashboard> = {
  trends: {
    id: 'trends',
    title: 'Technical Trends',
    filter: {
      name: 'date',
      options: [
        {
          key: '1D',
          value: 'from:now-1d,mode:relative,to:now',
          networkValue: '{ "name": "Janina Szkut" }',
        },
        {
          key: '7D',
          value: 'from:now-7d,mode:relative,to:now',
          networkValue: '{ "name": "David Deal" }',
        },
        {
          key: '1M',
          value: 'from:now-1M,mode:relative,to:now',
          networkValue: '{ "name": "sruti" }',
        },
        {
          key: '3M',
          value: 'from:now-3M,mode:relative,to:now',
          networkValue: '{ "name": "Tim Fong" }',
        },
        {
          key: '6M',
          value: 'from:now-6M,mode:relative,to:now',
          networkValue: '{ "name": "Nirav Patel" }',
        },
        {
          key: '1Y',
          value: 'from:now-1y,mode:relative,to:now',
          networkValue: '{ "name": "Bryan Stone" }',
        },
        {
          key: '3Y',
          value: 'from:now-3y,mode:relative,to:now',
          networkValue: '{ "name": "Prasanna Mahajan" }',
        },
        {
          key: '5Y',
          value: 'from:now-5y,mode:relative,to:now',
          networkValue: '{ "name": "Fayaz" }',
          default: true,
        },
      ],
    },
    visualizations: [
      {
        id: 'technicaltrends',
        type: 'embedded',
        title: 'Technical Trends',
        // tslint:disable-next-line: quotemark
        url:
          // eslint-disable-next-line max-len
          'https://search-lfelastic-6tggavddppj6ejf6ulxdaipiyy.us-east-2.es.amazonaws.com/_plugin/kibana/app/kibana#/dashboard/ae0ca4c0-03f0-11ea-a835-ff066f096e7b?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A({{filter}}))',
        width: WidthOptions.Fill,
        rows: 1,
      },
      {
        id: 'codecontrib',
        type: 'embedded',
        title: 'Code Contribution',
        // tslint:disable-next-line: quotemark
        url:
          // eslint-disable-next-line max-len
          'https://search-lfelastic-6tggavddppj6ejf6ulxdaipiyy.us-east-2.es.amazonaws.com/_plugin/kibana/app/kibana#/dashboard/74b66860-0444-11ea-a835-ff066f096e7b?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A({{filter}}))',
        width: WidthOptions.Fill,
        rows: 4,
      },
      {
        id: 'ecoysystem',
        type: 'embedded',
        title: 'Ecosystem',
        // tslint:disable-next-line: quotemark
        url:
          // eslint-disable-next-line max-len
          'https://search-lfelastic-6tggavddppj6ejf6ulxdaipiyy.us-east-2.es.amazonaws.com/_plugin/kibana/app/kibana#/dashboard/61a79ff0-0458-11ea-92c3-17d4faada409?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A({{filter}}))',
        width: WidthOptions.Fill,
        rows: 4,
      },
      {
        id: 'pipeline',
        type: 'network',
        title: 'Pipelines',
        // tslint:disable-next-line: quotemark
        width: WidthOptions.Fill,
        rows: 1,
        dbUrl: 'bolt://hobby-aipeieehdlpbgbkebibdocdl.dbs.graphenedb.com:24787',
        username: 'neo4j',
        password: 'b.nTGCVFewglWN.lsWTLv3LBWbAwduy',
        query:
          'MATCH (n:Person {name: {name}})-[m:merged_pr]->(p:Pull_Request)<-[cr:created_pr]-(c:Person) RETURN n,p,c,m,cr',
      },
      {
        id: 'lineSample',
        type: 'line',
        title: 'Line Chart Sample',
        width: WidthOptions.Half,
        rows: 1,
        labels: ['Sat', 'Sun', 'Mon', 'Tue'],
        dataSets: [
          {
            label: 'Data 1',
            data: [1, 5, 2, 8],
          },
          {
            label: 'Data 2',
            data: [8, 4, 3, 6],
          },
        ],
      },
      {
        id: 'barSample',
        type: 'bar',
        title: 'Bar Chart Sample',
        width: WidthOptions.Half,
        rows: 1,
        labels: ['Sat', 'Sun', 'Mon', 'Tue'],
        dataSets: [
          {
            label: 'Data 1',
            data: [1, 5, 2, 8],
          },
          {
            label: 'Data 2',
            data: [8, 4, 3, 6],
          },
        ],
      },
      {
        id: 'pieSample',
        type: 'pie',
        title: 'Pie Chart Sample',
        width: WidthOptions.Half,
        rows: 1,
        labels: ['Sat', 'Sun', 'Mon', 'Tue'],
        dataSets: [
          {
            data: [1, 5, 2, 8],
          },
        ],
      },
      {
        id: 'doughnutSample',
        type: 'doughnut',
        title: 'Doughnut Chart Sample',
        width: WidthOptions.Half,
        rows: 1,
        labels: ['Sat', 'Sun', 'Mon', 'Tue'],
        dataSets: [
          {
            data: [1, 5, 2, 8],
          },
        ],
      },
      {
        id: 'gaugeSample',
        type: 'gauge',
        title: 'Pull Requests',
        width: WidthOptions.Half,
        rows: 1,
        value: 50,
        min: 0,
        max: 120,
        zones: [
          {
            strokeStyle: '#30B32D',
            min: 0,
            max: 80,
          },
          {
            strokeStyle: '#ff0000',
            max: 120,
            min: 80,
          },
        ],
        labelsDivision: 4,
      },
      {
        id: 'numberSample',
        type: 'number',
        title: 'Number widget Sample',
        width: WidthOptions.Half,
        rows: 1,
        value: 20,
        suffix: 'Gb',
        color: 'blue',
      },
      buildDataForDataGrid(),
    ],
  },
  pipelines: {
    id: 'pipelines',
    title: 'Pipelines',
    visualizations: [
      {
        id: 'technical',
        type: 'embedded',
        title: 'Technical',
        // tslint:disable-next-line: quotemark
        url:
          'https://ec2-3-92-3-36.compute-1.amazonaws.com:7473/db/data/transaction/commit',
        width: WidthOptions.Half,
        rows: 2,
      },
    ],
  },
};

export const demoStatisticsDashboardNav: StatisticsDashboardNav = {
  trends: [
    ...convertObjectToArray<StatisticsDashboard>(demoStatisticsDashboards),
  ],
  pipelines: [],
};

export interface RepositoryDetail {
  issues: {
    count: number;
    percentage: number;
  };
  commits: {
    count: number;
    percentage: number;
  };
  contributors: {
    count: number;
    percentage: number;
  };
  merges: {
    count: number;
    percentage: number;
  };
}

export interface ActiveContributor {
  avatarUrl: string;
  name: string;
  commits: number;
}

export interface RepositorySecurity {
  name: string;
  high: number;
  low: number;
  medium: number;
}

export interface Statistics {
  community: RepositoryDetail[];
  repositorySecurity: RepositorySecurity[];
  organizationsRepositoryDetails: RepositoryDetail[];
  activeContributors: ActiveContributor[];
}

export interface RepoNumberGen {
  count: number;
  percentage: number;
}

export const generateMostActiveContributors = () => {
  const name = faker.name.findName();

  return {
    avatarUrl: imagePlaceholders.user,
    name,
    commits: faker.random.number(),
  };
};

const repoNames = [
  'kubernetes',
  'cloud-provider-azure',
  'k8s.io',
  'kubeadm',
  'repo-infra',
  'kube-controller-manager',
  'kube-scheduler',
  'kube-aggregator',
  'security',
  'node-api',
];

export const generateRepositorySecurity = () => ({
  name: faker.random.arrayElement(repoNames),
  high: faker.random.number(5),
  medium: faker.random.number(10),
  low: faker.random.number(10),
});

export const mockRepoNumberGen = (): RepoNumberGen => ({
  count: faker.random.number(),
  percentage: faker.random.number({ min: -25, max: 25 }),
});

export const repositoryDetails = [
  {
    issues: mockRepoNumberGen(),
    commits: mockRepoNumberGen(),
    contributors: mockRepoNumberGen(),
    merges: mockRepoNumberGen(),
  },
];

export const organizationDetails = [
  {
    issues: mockRepoNumberGen(),
    commits: mockRepoNumberGen(),
    contributors: mockRepoNumberGen(),
    merges: mockRepoNumberGen(),
  },
];

export const mockFoundationStatistics = (): Statistics => ({
  community: [...repositoryDetails],
  repositorySecurity: mockList(generateRepositorySecurity),
  organizationsRepositoryDetails: [...organizationDetails],
  activeContributors: mockList(generateMostActiveContributors, {
    min: 12,
    max: 20,
  }),
});
