import { ContextMenuAccess } from './context-menu-access';

export interface ContextAccessControl {
  contextId: string;
  name: string;
  menuAccesses: ContextMenuAccess[];
}

export const demoContextAccessControl = {
  user: {
    contextId: '1',
    name: 'user',
    menuAccesses: [
      {
        menuId: '101',
        title: 'Dashboard',
        icon: 'fa fa-th-large',
        url: '/company/dashboard',
        contextId: '1',
        defaultPage: true,
        accesses: [
          {
            accessId: '1001',
            contextId: '1',
            accessCode: 'DASHBOARD_LIST',
          },
        ],
        subMenu: [],
      },
      {
        menuId: '102',
        title: 'Foundations',
        icon: 'fas fa-chart-pie',
        url: '/foundation',
        contextId: '1',
        defaultPage: false,
        accesses: [
          {
            accessId: '2001',
            contextId: '1',
            accessCode: 'FOUNDATION_LIST',
          },
          {
            accessId: '2002',
            contextId: '1',
            accessCode: 'FOUNDATION_DETAIL',
          },
          {
            accessId: '2003',
            contextId: '1',
            accessCode: 'FOUNDATION_PROJECT_DETAILS',
          },
        ],
        subMenu: [],
      },
      {
        menuId: '103',
        title: 'Tools',
        icon: 'fas fa-suitcase',
        url: '',
        contextId: '1',
        accesses: [],
        subMenu: [],
      },
      // {
      //   menuId: '104',
      //   title: 'Events',
      //   icon: 'fas fa-suitcase',
      //   url: '',
      //   contextId: '1',
      //   accesses: [],
      //   subMenu: []
      // },
      // {
      //   menuId: '105',
      //   title: 'Training + Certification',
      //   icon: 'fas fa-wrench',
      //   url: '',
      //   contextId: '1',
      //   accesses: [],
      //   subMenu: []
      // },
      {
        menuId: '106',
        title: 'Crowdfunding',
        icon: 'fas fa-wrench',
        url: '',
        contextId: '1',
        accesses: [],
        subMenu: [],
      },
      {
        menuId: '107',
        title: 'Manage',
        icon: 'fas fa-wrench',
        url: '',
        contextId: '1',
        accesses: [],
        subMenu: [],
      },
      {
        icon: 'fas fa-file-code',
        title: 'Developer',
        url: '',
      },
    ],
  },
  maintainer: {
    contextId: '2',
    name: 'maintainer',
    menuAccesses: [
      {
        menuId: '201',
        title: 'Dashboard',
        icon: 'fa fa-th-large',
        url: '/user/dashboard',
        contextId: '1',
        defaultPage: true,
        accesses: [
          {
            accessId: '1001',
            contextId: '2',
            accessCode: 'DASHBOARD_LIST',
          },
        ],
        subMenu: [],
      },
      {
        menuId: '202',
        title: 'Foundations',
        icon: 'fas fa-chart-pie',
        url: '/foundation',
        contextId: '2',
        defaultPage: false,
        accesses: [
          {
            accessId: '2001',
            contextId: '2',
            accessCode: 'FOUNDATION_LIST',
          },
        ],
        subMenu: [],
      },
      {
        menuId: '103',
        title: 'Tools',
        icon: 'fas fa-suitcase',
        url: '',
        contextId: '2',
        accesses: [],
        subMenu: [],
      },
    ],
  },
  lfadmin: {
    contextId: '3',
    name: 'lfadmin',
    menuAccesses: [
      {
        menuId: '301',
        title: 'Dashboard',
        icon: 'fa fa-th-large',
        url: '/admin',
        contextId: '1',
        defaultPage: false,
        accesses: [
          {
            accessId: '1001',
            contextId: '1',
            accessCode: 'DASHBOARD_LIST',
          },
        ],
        subMenu: [],
      },
      {
        menuId: '302',
        title: 'Foundations',
        icon: 'fas fa-chart-pie',
        url: '/foundation',
        contextId: '1',
        defaultPage: true,
        accesses: [
          {
            accessId: '2001',
            contextId: '3',
            accessCode: 'FOUNDATION_LIST',
          },
          {
            accessId: '2002',
            contextId: '3',
            accessCode: 'FOUNDATION_DETAIL',
          },
          {
            accessId: '2003',
            contextId: '3',
            accessCode: 'FOUNDATION_PROJECT_DETAILS',
          },
        ],
        subMenu: [],
      },
      {
        menuId: '103',
        title: 'Tools',
        icon: 'fas fa-suitcase',
        url: '',
        contextId: '1',
        accesses: [],
        subMenu: [],
      },
      {
        menuId: '104',
        title: 'Events',
        icon: 'fas fa-suitcase',
        url: '',
        contextId: '1',
        accesses: [],
        subMenu: [],
      },
      {
        menuId: '105',
        title: 'Training + Certification',
        icon: 'fas fa-wrench',
        url: '',
        contextId: '1',
        accesses: [],
        subMenu: [],
      },
      {
        menuId: '106',
        title: 'Crowdfunding',
        icon: 'fas fa-wrench',
        url: '',
        contextId: '1',
        accesses: [],
        subMenu: [],
      },
      {
        menuId: '107',
        title: 'Administration',
        icon: 'fas fa-wrench',
        url: '',
        contextId: '1',
        accesses: [],
        subMenu: [],
      },
    ],
  },
};
