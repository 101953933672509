import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';

import {
  ClaInsightsResult,
  ApprovedContributorsResult,
  ClaEnabledProjectsResult,
} from './results';
import {
  ClaInsights,
  ActiveClaProject,
  NewClaManager,
  CompanyClaManagers,
  CompanyProjectMetrics,
  ContributorAcknowledgements,
  ClaGroupsForProject,
  RemoveClaManager,
  FoundationLevelCla,
  ApprovedContributor,
  ListIndividualSignaturesForCLAGroupQueryParms,
  ListIndividualSignaturesForCLAGroupResponse,
  UpdateAutoCreateEclaFlagInput,
  UpdateAutoCreateEclaFlagResponse,
  MutationResponse,
  ListClaGroupCorporateContributorsQueryParms,
  ListClaGroupCorporateContributorsResponse,
} from '@models';
import { map } from 'rxjs/operators';
import {
  claInsightsQuery,
  activeClaListQuery,
  claGroupsForProjectListQuery,
  companyClaManagersQuery,
  approvedContributorsQuery,
  claEnabledProjectsQuery,
  companyProjectMetricsQuery,
  contributorAcknowledgementsQuery,
  foundationCLAGroupsQuery,
  foundationLevelCLAQuery,
  individualSignatureForCLAGroupQuery,
  listClaGroupCorporateContributorsQuery,
} from '../queries';
import {
  addNewClaManagerMutation,
  requestCorporateSignatureMutation,
  removeClaManagerMutation,
  assignCLAManagerDesigneeMutation,
  requestAddCLAManagerDesignee,
  updateApprovalListMutation,
  updateAutoCreateEclaFlagMutation,
} from '../mutations';
import {
  RequestClaSignatureInput,
  AssignCLAManagerDesigneeInput,
  RequestAddCLAManagerDesigneeInput,
} from './inputs';

@Injectable({
  providedIn: 'root',
})
export class ClaServiceGql {
  constructor(private apollo: Apollo) {}

  getClaInsights(): Observable<ClaInsights> {
    return this.apollo
      .watchQuery<ClaInsightsResult>({
        query: claInsightsQuery,
      })
      .valueChanges.pipe(map(res => res.data.easyClaInsights));
  }

  getActiveClaList(organizationId, projectId): Observable<ActiveClaProject[]> {
    return this.apollo
      .watchQuery<{ activeClaList: ActiveClaProject[] }>({
        query: activeClaListQuery,
        variables: {
          input: {
            organizationId,
            projectId,
          },
        },
      })
      .valueChanges.pipe(map(res => res.data.activeClaList));
  }

  getClaGroupsForProjectList(
    organizationId: string,
    projectId: string,
    organizationInternalId: string,
    noCache?: boolean
  ): Observable<ClaGroupsForProject[]> {
    return this.apollo
      .watchQuery<{ claGroupsForProjectList: ClaGroupsForProject[] }>({
        query: claGroupsForProjectListQuery,
        fetchPolicy: noCache ? 'no-cache' : 'cache-and-network',
        variables: {
          organizationId,
          projectId,
          organizationInternalId,
        },
      })
      .valueChanges.pipe(
        map(res => (res.data ? res.data.claGroupsForProjectList : []))
      );
  }

  getCompanyClaManagers(
    companySFID,
    projectSFID,
    withProjectLogoUrl
  ): Observable<CompanyClaManagers[]> {
    return this.apollo
      .watchQuery<{ companyClaManagers: CompanyClaManagers[] }>({
        query: companyClaManagersQuery,
        variables: {
          companySFID,
          projectSFID,
          withProjectLogoUrl,
        },
      })
      .valueChanges.pipe(map(res => res.data.companyClaManagers));
  }

  addNewClaManager(input: NewClaManager): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: addNewClaManagerMutation,
        variables: {
          input,
        },
      })
      .pipe(map(res => res.data.addNewClaManager));
  }

  removeClaManager(input: RemoveClaManager): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: removeClaManagerMutation,
        variables: {
          input,
        },
      })
      .pipe(map(res => res.data.removeClaManager));
  }

  getApprovedContributors(
    companyId,
    projectId
  ): Observable<ApprovedContributor[]> {
    return this.apollo
      .watchQuery<ApprovedContributorsResult>({
        query: approvedContributorsQuery,
        variables: {
          input: {
            companyId,
            projectId,
          },
        },
      })
      .valueChanges.pipe(map(res => res.data.approvedContributorsList));
  }

  getClaEnabledProjects(id: string): Observable<any> {
    return this.apollo
      .watchQuery<ClaEnabledProjectsResult>({
        query: claEnabledProjectsQuery,
        variables: {
          id,
        },
      })
      .valueChanges.pipe(map(res => res.data.claEnabledProjects));
  }

  listCompanyProjectMetrics(
    companyId,
    projectId
  ): Observable<CompanyProjectMetrics[]> {
    return this.apollo
      .watchQuery<{ companyProjectMetrics: CompanyProjectMetrics[] }>({
        query: companyProjectMetricsQuery,
        variables: {
          input: {
            companyId,
            projectId,
          },
        },
      })
      .valueChanges.pipe(map(res => res.data.companyProjectMetrics));
  }

  getContributorAcknowledgements(input: {
    projectId: string;
    companyId: string;
    pageSize?: number;
    nextKey?: string;
    searchTerm?: string;
  }): Observable<ContributorAcknowledgements> {
    return this.apollo
      .watchQuery<{
        getCompanyContributorAcknowledgements: ContributorAcknowledgements;
      }>({
        query: contributorAcknowledgementsQuery,
        variables: {
          input,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.getCompanyContributorAcknowledgements)
      );
  }

  getRequestDocumentSignature(
    input: RequestClaSignatureInput
  ): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: requestCorporateSignatureMutation,
        variables: {
          input,
        },
      })
      .pipe(map(res => res.data.requestCorporateSignature));
  }

  getAssignCLAManagerDesignee(
    input: AssignCLAManagerDesigneeInput
  ): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: assignCLAManagerDesigneeMutation,
        variables: {
          input,
        },
      })
      .pipe(map(res => res.data.assignCLAManagerDesignee));
  }

  requestAddCLAManagerDesignee(
    input: RequestAddCLAManagerDesigneeInput
  ): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: requestAddCLAManagerDesignee,
        variables: {
          input,
        },
      })
      .pipe(map(res => res.data.requestAddCLAManagerDesignee));
  }

  getFoundationCLAGroups(input: any): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: foundationCLAGroupsQuery,
        variables: {
          input,
        },
      })
      .valueChanges.pipe(map(res => res.data.foundationCLAGroups));
  }

  updateApprovalList(input: any): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: updateApprovalListMutation,
        variables: {
          input,
        },
      })
      .pipe(map(res => res.data.updateApprovalList));
  }

  getFoundationLevelCLA(id: string): Observable<FoundationLevelCla> {
    return this.apollo
      .watchQuery<any>({
        query: foundationLevelCLAQuery,
        variables: {
          id,
        },
      })
      .valueChanges.pipe(map(res => res.data.foundationLevelCla));
  }

  listIndividualSignaturesForCLAGroup(
    claGroupID: string,
    queryParams: ListIndividualSignaturesForCLAGroupQueryParms
  ): Observable<ListIndividualSignaturesForCLAGroupResponse> {
    return this.apollo
      .watchQuery<any>({
        query: individualSignatureForCLAGroupQuery,
        fetchPolicy: 'cache-first',
        variables: {
          claGroupID,
          queryParams,
        },
      })
      .valueChanges.pipe(map(res => res.data.individualSignaturesForCLAGroup));
  }

  listClaGroupCorporateContributors(
    claGroupID: string,
    queryParams: ListClaGroupCorporateContributorsQueryParms
  ): Observable<ListClaGroupCorporateContributorsResponse> {
    return this.apollo
      .watchQuery<any>({
        query: listClaGroupCorporateContributorsQuery,
        fetchPolicy: 'cache-first',
        variables: {
          claGroupID,
          queryParams,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.listClaGroupCorporateContributors)
      );
  }

  updateAutoCreateEclaFlag({
    autoCreateEcla,
    claGroupID,
    companyID,
  }: UpdateAutoCreateEclaFlagInput): Observable<
    MutationResponse<UpdateAutoCreateEclaFlagResponse>
  > {
    return this.apollo
      .mutate<any>({
        mutation: updateAutoCreateEclaFlagMutation,
        variables: {
          input: { autoCreateEcla, claGroupID, companyID },
        },
      })
      .pipe(map(res => res.data.updateAutoCreateEclaFlag));
  }
}
