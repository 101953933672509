import gql from 'graphql-tag';

export const updateUserMutation = gql`
  mutation updateUser($userInfo: UpdateUserInput!) {
    updateUser(input: $userInfo) {
      code
      message
      success
      user {
        id
        username
        firstName
        lastName
        timezone
        emails {
          emailAddress
          isDeleted
          isPrimary
        }
      }
    }
  }
`;
