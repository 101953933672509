import { UpdateOrganizationPayload } from './results/update-organization-result';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  allOrganizationsQuery,
  committeeMembersQuery,
  myOrganizationQuery,
  organizationQuery,
  organizationAdminsQuery,
  searchUserQuery,
  addressesQuery,
  organizationSigningEntityQuery,
} from '../queries';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
  Company,
  CompanyAdministrators,
  User,
  DeleteUserRoleScopeInput,
  Address,
  CommitteeMembersConnection,
  CreateAddressInput,
  UpdateAddressInput,
  MemberInvite,
} from '@models';
import {
  AllOrganizationsResult,
  UpdateOrganizationResult,
  SearchUserResult,
  CreateAddressPayload,
  CreateAddressResult,
  UpdateAddressPayload,
  UpdateAddressResult,
} from './results';
import {
  uploadFileMutation,
  updateOrganizationMutation,
  deleteUserRoleScopeMutation,
  createUserRoleScopeMutation,
  sendNewMemberInviteMutation,
  createUserRoleScopesMutation,
  createAddressMutation,
  updateAddressMutation,
  resendInviteMutation,
} from '../mutations';
import {
  UpdateOrganizationInput,
  SearchUserInput,
  GetMyOrganizationInput,
  CreateUserRoleScopeInput,
  SendMemberInviteInput,
  GetCommitteeMembersInput,
} from './inputs';
import { MutationResponse } from '@lfx/core/models/mutation-response';

@Injectable({
  providedIn: 'root',
})
export class OrganizationServiceGql {
  constructor(private apollo: Apollo) {}

  getAllOrganizations(): Observable<Company[]> {
    return this.apollo
      .watchQuery<AllOrganizationsResult>({
        query: allOrganizationsQuery,
      })
      .valueChanges.pipe(map(res => res.data.organizations));
  }

  getMyOrganization(
    options?: GetMyOrganizationInput,
    noCache = false
  ): Observable<Company> {
    return this.apollo
      .query<{ myOrganization: Company }>({
        query: myOrganizationQuery,
        variables: options,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      })
      .pipe(
        map(res => res.data.myOrganization),
        catchError(error => {
          if (
            error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].extensions &&
            error.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
          ) {
            return throwError(new Error('UNAUTHENTICATED'));
          }

          return of(null);
        })
      );
  }

  getOrganizationById(salesforceId: string): Observable<Company> {
    return this.apollo
      .watchQuery<any>({
        query: organizationQuery,
        variables: {
          salesforceId,
        },
      })
      .valueChanges.pipe(map(res => res.data.organization));
  }

  getOrganizationSigningEntity(signingEntityId: string) {
    return this.apollo
      .watchQuery<any>({
        query: organizationSigningEntityQuery,
        variables: {
          signingEntityId,
        },
        fetchPolicy: 'cache-first',
      })
      .valueChanges.pipe(map(res => res.data.organizationSigningEntityById));
  }

  uploadLogo(file: File): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: uploadFileMutation,
        variables: { file },
        context: { useMultipart: true, hasUpload: true },
      })
      .pipe(map(res => res.data.uploadContractDoc));
  }

  deleteUserRoleScope(
    input: DeleteUserRoleScopeInput
  ): Observable<MutationResponse<null>> {
    return this.apollo
      .mutate<any>({
        mutation: deleteUserRoleScopeMutation,
        variables: { input },
      })
      .pipe(map(res => res.data.deleteUserRoleScope));
  }

  createUserRoleScope(input: CreateUserRoleScopeInput): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: createUserRoleScopeMutation,
        variables: { input },
      })
      .pipe(map(res => res.data));
  }

  updateOrganizationProfile(
    input: UpdateOrganizationInput
  ): Observable<UpdateOrganizationPayload> {
    return this.apollo
      .mutate<UpdateOrganizationResult>({
        mutation: updateOrganizationMutation,
        refetchQueries: [
          {
            query: myOrganizationQuery,
          },
        ],
        variables: {
          input,
        },
      })
      .pipe(map(res => res.data.updateOrganization));
  }

  getOrganizationAdmins(
    salesforceId: string
  ): Observable<CompanyAdministrators> {
    return this.apollo
      .watchQuery<any>({
        query: organizationAdminsQuery,
        variables: {
          salesforceId,
        },
      })
      .valueChanges.pipe(map(res => res.data.organizationAdmins));
  }

  getOrganizationCommitteeMembers(
    options: GetCommitteeMembersInput
  ): Observable<CommitteeMembersConnection> {
    return this.apollo
      .watchQuery<any>({
        query: committeeMembersQuery,
        variables: options,
      })
      .valueChanges.pipe(
        map(res => res.data.organization.committeeMembersConnection)
      );
  }

  searchUsers(input: SearchUserInput): Observable<User[]> {
    return this.apollo
      .watchQuery<SearchUserResult>({
        query: searchUserQuery,
        variables: {
          input,
        },
      })
      .valueChanges.pipe(map(res => res.data.searchUser));
  }

  sendNewMemberEmailInvitation(input: SendMemberInviteInput): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: sendNewMemberInviteMutation,
        variables: { input },
      })
      .pipe(map(res => res.data));
  }

  resendEmailInvitation(
    inviteId: string,
    inviteEmail: string
  ): Observable<MutationResponse<MemberInvite>> {
    return this.apollo
      .mutate<any>({
        mutation: resendInviteMutation,
        variables: {
          inviteId,
          inviteEmail,
        },
      })
      .pipe(map(res => res.data.resendInvite));
  }

  createUserRoleScopes(input: CreateUserRoleScopeInput[]): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: createUserRoleScopesMutation,
        variables: { input },
      })
      .pipe(map(res => res.data));
  }

  getAddresses(organizationId: string): Observable<Address[]> {
    return this.apollo
      .watchQuery<{ organizationAdresses: Address[] }>({
        query: addressesQuery,
        variables: { organizationId },
      })
      .valueChanges.pipe(map(res => res.data.organizationAdresses));
  }

  createAddress(input: CreateAddressInput): Observable<CreateAddressPayload> {
    return this.apollo
      .mutate<CreateAddressResult>({
        mutation: createAddressMutation,
        variables: { input },
      })
      .pipe(map(res => res.data.createAddress));
  }

  updateAddress(input: UpdateAddressInput): Observable<UpdateAddressPayload> {
    return this.apollo
      .mutate<UpdateAddressResult>({
        mutation: updateAddressMutation,
        refetchQueries: [
          {
            query: addressesQuery,
            variables: { organizationId: input.organizationId },
          },
        ],
        awaitRefetchQueries: true,
        variables: { input },
      })
      .pipe(map(res => res.data.updateAddress));
  }
}
