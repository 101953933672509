import gql from 'graphql-tag';

export const contributorAcknowledgementsQuery = gql`
  query getCompanyContributorAcknowledgements($input: ClaDefaultInput!) {
    getCompanyContributorAcknowledgements(input: $input) {
      code
      message
      success
      contributorsList {
        linuxFoundationId
        githubId
        name
        timestamp
        signatureVersion
        photoUrl
        signatureApproved
        signatureId
        signatureModified
        signatureSigned
        userDocusignDateSigned
        userDocusignName
      }
      resultCount
      totalCount
      nextKey
    }
  }
`;

export const individualSignatureForCLAGroupQuery = gql`
  query individualSignaturesForCLAGroup(
    $claGroupID: ID!
    $queryParams: IndividualSignaturesForCLAGroupInput
  ) {
    individualSignaturesForCLAGroup(
      claGroupID: $claGroupID
      queryParams: $queryParams
    ) {
      list {
        github_username
        lf_username
        signatureApproved
        signatureModified
        signatureSigned
        signature_id
        signed_on
        userDocusignDateSigned
        userDocusignName
        user_email
        user_id
        user_name
      }
      lastKeyScanned
      pageSize
      resultCount
    }
  }
`;

export const listClaGroupCorporateContributorsQuery = gql`
  query listClaGroupCorporateContributors(
    $claGroupID: ID!
    $queryParams: ListClaGroupCorporateContributorsInput
  ) {
    listClaGroupCorporateContributors(
      claGroupID: $claGroupID
      queryParams: $queryParams
    ) {
      list {
        signatureApproved
        signatureSigned
        email
        github_id
        linux_foundation_id
        name
        userDocusignName
      }
      resultCount
    }
  }
`;
