import { map, first } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { Injectable } from '@angular/core';
import {
  AppNotification,
  AppNotificationMetadata,
  PaginatedAppNotifications,
} from '@models';
import { BehaviorSubject, of, Observable } from 'rxjs';
import { ToastService } from './toast.service';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  public notificationsLength$: Observable<number>;

  private _notificationsLength$ = new BehaviorSubject(0);
  private notifications$: Observable<AppNotification[]>;
  private currentlyShowing: AppNotification[];
  private lastShownIndex: number;

  constructor(
    private toastService: ToastService,
    private storageService: StorageService
  ) {
    this.initialize();
  }

  push(options: any) {
    const { title, body, showMore } = options;

    this.getNotifications()
      .pipe(first())
      .subscribe(notifications => {
        /*
        notifications order is not preserved after JSON.stringify() is
         called on notifications hence the logic below for generating notification id
        */
        const lastId = notifications.reduce(
          (id, currentNotification) =>
            currentNotification.id > id ? currentNotification.id : id,
          0
        );

        const notificationId = !notifications[0] ? 0 : lastId + 1;
        const notification: AppNotification = {
          title,
          body,
          id: notificationId,
          createdAt: new Date(),
          showMore: showMore || true,
        };

        notifications.push(notification);

        ++this.lastShownIndex;

        this.storageService.setItem<AppNotification[]>(
          'notifications',
          notifications
        );

        this.notifications$ = of(notifications);
        this._notificationsLength$.next(notifications.length);

        this.toastService.notification(
          title,
          body,
          notificationId,
          '',
          showMore
        );
      });
  }

  getNextNotifications(size: number): Observable<PaginatedAppNotifications> {
    return this.notifications$.pipe(
      map(notifications => {
        this.lastShownIndex =
          this.lastShownIndex >= 0
            ? this.lastShownIndex
            : notifications.length - 1;
        this.currentlyShowing = [];
        const originalSize = notifications.length;

        for (let i = size; i > 0 && this.lastShownIndex >= 0; --i) {
          const notification = notifications[this.lastShownIndex];

          --this.lastShownIndex;
          this.currentlyShowing.push(notification);
        }

        const metadata: AppNotificationMetadata = {
          allNotifications: originalSize,
          limit: size,
        };

        this._notificationsLength$.next(originalSize);

        return { metadata, notifications: this.currentlyShowing };
      })
    );
  }

  dismissNotification(
    notification: AppNotification
  ): Observable<PaginatedAppNotifications> {
    return this.removeNotification(notification).pipe(
      map(notifications => {
        const notificationsLength = notifications.length;
        const paginatedNotifications = {} as PaginatedAppNotifications;

        paginatedNotifications.notifications = this.currentlyShowing;
        paginatedNotifications.metadata = {
          allNotifications: notificationsLength,
          limit: this.currentlyShowing.length,
        };

        this._notificationsLength$.next(notificationsLength);

        return paginatedNotifications;
      })
    );
  }

  private initialize() {
    this.notificationsLength$ = this._notificationsLength$.asObservable();

    this.getNotifications()
      .pipe(first())
      .subscribe(
        notifications => {
          this._notificationsLength$.next(notifications.length);

          const sorted = notifications.sort((current, next) => {
            if (current.createdAt > next.createdAt) {
              return -1;
            }

            if (current.createdAt < next.createdAt) {
              return 1;
            }

            return 0;
          });

          this.notifications$ = of(sorted);
        },
        () => {
          this.storageService.setItem('notifications', []);

          return of([]);
        }
      );
  }

  private removeNotification(
    notification: AppNotification
  ): Observable<AppNotification[]> {
    return this.getNotifications().pipe(
      map(notifications => {
        this.currentlyShowing = this.currentlyShowing.filter(
          n => n.id !== notification.id
        );
        const filtered = notifications.filter(n => n.id !== notification.id);
        const notificationsLength = filtered.length;

        this.lastShownIndex = notificationsLength - 1;

        if (notificationsLength !== this.currentlyShowing.length) {
          this.currentlyShowing.push(filtered[this.lastShownIndex]);
        }

        this.storageService.setItem('notifications', filtered);

        return filtered;
      })
    );
  }

  private getNotifications(): Observable<AppNotification[]> {
    return this.storageService
      .getItem<AppNotification[]>('notifications')
      .pipe(map(res => (res ? res : [])));
  }
}
