import gql from 'graphql-tag';

export const projectResourcesQuery = gql`
  query projectResource($id: ID!) {
    projectResource(id: $id) {
      id
      name
      description
      resourceUrl
      resourceType
    }
  }
`;
