import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'lfx-top-btn',
  templateUrl: './top-btn.component.html',
  styleUrls: ['./top-btn.component.scss'],
})
export class TopBtnComponent implements OnInit {
  showTopBtn = false;

  constructor() {}

  ngOnInit() {}

  jumbToTop() {
    window.scrollTo({ top: 0 });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

    if (top > 25) {
      this.showTopBtn = true;
    } else {
      this.showTopBtn = false;
    }
  }
}
