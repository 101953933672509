import gql from 'graphql-tag';

export const projectCommitteesQuery = gql`
  query project($id: ID!) {
    project(id: $id) {
      committees {
        id
        name
        collaborationName
        category
        description
        url
      }
    }
  }
`;
