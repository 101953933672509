// tslint:disable: max-line-length
import * as faker from 'faker';

import { severity } from './severity';

export const mockList = <T>(
  mockGenFn: () => T,
  size: any = { min: 3, max: 10 }
): T[] => {
  const set = new Set();
  const num = faker.random.number(size);

  while (set.size < num) {
    set.add(mockGenFn());
  }

  return [...set] as T[];
};

export const randomEnum = <T>(anEnum: T): T[keyof T] => {
  const enumKeys: string[] = Object.keys(anEnum);
  const enumArr: T[] = enumKeys.map(k => anEnum[k as any]).map(v => v as T);

  return faker.random.arrayElement(enumArr);
};

export const randomDateWithin = (
  days: number,
  ofDate: Date = new Date()
): Date =>
  faker.date.between(
    faker.date.past(days / 365, ofDate),
    faker.date.future(days / 365, ofDate)
  );

export const randomSalesforceId = (): string => faker.random.alphaNumeric(15);

// These are all valid project ids from the CII Best Practices site
export const randomBadgeId = (): number =>
  faker.random.arrayElement([
    1,
    24,
    26,
    29,
    31,
    33,
    34,
    41,
    42,
    129,
    144,
    145,
    146,
    152,
    154,
    164,
    35,
    40,
    64,
    65,
    80,
    82,
    84,
    85,
  ]);

export const randomSeverity = () => faker.random.arrayElement(severity);

export const randomDateBetweenTwoDates = (
  startDate: Date,
  endDate: Date
): Date => faker.date.between(startDate, endDate);
