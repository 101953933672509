import gql from 'graphql-tag';

export const deleteInviteMutation = gql`
  mutation deleteInvite($input: DeleteInviteInput!) {
    deleteInvite(input: $input) {
      code
      message
      success
    }
  }
`;
