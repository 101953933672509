import gql from 'graphql-tag';

export const myMembershipsQuery = gql`
  query myMemberships(
    $loadMainDataOnly: Boolean = false
    $filters: MembershipFilters
  ) {
    myMemberships(filters: $filters) {
      createdDate
      docuSignStatus @skip(if: $loadMainDataOnly) {
        date
      }
      product @skip(if: $loadMainDataOnly) {
        family
        id
        name
      }
      projectName @skip(if: $loadMainDataOnly)
      systemModStamp @skip(if: $loadMainDataOnly)
      accountId @skip(if: $loadMainDataOnly)
      agreementDate @skip(if: $loadMainDataOnly)
      annualFullPrice @skip(if: $loadMainDataOnly)
      assetLevel @skip(if: $loadMainDataOnly)
      autoRenew @skip(if: $loadMainDataOnly)
      cancellationDate @skip(if: $loadMainDataOnly)
      endDate
      id
      installDate @skip(if: $loadMainDataOnly)
      name
      numberOfYearsRequired @skip(if: $loadMainDataOnly)
      order @skip(if: $loadMainDataOnly)
      paymentFrequency @skip(if: $loadMainDataOnly)
      paymentTerms @skip(if: $loadMainDataOnly)
      price @skip(if: $loadMainDataOnly)
      projectId @skip(if: $loadMainDataOnly)
      purchaseDate @skip(if: $loadMainDataOnly)
      quantity @skip(if: $loadMainDataOnly)
      renewalType @skip(if: $loadMainDataOnly)
      rootAssetId @skip(if: $loadMainDataOnly)
      status
      year @skip(if: $loadMainDataOnly)
    }
  }
`;
