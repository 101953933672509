import gql from 'graphql-tag';

export const assignCLAManagerDesigneeMutation = gql`
  mutation assignCLAManagerDesignee($input: AssignCLAManagerDesigneeInput!) {
    assignCLAManagerDesignee(input: $input) {
      code
      message
      success
    }
  }
`;
