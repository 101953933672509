export interface ActiveClaList {
  projectId: string;
  projectLogo: string;
  projectName: string;
  projectSfid: string;
  projectType: string;
  signatoryName: string;
  signedOn: string;
  pdf: string;
  subProjects: ActiveClaList[];
}

export enum ActiveClaListStatus {
  signed = 'Signed',
  notSigned = 'Not signed',
}
