export const generalConstants = {
  claGeneralErrorMessage: 'None',
  organizationHeader: {
    orgProfileEditConflictMessage:
      'The Company domain name is already associated with another Company',
    updateCompanyInformationSuccessMessage:
      'Organization Information updated successfully',
  },
  accountSettingsTitle: 'Account Settings',
  defaultAvatar: 'assets/img/svgs/user-solid.svg',
  browserTime: 'Browser Time:',
  other: 'Other',
  dateFormat: 'dd/MM/y',
  // Foundation Module
  category: 'Category',
  filterBy: 'Filter By',
  selectCategory: 'Select Category',
  selectFilter: 'Select Filter',
  selectProject: 'Select Project',
  mailSubject: 'Requesting Change for Community Role',
  foundationContactRoles: [
    'Representative/Voting Contact',
    'Marketing Contact',
    'Technical Contact',
    'Billing Contact',
    'Press Contact',
    'Legal Contact',
    'HL - POC',
  ],
  securityHeaders: [
    {
      name: 'Overview',
      relativeLink: 'overview',
    },
    {
      name: 'Issues',
      relativeLink: 'issues',
      permissions: {
        any: 'feature_security_project_issues',
      },
    },
    {
      name: 'Dependencies',
      relativeLink: 'dependencies',
      permissions: {
        any: 'feature_security_project_dependencies',
      },
    },
    {
      name: 'Packages',
      relativeLink: 'packages',
      permissions: {
        any: 'feature_security_project_packages',
      },
    },
  ],
  defaultEmptyUserTable: 'No Team Members Found',
  monthNames: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  adminStatus: [
    'Planned',
    'Engaged',
    'Active',
    'Sunset',
    'Exploratory',
    'Intent to Form',
    'Moving to Launch',
    'Launched',
    'Disengaged',
  ],
  sector: [
    'AI & ML',
    'Blockchain, Data & Analytics',
    'Cloud',
    'Compliance & Security',
    'Embedded & IOT',
    'Platforms',
    'Networking',
  ],
  quarters: [
    {
      name: 'Q1',
      months: [0, 1, 2],
    },
    {
      name: 'Q2',
      months: [3, 4, 5],
    },
    {
      name: 'Q3',
      months: [6, 7, 8],
    },
    {
      name: 'Q4',
      months: [9, 10, 11],
    },
  ],
  monthly: 'monthly',
  status: 'Status',
  state: 'State',
  selectState: 'Select State',
  selectStatus: 'Select Status',
  synkURL: 'https://snyk.io/vuln/',
  snykcweURL: 'https://cwe.mitre.org/data/definitions/',
  snykcveURL: 'https://cve.mitre.org/cgi-bin/cvename.cgi?name=',
  sliderRollerColor: '#C5C5C5',
  marketingColorCode: '#3FA9D2',
  mentorshipColorCode: '#FFA356',
  meetsupColorCode: '#98D377',
  travelColorCode: '#FF4A53',
  stipendColorCode: '#FFC107',
  otherColorCode: '#6C3008',
  dollarSymbol: '$',
  annualGoal: 'Annual Goal',
  shortOfGoal: '%delta% short of %total% goal',
  surpassedByTotal: 'Goal of %total% surpassed by %delta%',
  goalReached: 'Goal of %total% has been reached!',
  btnText: 'Learn More',
  defaultActionsTitle: 'Manage',
  viewProjects: 'View Projects',
  btnType: 'primary',
  currentContext: 'current-context',
  accessKey: 'userAccess',
  hexaCodeValidatorRegex: /^#[0-9A-F]{6}$/i,
  incomeGLAccount: [
    '4200 Training',
    '4105 Membership Dues : Platinum Membership',
    '4110 Membership Dues : Gold Membership',
    '4115 Membership Dues : Silver Membership',
    '4120 Membership Dues : Premier Membership',
    '4125 Membership Dues : Community',
    '4126 Membership Dues : Promoter',
    '4128 Membership Dues : Supporter',
    '4129 Membership Dues : Adopter Membership',
    '4130 Membership Dues : Affiliates & Colleges',
    '4150 Membership Dues : Individual Memberships',
    '4310 Directed Funds : Directed Fund Revenue',
    '4312 Directed Funds : Directed Fund Rev. used to offset Event Mgmt exps',
    '4315 Directed Funds : Directed Fund Event Sponsorship/Registration',
    '4410 Events (non EMS) : Event Sponsor Revenue',
    '4415 Events (non EMS) : Event Registration',
    '4420 Events (non EMS) : Event Commission',
    '4530 Donations',
    '4550 Oth Income',
    '4560 Oth Income : Advertising Income',
    '4570 Oth Income : Event Management Services (non CPs)',
    '4590 Oth Income : Other Revenue',
    '4605 Collaborative Projects : Event Management Services Revenue',
    '4610 Collaborative Projects : Marketing Services Revenue',
    '4615 Collaborative Projects : IT Services Revenue',
    '4620 Collaborative Projects : Project Coordination Services Revenue',
    '4625 Collaborative Projects : License Scanning Compliance Revenue',
    '4690 Collaborative Projects : SG&A - CPs',
  ],
  deferredRevenueAccount: [
    'Deferred Revenue - Others',
    'Deferred Revenue - Memberships',
    'Deferred Revenue - Direct Funds',
  ],
  department: ['Events', 'Sales', 'Training', 'Training (Linux Labs)'],
  urlRegex: /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
  orgUrlRegex: /^(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,

  defaultLocale: 'en-US',
  dateFormatForDateFormatPipe: 'mediumDate',
  timeFormatForDateFormatPipe: 'mediumTime',
  dateTimeFormatForDateFormatPipe: 'medium',
  dataGridTypes: {
    date: 'date',
    time: 'time',
    dateTime: 'datetime',
    currency: 'currency',
  },
  pdfModalComponentConstants: {
    title: 'Document',
    closeText: 'Close',
    buttonText: 'Review Document',
    pdfTextSize: 'xl',
  },
  adminFoundationSections: [
    {
      name: 'Essentials',
      icon: '',
      state: '',
    },
    {
      name: 'Legal',
      icon: 'fas fa-gavel',
      state: 'Pending',
    },
    {
      name: 'Finance',
      icon: 'fas fa-hand-holding-usd',
      state: 'Incomplete',
    },
    {
      name: 'Membership',
      icon: 'fas fa-user-circle',
      state: 'Attention',
    },
    {
      name: 'User Groups',
      icon: 'fas fa-users',
      state: 'Incomplete',
    },
    {
      name: 'Managed Services',
      icon: 'fas fa-cogs',
      state: 'Incomplete',
    },
    {
      name: 'IT Services',
      icon: 'fas fa-cog',
      state: 'Incomplete',
    },
  ],
  adminProjectSections: [
    {
      name: 'User Groups',
      icon: 'fas fa-users',
      state: 'Incomplete',
    },
    {
      name: 'Managed Services',
      icon: 'fas fa-cogs',
      state: 'Incomplete',
    },
    {
      name: 'IT Services',
      icon: 'fas fa-cog',
      state: 'Incomplete',
    },
  ],

  vulnerabilityStatisticsColor: '#7EB6D8',
  vulnerabilitiesFound: [
    { projectName: 'Kubernetes', vulnerabilities: 353000 },
    { projectName: 'Appium', vulnerabilities: 221474 },
    { projectName: 'Horovod', vulnerabilities: 18900 },
    { projectName: 'Eslint', vulnerabilities: 82000 },
    { projectName: 'Grunt', vulnerabilities: 132000 },
    { projectName: 'Sawtooth', vulnerabilities: 434111 },
    { projectName: 'Grid', vulnerabilities: 334133 },
    { projectName: 'Transact', vulnerabilities: 222566 },
    { projectName: 'Ursa', vulnerabilities: 134522 },
    { projectName: 'Explorer', vulnerabilities: 555221 },
    { projectName: 'Nodejs', vulnerabilities: 123553, fixed: true },
    { projectName: 'Docker', vulnerabilities: 345253, fixed: true },
    { projectName: '.Net', vulnerabilities: 345030, fixed: true },
    { projectName: 'Gulp', vulnerabilities: 123553, fixed: true },
    { projectName: 'Webpack', vulnerabilities: 593933, fixed: true },
    { projectName: 'Linux', vulnerabilities: 333093, fixed: true },
    { projectName: 'Jaeger', vulnerabilities: 449504, fixed: true },
    { projectName: 'Fluentd', vulnerabilities: 444033, fixed: true },
    { projectName: 'CNI', vulnerabilities: 994584, fixed: true },
    { projectName: 'Vites', vulnerabilities: 132466, fixed: true },
  ],
  securityDoughnutChartConfig: {
    element: {
      color: '#000',
      fontStyle: 'Source Sans Pro, sans-serif',
      sidePadding: 15,
    },
    cutoutPercentage: 80,
    toolTipsCallbacksFactories: {
      titleFactory: (prefix = '', suffix = '') => (toolTipItem, data) =>
        `${prefix} ${data.labels[toolTipItem[0].index]} ${suffix}`,
      labelFactory: (prefix = '', suffix = '') => (v, data) =>
        `${prefix} ${data.datasets[0].data[v.index]} ${suffix}`,
    },
  },
  vulnerabilityRemediationRateChartConfig: {
    scales: {
      yAxes: [{ ticks: { min: 1, max: 2000, maxTicksLimit: 10 } }],
    },
    shared: {
      datalabels: {
        display: true,
        color: 'rgb(37, 37, 37)',
      },
    },
    stackLabels: ['FIXES RECOMMENDED', 'NO FIXES RECOMMENDED'],
    stacksBackgroundColors: ['rgb(0, 153, 204)', 'rgb(141, 215, 240)'],
  },
  numberWidgetDefaultFontSize: 70,
  regexPhoneNumber: /[0-9\+\-\ ]/,
  regexReplace: /^\s+|\s+$/g,
  regexCharacters: /[^a-z0-9 -]/g,
  routerForRedirectForbidden: '/',
  securitySeverity: ['All', 'High', 'Medium', 'Low'],
  securityStatus: ['All', 'Open', 'Close'],
  resourceTypes: {
    audio: 'audio',
    video: 'video',
    pdf: 'pdf',
    image: 'image',
    link: 'link',
    doc: 'doc',
    code: 'code',
    archive: 'archive',
    other: 'other',
  },
  foundation: 'Foundation',
  projectGroup: 'Project Group',
  project: 'Project',
  foundationDefaultIcon: 'fas fa-brackets-curly',
  projectDefaultIcon: 'fas fa-code',
  noDescriptionText: 'No description available',
  defaultFoundationColor: '#cccccc',
  viewProjectsDetails: 'View Details',
  lfSponsored: 'LF Supported',
  summaryTableHeader: [
    'projects',
    'high severity',
    'medium severity',
    'low severity',
    'total issues',
    'updated',
  ],
  redirectUrlLocalStorageProp: 'redirectUrl',
  projectsStackDepthDataLabels: ['0-2', '3-5', '6-8', '9-11', '>=12'],
  bubbleChartColors: ['#a0d682', '#0099cd', '#ff595a', '#f4b626', '#7564a0'],
  changePasswordErrors: {
    patternError:
      'Must have at least 8 characters, including upper, lower, numeric, and special characters.',
    mismatchError: 'Passwords do not match',
    serverError: 'Failed to change your password, try again',
  },
  projectTransitiveDependenciesDefaultBorderColor: [
    'rgb(175, 211, 231)',
    'rgb(247, 225, 171)',
    'rgb(252, 186, 187)',
    'rgb(214, 234, 203)',
    'rgb(196, 190, 214)',
  ],
  projectTransitiveDependenciesDefaultBackgroundColor: [
    'rgb(175, 211, 231)',
    'rgb(247, 225, 171)',
    'rgb(252, 186, 187)',
    'rgb(214, 234, 203)',
    'rgb(196, 190, 214)',
  ],
  mockAvatar: '',
  dateList: ['ALL TIME', 'LAST YEAR', 'CURRENT YEAR'],
  statusOK: '200',
  updateFlag: 'update',
  addNewCLAManagerText:
    'A request has been sent to NAME to create an LF Login. They must create an LF Login before they can be added as a CLA Manager.',
  addNewClaManagerFailure: (email: string, url: string) =>
    `The user ${email} does not have an associated LF Login account. Before they can be added as a CLA Manager, please ask them to create an LF Login account by going to this <a href="${url}" target="_blank">portal</a>`,
  addCLAManagerText: ' NAME has been added as a CLA Manager for PROJECTNAME.',
  loader: '/assets/img/spinners/spinner.svg',
  claSignatory: 'cla-signatory',
  claManager: 'cla-manager',
  claManagerDesignee: 'cla-manager-designee',
  excludedRolesForOrgScope: [
    'cla-manager',
    'cla-signatory',
    'cla-manager-designee',
    'company-admin',
    'project-manager',
    'community-program-manager',
  ],
  allowedRolesForOrgScope: ['contact'],
  githubIcon: './assets/img/github-icon.jpeg',
  domainIcon: './assets/img/svgs/domain-icon.svg',
  emailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  fullNameRegex: /^(?:[a-zA-Z]['.-]? ?)+$/,
  usernameRegex: /^[a-z][a-z0-9.]+[a-z0-9]$/,
  nameRegex: /^[aA-zZ]'?[- a-zA-Z]+$/,
  domainREgex: /^(?:http(s)?:\/\/)?(\*\.)?[\w.-]{1,100}(?:\.[\w\.-]{1,55})+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]{1,100}$/,
  githubUsernameRegex: /^[a-z\d](?:[a-z\d]|[-_](?=[a-z\d])){0,38}$/i,
  gitlabUsernameRegex: /^[a-z\d][a-z\d-._]{2,255}$/i,
  gitlabGroupRegex: /^(?:http(s)?:\/\/)?(?:www\.)?(\w+[\w-]+\w+\.)?gitlab\.com[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]{3,100}$/i,
  nameSize: 30,
  fullNameSize: 60,
  claManagerAddFailed: 'Failed to add a new cla manager',
  claManagerLabel: 'Successfully Added',
  claManagerSuccessIcon: 'fa-check-circle',
  claManagerFailIcon: 'fa-exclamation-circle',
  claTicketSupport:
    'https://jira.linuxfoundation.org/servicedesk/customer/portal/4',
  fileIcon: '/assets/img/svgs/file-alt.svg',
  claManagerMessage: `Note: CLA Managers can edit the list of authorized contributors. If a CLA Manager also plans to contribute code themselves, they should add themselves to the Approved List.`,
  cclaSignedMessage:
    'There might be a delay in enabling of "Add CLA Manager" & "Add Approval Criteria" buttons, please refresh the page',
  communityMemberRole: {
    initEmptyProjectMembers:
      'This organization does not have any committee members yet',
    emptyProjectMembers: 'No results were found',
  },
  membershipEmptyProjectMessage: 'Select Project to view Membership Roles',
  organizationAdministrator: {
    userIdNotVerified: 'Id is not verified',
    expiredInvite: 'Invitation has expired.',
    isAppointed: 'Added by another admin',
    notAppointed: 'Entitlements',
    entitlements: 'This user is a primary contact or CLA manager',
    resendInviteText: 'Resend Invite',
  },
  organizationMemberRoleService: {
    alerts: {
      resendInvitation: {
        success: (name: string) =>
          `Team member roles invitation for ${name} was sent Successfully`,
        failure: 'Error While Sending Invitation: ',
      },
    },
    warning: {
      userIdNotVerified: 'Id is not verified',
    },
    callToAction: {
      expiredInvite: 'Invitation has expired.',
    },
    apiErrorCodeWaringText: {
      400: 'Bad Request -',
      401: 'Unauthorized',
      403: 'Insufficient privilege to execute action',
      409: 'Duplicate Resource - the current role and project selection is conflicting with existing role and project',
      404: 'Not Found -',
      422: 'Error -',
    },
  },
  header: {
    supportLink:
      'https://jira.linuxfoundation.org/servicedesk/customer/portal/4',
    docslink: 'https://docs.linuxfoundation.org/lfx/easycla',
  },
  individualNoAccount: 'Individual - No Account',
  standAloneProjectLinuxFoundationName: 'The Linux Foundation',
  standAloneProjectLLCProjectFoundationName: 'LF Projects, LLC',
  requestSupportTicketLink:
    'https://jira.linuxfoundation.org/plugins/servlet/theme/portal/4/create/383',
  projectType: 'Project',
  claAssignManagerTicket:
    'https://jira.linuxfoundation.org/plugins/servlet/theme/portal/4/create/143',
};
