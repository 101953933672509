import gql from 'graphql-tag';

export const organizationSigningEntityQuery = gql`
  query organizationSigningEntity($signingEntityId: String!) {
    organizationSigningEntityById(signingEntityId: $signingEntityId) {
      companyAcl
      companyExternalId
      companyId
      companyManagerId
      companyName
      signingEntityName
    }
  }
`;
