import gql from 'graphql-tag';

export const registerNewDomainMutation = gql`
  mutation registerNewDomainMutation($domainInfo: RegisterDomainInput!) {
    registerDomain(input: $domainInfo) {
      code
      message
      success
    }
  }
`;
