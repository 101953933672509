/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '@services';
import { ContextMenuAccess } from '@models';
import { generalConstants } from '@config';

export const accessKey = generalConstants.accessKey;
export const userRole = generalConstants.currentContext;

@Injectable({
  providedIn: 'root',
})
export class AccessControlGuard implements CanActivate {
  menus: ContextMenuAccess[];
  selectedRole;

  constructor(private storageService: StorageService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const accessCode = 'accessCode';

    // this.validateAccess(next.data[accessCode]);
    return true;
  }

  validateAccess(accessCode) {
    this.storageService.getItem(userRole).subscribe(role => {
      this.selectedRole = role;
      // this.sidebarService.getSidebarMenus(this.selectedRole).subscribe((result => {
      //   this.menus = result;
      //   if (!this.isAccessPresent(accessCode)) {
      //     this.redirectToDefaultPage();
      //   }
      // }));
    });
  }

  isAccessPresent(accessCode) {
    for (const menu of this.menus) {
      const accesses = menu.accesses;

      for (const access of accesses) {
        if (access.accessCode === accessCode) {
          return true;
        }
      }
    }

    return false;
  }

  redirectToDefaultPage() {
    for (const menu of this.menus) {
      if (menu.defaultPage) {
        this.router.navigate([menu.url]);

        return false;
      }
    }
  }
}
