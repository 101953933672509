import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckAuthAndPermissionsGuard, ContextRedirectGuard } from '@guards';
import { AuthInterceptorService } from '@interceptors';
import { generalConstants, roleContextMapping } from '@lfx/config';
import { ForbiddenComponent } from '@lfx/core/components/forbidden/forbidden.component';
import { NotFoundComponent } from '@lfx/core/components/not-found/not-found.component';
import { UserContext } from '@lfx/core/models';
import { UnauthorizedComponent } from '@lfx/core/containers/unauthorized/unauthorized.component';
import { ViewSizeRedirectGuard } from '@lfx/core/guards/view-size-redirect.guard';
import { NotSupportedViewComponent } from '@lfx/core/containers/not-supported-view/not-supported-view.component';

const routes: Routes = [
  {
    path: '',
    component: NotFoundComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [
      CheckAuthAndPermissionsGuard,
      ContextRedirectGuard,
      ViewSizeRedirectGuard,
    ],
  },
  // { path: 'account-settings', redirectTo: 'user/account-settings' },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    canActivate: [ContextRedirectGuard],
  },
  {
    path: 'not-supported-view',
    component: NotSupportedViewComponent,
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('./modules/notifications/notifications.module').then(
        m => m.NotificationsModule
      ),
    outlet: 'notifications',
    runGuardsAndResolvers: 'always',
    canLoad: [CheckAuthAndPermissionsGuard],
    data: {
      permissions: {
        only: ['feature_notifications_panel'],
        redirectTo: generalConstants.routerForRedirectForbidden,
      },
    },
  },
  {
    path: 'company',
    loadChildren: () =>
      import('./modules/company/company.module').then(m => m.CompanyModule),
    runGuardsAndResolvers: 'always',
    canLoad: [CheckAuthAndPermissionsGuard],
    canActivate: [ContextRedirectGuard, ViewSizeRedirectGuard],
    data: {
      permissions: {
        only: ['me_orgs_view'],
        redirectTo: generalConstants.routerForRedirectForbidden,
      },
    },
  },
  {
    path: 'foundation',
    loadChildren: () =>
      import('./modules/foundation/foundation.module').then(
        m => m.FoundationModule
      ),
    canLoad: [CheckAuthAndPermissionsGuard],
    canActivate: [ContextRedirectGuard, ViewSizeRedirectGuard],
    runGuardsAndResolvers: 'always',
    data: {
      permissions: {
        only: Object.entries(roleContextMapping)
          .filter(v => v[1] === UserContext.Company)
          .map(v => v[0]),
        redirectTo: generalConstants.routerForRedirectForbidden,
      },
    },
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./modules/user/user.module').then(m => m.UserModule),
    canLoad: [CheckAuthAndPermissionsGuard],
    runGuardsAndResolvers: 'always',
    data: {
      permissions: {
        only: ['me_view'],
        redirectTo: generalConstants.routerForRedirectForbidden,
      },
    },
  },
  {
    path: 'cla',
    loadChildren: () =>
      import('./modules/contributor-license-agreement/cla.module').then(
        m => m.CLAModule
      ),
    canLoad: [CheckAuthAndPermissionsGuard],
    data: {
      permissions: {
        only: ['feature_cla'],
        redirectTo: generalConstants.routerForRedirectForbidden,
      },
    },
  },
  {
    path: 'security',
    loadChildren: () =>
      import('./modules/security/security.module').then(m => m.SecurityModule),
    canLoad: [CheckAuthAndPermissionsGuard],
    data: {
      permissions: {
        only: ['feature_security'],
        redirectTo: generalConstants.routerForRedirectForbidden,
      },
    },
  },
  {
    path: 'statistics',
    loadChildren: () =>
      import('./modules/dev-analytics/dev-analytics.module').then(
        m => m.DevAnalyticsModule
      ),
    canLoad: [CheckAuthAndPermissionsGuard],
    runGuardsAndResolvers: 'always',
    data: {
      permissions: {
        only: Object.entries(roleContextMapping)
          .filter(v => v[1] === UserContext.Company)
          .map(v => v[0]),
        redirectTo: generalConstants.routerForRedirectForbidden,
      },
    },
  },
  {
    path: 'crowdfunding',
    loadChildren: () =>
      import('./modules/crowdfunding/crowdfunding.module').then(
        m => m.CrowdfundingModule
      ),
    canLoad: [CheckAuthAndPermissionsGuard],
    runGuardsAndResolvers: 'always',
    data: {
      permissions: {
        only: 'feature_crowdfunding',
        redirectTo: generalConstants.routerForRedirectForbidden,
      },
    },
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/admin/admin.module').then(m => m.AdminModule),
    canLoad: [CheckAuthAndPermissionsGuard],
    runGuardsAndResolvers: 'always',
    data: {
      permissions: {
        only: [
          'project_view',
          'project_partial_update',
          'project_billing_view',
          'project_billing_update',
          'project_netsuite_view',
          'project_netsuite_update',
        ],
        redirectTo: generalConstants.routerForRedirectForbidden,
      },
    },
  },
  {
    path: '403',
    component: ForbiddenComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
  ],
})
export class AppRoutingModule {}
