'use strict';

export const foundationMenus = [
  'dashboard',
  'statistics',
  'security',
  'cla',
  'events',
  'training',
  'certification',
];
