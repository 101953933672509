import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './not-supported-view.component.html',
})
export class NotSupportedViewComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
