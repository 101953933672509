import gql from 'graphql-tag';

export const linkSocialIdentityMutation = gql`
  mutation linkUserIdentity($input: LinkUserIdentityInput!) {
    linkUserIdentity(input: $input) {
      code
      message
      success
      identities {
        userId
        provider
        connection
        isSocial
      }
    }
  }
`;
