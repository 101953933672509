import gql from 'graphql-tag';

export const sendNewMemberInviteMutation = gql`
  mutation sendInvite($input: SendInviteInput!) {
    sendInvite(input: $input) {
      code
      message
      success
      invitation {
        status
        email
        type
      }
    }
  }
`;
