import gql from 'graphql-tag';

export const allProjectsQuery = gql`
  query allProjects {
    projects {
      badgeUrl
      autoJoinEnabled
      enabledServices
      membershipAutorenew
      name
      parent
      status
      category
      createdDate
      endDate
      id
      modifiedDate
      projectType
      systemModStamp
      type
      description
      logoUrl
      colorBand
      tag
      community {
        contributors
        commits
      }
      organization {
        contributors
        commits
        totalCodeContribution
      }
      groupName
    }
  }
`;
