import gql from 'graphql-tag';

export const vulnerabilityDetectionStatisticsQuery = gql`
  query vulnerabilityDetectionStatistics($input: SecurityStatisticsInput) {
    vulnerabilityDetectionStatistics(input: $input) {
      projectScanned
      vulnerabilitiesFound
      fixesRecommended
      uniqueLicenses
      libraries
      vulnerabilitiesFixed
      totalRepositories
    }
  }
`;
