import gql from 'graphql-tag';

export const createUserRoleScopeMutation = gql`
  mutation createUserRoleScopeForService(
    $input: CreateUserRoleScopeForServiceInput!
  ) {
    createUserRoleScopeForService(input: $input) {
      code
      message
      success
      inviteSent
    }
  }
`;

export const createUserRoleScopesMutation = gql`
  mutation createUserRoleScopes($input: [CreateUserRoleScopeForServiceInput]!) {
    createUserRoleScopes(input: $input) {
      code
      message
      success
      inviteSent
    }
  }
`;
