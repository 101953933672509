import gql from 'graphql-tag';

export const updateFoundationLegalMutation = gql`
  mutation updateFoundationLegal($legalInfo: UpdateFoundationLegalInput!) {
    updateFoundationLegal(input: $legalInfo) {
      code
      message
      success
      foundationLegal {
        legalEntity
        formationDate
        dissolutionDate
        entityType
        msaUrl
        charterUrl
        cocUrl
      }
    }
  }
`;
