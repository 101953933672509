import gql from 'graphql-tag';

export const vulnerabilityDetectionLeaderBoardQuery = gql`
  query vulnerabilityDetectionLeaderBoard(
    $input: VulnerabilityStatisticsLeaderboardInput
  ) {
    vulnerabilityDetectionLeaderBoard(input: $input) {
      projectName
      logoUrl
      vulnerabilities
      fixed
      allVulnerabilityData
    }
  }
`;
