import gql from 'graphql-tag';

export const companyProjectMetricsQuery = gql`
  query companyProjectMetrics($input: CompanyProjectMetricsInput!) {
    companyProjectMetrics(input: $input) {
      claManagersCount
      companyId
      companyName
      contributorsCount
      projectId
      projectName
      projectLogoUrl
    }
  }
`;
