import gql from 'graphql-tag';

export const allOrganizationsQuery = gql`
  query allOrganizations {
    organizations {
      id
      name
      description
      logoUrl
      phone
      website
    }
  }
`;
