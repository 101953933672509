import { Injectable } from '@angular/core';
import {
  UrlTree,
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { contextDefaultRoutes } from '@lfx/config';
import { UserService } from '@services';
import { UserContext } from '@models';

@Injectable({
  providedIn: 'root',
})
export class ContextRedirectGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.userService.getUserContexts().pipe(
      map(contexts => {
        let url = '';
        const path = route.url[0] && route.url[0].path;

        if (contexts.includes(UserContext.Admin)) {
          url = contextDefaultRoutes.get(UserContext.Admin);
        } else if (contexts.includes(UserContext.Company)) {
          if (path === 'foundation' || path === 'company') {
            return true;
          }
          url = contextDefaultRoutes.get(UserContext.Company);
        } else if (contexts.includes(UserContext.Individual)) {
          if (path === 'unauthorized') {
            return true;
          }
          url = contextDefaultRoutes.get(UserContext.Individual);
        }

        if (url) {
          return this.router.createUrlTree([url]);
        } else {
          return false;
        }
      })
    );
  }
}
