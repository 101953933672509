import { PageIds } from '@config';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from '@environment';
import { easyclaHelp, pageSettings } from '@config';
import { CommunityInitiative, Foundation } from '@models';
import {
  AuthService,
  CrowdfundingService,
  FoundationService,
  NotificationsService,
  UserService,
} from '@services';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { generalConstants } from '@config';

declare global {
  interface Window {
    DD_RUM: any;
  }
}

@Component({
  selector: 'lfx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'lfx';
  pageSettings;
  pageHasScroll;
  helpUrl: string;
  myCommunityInitiatives$: Observable<CommunityInitiative[]>;

  constructor(
    public authService: AuthService,
    public userService: UserService,
    private notificationService: NotificationsService,
    private route: ActivatedRoute,
    private router: Router,
    private foundationService: FoundationService,
    private crowdfundingService: CrowdfundingService,
    private gtmService: GoogleTagManagerService
  ) {
    this.pageSettings = pageSettings;
    this.pageSettings.pageWithFooter = true;

    if (environment.production && environment.gtmId) {
      this.router.events.forEach(item => {
        if (item instanceof NavigationEnd) {
          const gtmTag = {
            event: 'page',
            pageName: item.url,
          };

          this.gtmService.pushTag(gtmTag);
        }
      });
    }

    this.setUserInLFxHeader();
  }

  ngOnInit() {
    this.userService.initializeFavourite();
    this.myCommunityInitiatives$ = this.crowdfundingService
      .getMyInitiatives()
      .pipe(catchError(() => of(null)));
    const script = document.createElement('script');

    script.setAttribute('src', environment.lfxHeaderUrl);
    document.head.appendChild(script);
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        switchMap(() => this.getRouteHelpPath())
      )
      .subscribe(url => this.setHelpPath(url));
    this.initLFXHeaderLinks();
  }

  // set page right collapse
  onToggleSidebarRight(val: boolean): void {
    if (this.pageSettings.pageSidebarTwo) {
      this.pageSettings.pageSidebarTwo = false;
    } else {
      this.pageSettings.pageSidebarTwo = true;
    }
  }

  // hide mobile sidebar
  onHideMobileSidebar(val: boolean): void {
    if (this.pageSettings.pageMobileSidebarToggled) {
      if (this.pageSettings.pageMobileSidebarFirstClicked) {
        this.pageSettings.pageMobileSidebarFirstClicked = false;
      } else {
        this.pageSettings.pageMobileSidebarToggled = false;
      }
    }
  }

  // toggle mobile sidebar
  onToggleMobileSidebar(val: boolean): void {
    if (this.pageSettings.pageMobileSidebarToggled) {
      this.pageSettings.pageMobileSidebarToggled = false;
    } else {
      this.pageSettings.pageMobileSidebarToggled = true;
      this.pageSettings.pageMobileSidebarFirstClicked = true;
    }
  }

  // hide right mobile sidebar
  onHideMobileRightSidebar(val: boolean): void {
    if (this.pageSettings.pageMobileRightSidebarToggled) {
      if (this.pageSettings.pageMobileRightSidebarFirstClicked) {
        this.pageSettings.pageMobileRightSidebarFirstClicked = false;
      } else {
        this.pageSettings.pageMobileRightSidebarToggled = false;
      }
    }
  }

  // toggle right mobile sidebar
  onToggleMobileRightSidebar(val: boolean): void {
    if (this.pageSettings.pageMobileRightSidebarToggled) {
      this.pageSettings.pageMobileRightSidebarToggled = false;
    } else {
      this.pageSettings.pageMobileRightSidebarToggled = true;
      this.pageSettings.pageMobileRightSidebarFirstClicked = true;
    }
  }

  setUserInLFxHeader(): void {
    const lfHeaderElement: any = document.getElementById('lfx-header');

    if (!lfHeaderElement) {
      return;
    }

    lfHeaderElement.beforeLogout = () => {
      this.authService.clearSession();
    };

    if (this.authService.userProfile$) {
      this.authService.userProfile$.subscribe(data => {
        if (data) {
          lfHeaderElement.authuser = data;
          /* eslint-disable prefer-arrow/prefer-arrow-functions */
          window.DD_RUM.onReady(function () {
            window.DD_RUM.setUser({
              id: data['https://sso.linuxfoundation.org/claims/username'],
            });
          });
        }
      });
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

    if (top > 0) {
      this.pageHasScroll = true;
    } else {
      this.pageHasScroll = false;
    }
  }

  private setHelpPath(helpPath: string) {
    this.helpUrl = helpPath;
  }

  private getRouteHelpPath(): Observable<string> {
    let childRoute = this.route.root;

    while (childRoute.firstChild) {
      childRoute = childRoute.firstChild;
    }

    return childRoute.data.pipe(
      map(
        data =>
          easyclaHelp.easyClaHelpPaths[data.pageId] ||
          easyclaHelp.easyClaHelpPaths[PageIds.Faq]
      )
    );
  }

  private initLFXHeaderLinks() {
    const header: any = document.getElementById('lfx-header');

    if (header) {
      header.docslink = generalConstants.header.docslink;
      header.supportlink = generalConstants.header.supportLink;
    }
  }
}
