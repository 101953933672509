import gql from 'graphql-tag';

export const projectMeetingsQuery = gql`
  query projectMeeting($id: ID!) {
    projectMeeting(id: $id) {
      name
      date
    }
  }
`;
