import { Injectable } from '@angular/core';
import {
  ProjectInfrastructureSummary,
  ProjectInfrastructureInfoResult,
} from '@models';
import { Observable } from 'rxjs';
import { ProjectInfrastructureServiceGql } from '../gql';

import { AddAliasInput } from '../gql/services/inputs';

@Injectable({
  providedIn: 'root',
})
export class ProjectInfrastructureService {
  constructor(
    private projectInfrastructureServiceGql: ProjectInfrastructureServiceGql
  ) {}

  public getProjectInfrastructureSummary(
    projectName
  ): Observable<ProjectInfrastructureSummary> {
    return this.projectInfrastructureServiceGql.getProjectInfrastructureSummary(
      projectName
    );
  }

  public addEmailAlias(
    input: AddAliasInput
  ): Observable<ProjectInfrastructureInfoResult> {
    return this.projectInfrastructureServiceGql.addEmailAlias(input);
  }
}
