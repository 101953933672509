export interface FoundationLegal {
  id?: string;
  legalEntity: string;
  entityType: EntityType;
  formationDate: string;
  dissolutionDate: string;
  msaUrl: string;
  charterUrl: string;
  cocUrl: string;
}
export enum EntityType {
  DirectedFund = 'Directed Fund',
  Incorporated = 'Incorporated',
  SeparatelyIncorporated = 'Separately Incorporated',
  SeriesLLC = 'Series LLC',
  Unincorporated = 'Unincorporated',
}

export const mockFoundationLegal = (): FoundationLegal => {
  const fl: FoundationLegal = {
    legalEntity: 'OpenTexts Group',
    entityType: EntityType.Incorporated,
    formationDate: '',
    dissolutionDate: '',
    msaUrl: '',
    cocUrl: '',
    charterUrl: '',
  };

  return fl;
};
