import * as faker from 'faker';
import { imagePlaceholders } from '@config';

export interface Person {
  firstName: string;
  lastName: string;
  name: string;
  avatarUrl: string;
}

export const mockPerson = (): Person => {
  const firstName = faker.name.firstName();
  const lastName = faker.name.lastName();
  const name = `${firstName} ${lastName}`;

  return {
    name,
    firstName,
    lastName,
    avatarUrl: imagePlaceholders.user,
  };
};
