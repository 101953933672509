import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { pageSettings } from '@config';
import { InitService } from '@lfx/init.service';
import { AuthService } from '@services';
import { combineLatest, of, Subscription } from 'rxjs';
import { catchError, concatMap, tap } from 'rxjs/operators';

@Component({
  selector: 'lfx-auth-callback',
  templateUrl: './auth-callback.component.html',
})
export class AuthCallbackComponent implements OnInit, OnDestroy {
  pageSettings = pageSettings;
  authCompleteSubscription$: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router,
    private init: InitService
  ) {
    this.pageSettings.pageEmpty = true;
  }

  ngOnInit() {
    this.handleAuthCallback();
  }

  ngOnDestroy() {
    this.pageSettings.pageEmpty = false;
    this.authCompleteSubscription$.unsubscribe();
  }

  private handleAuthCallback() {
    const url = new URL(window.location.href);

    if (url.searchParams.has('code') && url.searchParams.has('state')) {
      let targetRoute = '/';
      const authComplete$ = this.authService.handleRedirectCallback$.pipe(
        tap(cbRes => {
          const returnTo = url.searchParams.get('returnTo');

          targetRoute = returnTo
            ? this.getTargetRouteFromReturnTo(returnTo)
            : this.getTargetRouteFromAppState(cbRes.appState);
        }),
        concatMap(() =>
          combineLatest([
            this.init.initUser(),
            this.authService.isAuthenticated$,
          ])
        ),
        // *info: avoid break the app and allow to re-try
        catchError(() => of(''))
      );

      this.authCompleteSubscription$ = authComplete$.subscribe(() => {
        // *info: on re-try user can be stuck on /auth page
        if (targetRoute === '/auth') {
          targetRoute = '/';
        }

        this.router.navigate([targetRoute]);
      });
    }
  }

  private getTargetRouteFromAppState(appState) {
    if (!appState) {
      return '/';
    }

    const { returnTo, target, targetUrl } = appState;

    return (
      this.getTargetRouteFromReturnTo(returnTo) || target || targetUrl || '/'
    );
  }

  private getTargetRouteFromReturnTo(returnTo: string) {
    if (!returnTo) {
      return '';
    }

    const returnToUrl = new URL(returnTo);

    return returnToUrl.hash || returnToUrl.pathname || '/';
  }
}
