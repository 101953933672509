'use strict';

export const roleHierarchy = [
  'super-admin',
  'lf-admin',
  'org-admin',
  'project-admin',
  'member-admin',
  'program-manager',
  'apprentice',
  'contributor',
  'maintainer',
  'marketing',
  'events',
  'executive',
  'finance',
  'details',
  'member',
  'Mentee',
  'staff',
  'training',
  'user',
];
