export const graphMetaData = [
  {
    label: 'low',
    backgroundColor: 'rgba(35,183,229, 0.75)',
    fill: 'origin',
    data: [],
  },
  {
    label: 'medium',
    backgroundColor: 'rgba(244,168,62,0.85)',
    fill: 'origin',
    data: [],
  },
  {
    label: 'high',
    backgroundColor: 'rgb(238,116,118, 0.9)',
    fill: 'origin',
    data: [],
  },
];
