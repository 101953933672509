import gql from 'graphql-tag';

export const projectTransitiveDependencyQuery = gql`
  query projectTransitiveDependencies($input: ProjectsDependencyInput) {
    projectTransitiveDependencies(input: $input) {
      dependencyCount
      sfdcProjectId
    }
  }
`;
