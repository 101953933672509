import { Injectable } from '@angular/core';
import {
  NgxPermissionsService,
  NgxPermissionsObject,
  NgxRolesService,
} from 'ngx-permissions';
import { Observable, from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PermissionService {
  private loadedPermissions: string[] = [];
  private loadedRoles: string[] = [];
  private loadedContextPermissions: string[] = [];

  constructor(
    private ngxPermissionsService: NgxPermissionsService,
    private ngxRolesService: NgxRolesService
  ) {}

  loadRoles(roles: string[]) {
    this.loadedRoles.forEach(item => this.ngxRolesService.removeRole(item));
    this.loadedRoles = roles;
    roles.forEach(item => this.ngxRolesService.addRole(item, []));
  }

  loadPermissions(permissions: string[]) {
    this.loadedPermissions.forEach(item =>
      this.ngxPermissionsService.removePermission(item)
    );
    this.loadedPermissions = permissions;
    const v = Object.assign(
      {},
      (this.ngxPermissionsService as any).permissionsSource.value
    );

    permissions.forEach(name => {
      v[name] = { name };
    });
    (this.ngxPermissionsService as any).permissionsSource.next(v);
  }

  loadContextPermissions(permissions: string[]) {
    this.loadedContextPermissions.forEach(item =>
      this.ngxPermissionsService.removePermission(item)
    );
    this.loadedContextPermissions = permissions;
    permissions.forEach(item => this.ngxPermissionsService.addPermission(item));
  }

  getPermissions(): Observable<NgxPermissionsObject> {
    return this.ngxPermissionsService.permissions$;
  }

  definePermission(permission) {
    this.ngxPermissionsService.addPermission(permission, () => true);
  }

  removePermission(permission) {
    this.ngxPermissionsService.removePermission(permission);
  }

  clearAllPermissionsForSession() {
    this.ngxPermissionsService.flushPermissions();
  }

  hasPermission(permissions: string | string[]) {
    return from(this.ngxPermissionsService.hasPermission(permissions));
  }
}
