import gql from 'graphql-tag';

export const vulnerabilitiesSeverityQuery = gql`
  query vulnerabilitiesSeverity {
    vulnerabilitiesSeverity {
      low
      medium
      high
    }
  }
`;
