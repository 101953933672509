import gql from 'graphql-tag';

export const createFoundationCommitteesMutation = gql`
  mutation createFoundationCommittees($committeesInfo: CreateCommitteesInput!) {
    createCommittees(input: $committeesInfo) {
      code
      message
      success
      committees {
        id
        collaborationName
        name
        description
        category
        url
      }
    }
  }
`;
