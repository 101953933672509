import gql from 'graphql-tag';

export const foundationStatisticsQuery = gql`
  query foundationStatistics($id: ID!) {
    foundationStatistics(id: $id) {
      community {
        issues {
          count
          percentage
        }
        commits {
          count
          percentage
        }
        contributors {
          count
          percentage
        }
        merges {
          count
          percentage
        }
      }

      repositorySecurity {
        name
        high
        low
        medium
      }

      organizationsRepositoryDetails {
        issues {
          count
          percentage
        }
        commits {
          count
          percentage
        }
        contributors {
          count
          percentage
        }
        merges {
          count
          percentage
        }
      }
      activeContributors {
        name
        avatarUrl
        commits
      }
    }
  }
`;
