import gql from 'graphql-tag';

export const committeeMembersQuery = gql`
  query organizationCommitteeMembers(
    $salesforceId: ID!
    $projectIds: [ID]
    $committeesCategoriesIds: [String]
    $first: Int
    $offset: Int
  ) {
    organization(salesforceId: $salesforceId) {
      committeeMembersConnection(
        projectIds: $projectIds
        committeesCategoriesIds: $committeesCategoriesIds
        first: $first
        offset: $offset
      ) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        committeeMembers {
          appointedBy
          category
          committeeID
          committeeMemberID
          name
          role
          roleStartDate
          roleEndDate
          status
          project {
            id
            name
            logoUrl
          }
          contact {
            id
            lastName
            firstName
            email
            logoUrl
          }
        }
      }
    }
  }
`;
