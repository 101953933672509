import gql from 'graphql-tag';

export const updateCurrentUserMutation = gql`
  mutation updateCurrentUser($userInfo: UpdateCurrentUserInput!) {
    updateCurrentUser(input: $userInfo) {
      code
      message
      currentUser {
        id
        auth0Id
        username
        firstName
        lastName
        fullName
        emails {
          emailAddress
          active
          isDeleted
          isPrimary
          isVerified
        }
        timezone
        modifiedDate
        socialAccounts {
          userId
          provider
          connection
          isSocial
        }
        avatarUrl
      }
    }
  }
`;
