import gql from 'graphql-tag';

export const securityActivityLogQuery = gql`
  query rootSecurityActivityLogs($input: SecurityActivityLogsInput) {
    rootSecurityActivityLogs(input: $input) {
      id
      message
      activityType
      name
      logoUrl
      createdAt
    }
  }
`;
