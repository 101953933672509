import gql from 'graphql-tag';

export const claEnabledProjectsQuery = gql`
  query claEnabledProjects($id: ID!) {
    claEnabledProjects(id: $id) {
      id
      name
      logoUrl
      description
    }
  }
`;
