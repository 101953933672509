import gql from 'graphql-tag';

export const claInsightsQuery = gql`
  query easyClaInsights {
    easyClaInsights {
      claManagersCount
      companiesCount
      companiesProjectContributionCount
      contributorsCount
      corporateContributorsCount
      createdAt
      gerritRepositoriesCount
      githubRepositoriesCount
      individualContributorsCount
      projectsCount
      repositoriesCount
    }
  }
`;
