/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lfx-confirmation-modal',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  @Input() title = '';
  @Input() message = '';
  @Input() confirmLabel = '';
  @Input() rejectLabel = '';
  @Output() confirmAction = new EventEmitter<any>();
  @Output() rejectAction = new EventEmitter<any>();

  isConfirmLoading = false;
  isRejectLoading = false;

  constructor() {}

  ngOnInit() {}

  confirm() {
    this.isConfirmLoading = true;

    this.confirmAction.emit(this.onActionDone.bind(this.isConfirmLoading));
  }

  reject() {
    this.isRejectLoading = true;

    this.rejectAction.emit(this.onActionDone.bind(this.isRejectLoading));
  }

  onActionDone(isLoading: boolean) {
    isLoading = false;
  }
}
