import gql from 'graphql-tag';

export const nonMemberFoundationsQuery = gql`
  query nonMemberFoundationsQuery(
    $withMembersData: Boolean = false
    $withProjectsData: Boolean = false
  ) {
    nonMemberFoundations {
      id
      name
      creationDate
      description
      logoUrl
      colorBand
      tag
      autoJoinEnabled
      projectType
      slug
      members @include(if: $withMembersData) {
        name
        logoUrl
        tier {
          name
        }
      }
      community {
        contributors
        commits
        members {
          id
          name
          description
          logoUrl
          membershipTier
        }
      }
      organization {
        contributors
        commits
        totalCodeContribution
      }
      projects @include(if: $withProjectsData) {
        badgeUrl
        autoJoinEnabled
        enabledServices
        membershipAutorenew
        name
        parent
        status
        category
        createdDate
        endDate
        id
        modifiedDate
        projectType
        systemModStamp
        type
        description
        logoUrl
        colorBand
        tag
        community {
          contributors
          commits
        }
        organization {
          contributors
          commits
          totalCodeContribution
        }
        groupName
        type
      }
    }
  }
`;
