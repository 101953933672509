import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  projectVulnerabilityOverviewQuery,
  projectSecurityIssuesQuery,
  projectSecurityDetailsQuery,
  repositoriesDependenciesVulnerabilitiesQuery,
  foundationProjectVulnerabilityPackages,
  repositoriesLicensesQuery,
  securityProjectListQuery,
  rootVulnerableProjectsQuery,
  vulnerabilitiesSeverityQuery,
  securityActivityLogQuery,
  projectTransitiveDependencyQuery,
  securityLanguagesDistribution,
  projectsRemediationRateDetails,
  foundationVulnerableProjectsQuery,
} from '../queries';
import {
  ProjectVulnerabilityOverviewResult,
  SecurityIssuesResult,
  SecurityProjectDetailsResult,
  RepositoriesDependenciesVulnerabilitiesResult,
  SecurityProjectListResult,
  RootVulnerableProjectsResult,
  RootVulnerabilitiesSeverityResult,
  SecurityActivityLogResult,
  ProjectTransitiveDependencyResult,
  ProjectDependencyStackDepthResult,
  ProjectsRemediationRateDetailsResult,
} from './results';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  VulnerabilitiesOverview,
  SecurityIssues,
  SecurityProjectDetails,
  Dependency,
  VulnerabilityPackagesQueryOptions,
  VulnerabilityPackagesDetailsResult,
  License,
  RepositoriesLicensesResult,
  Foundation,
  VulnerableProjectModel,
  VulnerabilitiesSeverity,
  ActivityLog,
  VulnerabilityDetectionStatistics,
  VulnerabilityDetectionLeaderBoard,
  VulnerabilityUniqueLicenseDetails,
  ProjectDependencyStackDepth,
  ProjectTransitiveDependency,
  SecurityLanguagesDistribution,
  SecurityLanguagesDistributionInput,
  FoundationProjectVulnerabilitiesDetected,
} from '@models';
import {
  SecurityProjectDetailsInput,
  ProjectDependencyInput,
  SecurityActivityLogsInput,
} from './inputs';
import { VulnerabilityDetectionStatisticsResult } from '@lfx/core/gql/services/results/project-vulnerability-detection-statistics-result';
import { vulnerabilityDetectionStatisticsQuery } from '@lfx/core/gql/queries/security/project-vulnerability-detection-statistics';
import { vulnerabilityDetectionLeaderBoardQuery } from '@lfx/core/gql/queries/security/vulnerability-detection-leader-board';
import { VulnerabilityDetectionLeaderBoardResult } from '@lfx/core/gql/services/results/vulnerability-detection-leader-board-result';
import { vulnerabilityUniqueLicensesDetailsQuery } from '@lfx/core/gql/queries/security/vulnerability-unique-licenses-detail';
import { VulnerabilityUniqueLicensesDetailsResult } from '@lfx/core/gql/services/results/vulnerability-unique-licenses-details-result';
import { projectsDependencyStackDepthQuery } from '../queries/security/project-dependency-stack-depth';
import { VulnerableProjectsResult } from './results';
import { foundationProjectVulnerabilityDetectedQuery } from '@lfx/core/gql/queries/security/foundation-project-vulnerability-detected';
import { FoundationProjectVulnerabilityDetectedResult } from '@lfx/core/gql/services/results/foundation-project-vulnerability-detected-result';
import { FoundationProjectVulnerabilityInput } from '@lfx/core/gql/services/inputs/foundation-project-vulnerability-input';

@Injectable({
  providedIn: 'root',
})
export class SecurityServiceGql {
  constructor(private apollo: Apollo) {}

  getProjectVulnerabilityOverview(
    vulnerabilityOverviewInput
  ): Observable<VulnerabilitiesOverview> {
    return this.apollo
      .watchQuery<ProjectVulnerabilityOverviewResult>({
        query: projectVulnerabilityOverviewQuery,
        variables: {
          vulnerabilityOverviewInput,
        },
      })
      .valueChanges.pipe(map(res => res.data.projectVulnerabilityOverview));
  }

  getVulnerableProjects(): Observable<VulnerableProjectModel[]> {
    return this.apollo
      .watchQuery<RootVulnerableProjectsResult>({
        query: rootVulnerableProjectsQuery,
      })
      .valueChanges.pipe(map(res => res.data.rootVulnerableProjects));
  }

  getRepositoriesDependenciesVulnerabilities(input): Observable<Dependency[]> {
    return this.apollo
      .watchQuery<RepositoriesDependenciesVulnerabilitiesResult>({
        query: repositoriesDependenciesVulnerabilitiesQuery,
        variables: {
          input,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.repositoriesDependenciesVulnerabilities)
      );
  }

  getFoundationProjectVulnerabilityDetected(
    input: FoundationProjectVulnerabilityInput
  ): Observable<FoundationProjectVulnerabilitiesDetected[]> {
    return this.apollo
      .watchQuery<FoundationProjectVulnerabilityDetectedResult>({
        query: foundationProjectVulnerabilityDetectedQuery,
        variables: {
          input,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.foundationProjectVulnerabilityDetected)
      );
  }

  getVulnerabilitiesSeverity(): Observable<VulnerabilitiesSeverity> {
    return this.apollo
      .watchQuery<RootVulnerabilitiesSeverityResult>({
        query: vulnerabilitiesSeverityQuery,
      })
      .valueChanges.pipe(map(res => res.data.vulnerabilitiesSeverity));
  }

  getVulnerabilityStatistics(
    input
  ): Observable<VulnerabilityDetectionStatistics> {
    return this.apollo
      .watchQuery<VulnerabilityDetectionStatisticsResult>({
        query: vulnerabilityDetectionStatisticsQuery,
        variables: {
          input,
        },
      })
      .valueChanges.pipe(map(res => res.data.vulnerabilityDetectionStatistics));
  }

  getVulnerabilityDetectionLeaderBoard(
    input
  ): Observable<VulnerabilityDetectionLeaderBoard[]> {
    return this.apollo
      .watchQuery<VulnerabilityDetectionLeaderBoardResult>({
        query: vulnerabilityDetectionLeaderBoardQuery,
        variables: {
          input,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.vulnerabilityDetectionLeaderBoard)
      );
  }

  getVulnerabilityUniqueLicensesDetails(
    input
  ): Observable<VulnerabilityUniqueLicenseDetails> {
    return this.apollo
      .watchQuery<VulnerabilityUniqueLicensesDetailsResult>({
        query: vulnerabilityUniqueLicensesDetailsQuery,
        variables: {
          input,
        },
      })
      .valueChanges.pipe(
        map(res => res.data.vulnerabilityUniqueLicensesDetails)
      );
  }

  getSecurityIssues(id: string): Observable<SecurityIssues[]> {
    return this.apollo
      .watchQuery<SecurityIssuesResult>({
        query: projectSecurityIssuesQuery,
        variables: {
          id,
        },
      })
      .valueChanges.pipe(map(res => res.data.rootSecurityIssues));
  }

  getSecurityProjectDetails(
    input: SecurityProjectDetailsInput
  ): Observable<SecurityProjectDetails> {
    return this.apollo
      .watchQuery<SecurityProjectDetailsResult>({
        query: projectSecurityDetailsQuery,
        variables: {
          input,
        },
      })
      .valueChanges.pipe(map(res => res.data.rootSecurityProjectDetails));
  }

  getSecurityProjectList(): Observable<Foundation[]> {
    return this.apollo
      .watchQuery<SecurityProjectListResult>({
        query: securityProjectListQuery,
      })
      .valueChanges.pipe(map(res => res.data.rootSecurityProjectList));
  }

  getProjectPackages(
    projectId: string,
    query: VulnerabilityPackagesQueryOptions
  ) {
    return this.apollo
      .watchQuery<VulnerabilityPackagesDetailsResult>({
        query: foundationProjectVulnerabilityPackages,
        variables: {
          input: {
            projectId,
            ...query,
          },
        },
      })
      .valueChanges.pipe(
        map(res => res.data.repositoriesVulnerabilityPackagesDetails)
      );
  }

  getRepositoriesLicenses(input): Observable<License[]> {
    return this.apollo
      .watchQuery<RepositoriesLicensesResult>({
        query: repositoriesLicensesQuery,
        variables: {
          input,
        },
      })
      .valueChanges.pipe(map(res => res.data.vulnerabilityLicensesDetails));
  }

  getSecurityActivityLogs(
    input: SecurityActivityLogsInput
  ): Observable<ActivityLog[]> {
    return this.apollo
      .watchQuery<SecurityActivityLogResult>({
        query: securityActivityLogQuery,
        variables: {
          input,
        },
      })
      .valueChanges.pipe(map(res => res.data.rootSecurityActivityLogs));
  }

  getProjectsTransitiveDependencies(
    input: ProjectDependencyInput
  ): Observable<ProjectTransitiveDependency[]> {
    return this.apollo
      .watchQuery<ProjectTransitiveDependencyResult>({
        query: projectTransitiveDependencyQuery,
        variables: {
          input,
        },
      })
      .valueChanges.pipe(map(res => res.data.projectTransitiveDependencies));
  }

  getProjectsDependencyStackDepth(
    input: ProjectDependencyInput
  ): Observable<ProjectDependencyStackDepth[]> {
    return this.apollo
      .watchQuery<ProjectDependencyStackDepthResult>({
        query: projectsDependencyStackDepthQuery,
        variables: {
          input,
        },
      })
      .valueChanges.pipe(map(res => res.data.projectsDependencyStackDepth));
  }

  getSecurityLanguagesDistribution(
    input: SecurityLanguagesDistributionInput
  ): Observable<SecurityLanguagesDistribution> {
    return this.apollo
      .query<SecurityLanguagesDistribution>({
        query: securityLanguagesDistribution,
        variables: {
          input,
        },
      })
      .pipe(map(res => res.data.securityLanguagesDistribution));
  }

  getProjectRemediationRateDetails() {
    return this.apollo
      .watchQuery<ProjectsRemediationRateDetailsResult>({
        query: projectsRemediationRateDetails,
      })
      .valueChanges.pipe(map(res => res.data.projectsRemediationRateDetails));
  }

  getFoundationVulnerableProjects(input): Observable<VulnerableProjectModel[]> {
    return this.apollo
      .watchQuery<VulnerableProjectsResult>({
        query: foundationVulnerableProjectsQuery,
        variables: {
          input,
        },
      })
      .valueChanges.pipe(map(res => res.data.foundationVulnerableProjects));
  }
}
