import gql from 'graphql-tag';

export const activeClaListQuery = gql`
  query activeClaList($input: ActiveClaListInput!) {
    activeClaList(input: $input) {
      projectId
      projectLogo
      projectName
      projectSfid
      projectType
      signatoryName
      signedOn
      claGroupName
      pdf
      subProjects
    }
  }
`;
