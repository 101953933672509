import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  myMembershipsQuery,
  membershipContactsQuery,
  membershipHistoryQuery,
} from '../queries';
import {
  Membership,
  MembershipContacts,
  GetMembershipContactsQueryParams,
  MembershipContact,
} from '@models';
import {
  MyMembershipsResult,
  GetMembershipContactsResult,
  MembershipHistoryResult,
} from './results';

import {
  updateMembershipContactMutation,
  deleteMembershipContactMutation,
  createMembershipContactMutation,
} from '../mutations';
import { MembershipFilters } from '@lfx/config';

@Injectable({
  providedIn: 'root',
})
export class MembershipServiceGql {
  constructor(private apollo: Apollo) {}

  getMyMemberships(
    loadMainDataOnly = false,
    filters?: MembershipFilters
  ): Observable<Membership[]> {
    return this.apollo
      .watchQuery<MyMembershipsResult>({
        query: myMembershipsQuery,
        variables: {
          loadMainDataOnly,
          filters,
        },
      })
      .valueChanges.pipe(map(res => res.data.myMemberships));
  }

  getMembershipContacts(
    membershipId: string,
    queryParams?: GetMembershipContactsQueryParams,
    useCache?: boolean
  ): Observable<MembershipContacts> {
    return this.apollo
      .watchQuery<GetMembershipContactsResult>({
        query: membershipContactsQuery,
        fetchPolicy: useCache ? 'cache-and-network' : 'network-only',
        variables: {
          input: {
            membershipId,
            queryParams,
          },
        },
      })
      .valueChanges.pipe(map(res => res.data.membershipContacts));
  }

  getMembershipHistory(accountId: string): Observable<Membership[]> {
    return this.apollo
      .watchQuery<MembershipHistoryResult>({
        query: membershipHistoryQuery,
        variables: {
          accountId,
        },
      })
      .valueChanges.pipe(map(res => res.data.membershipHistory));
  }

  updateContact(contact: MembershipContact) {
    return this.apollo
      .mutate<any>({
        mutation: updateMembershipContactMutation,
        variables: {
          input: {
            membershipId: contact.membershipId,
            contactId: contact.contactId,
            update: {
              role: contact.role,
              primaryContact: contact.primaryContact,
            },
          },
        },
      })
      .pipe(map(res => res.data.updateMembershipContact.contact));
  }

  deleteMembershipContact(input: { membershipId: string; contactId: string }) {
    return this.apollo
      .mutate<any>({
        mutation: deleteMembershipContactMutation,
        variables: {
          input,
        },
      })
      .pipe(map(res => res.data.deleteMembershipContact));
  }

  createMembershipContact(input) {
    return this.apollo
      .mutate<any>({
        mutation: createMembershipContactMutation,
        variables: {
          input,
        },
      })
      .pipe(map(res => res.data.createMembershipContact.contact));
  }
}
