import gql from 'graphql-tag';

export const updateInviteMutation = gql`
  mutation updateInvite($input: UpdateInviteInput!) {
    updateInvite(input: $input) {
      code
      message
      success
      invite {
        inviteId
        email
        roleName
        status
      }
    }
  }
`;
