import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import {
  Certification,
  Certifications,
  Training,
  TrainingCertifications,
} from '@models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TrainingCertificationsService {
  constructor(private httpClient: HttpClient) {}

  public getMemberTraining(): Observable<Training> {
    const route = `${environment.apiUrl}/dashboard/certifications-and-training`;

    return this.httpClient
      .get<TrainingCertifications>(route)
      .pipe(map(response => response.training));
  }

  public getMemberCertifications(): Observable<Certifications> {
    const route = `${environment.apiUrl}/dashboard/certifications-and-training`;

    return this.httpClient
      .get<TrainingCertifications>(route)
      .pipe(map(response => response.certifications));
  }

  public getSuggestedCertifications(): Observable<Certification[]> {
    const route = `${environment.apiUrl}/dashboard/certifications-and-training/suggested`;

    return this.httpClient.get<Certification[]>(route);
  }
}
