import gql from 'graphql-tag';

export const updateApprovalListMutation = gql`
  mutation updateApprovalList($input: ApprovalListInput!) {
    updateApprovalList(input: $input) {
      code
      approvalList
      success
      message
    }
  }
`;
