import gql from 'graphql-tag';

export const getInviteQuery = gql`
  query getInvite($inviteId: String!) {
    getInvite(inviteId: $inviteId) {
      data {
        inviteId
        email
        status
        type
        roleName
      }
    }
  }
`;
