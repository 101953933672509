import gql from 'graphql-tag';

export const addEmailAliasMutation = gql`
  mutation addEmailAliasMutation($input: AddAliasInput!) {
    addEmailAlias(input: $input) {
      code
      message
      success
    }
  }
`;
