import gql from 'graphql-tag';

export const updateOrganizationMutation = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      code
      message
      success
      organization {
        id
        billingAddress {
          billingStreet
          billingCity
          billingCountry
          billingPostalCode
          billingState
        }
        description
        github
        gitlab
        industry
        logoUrl
        name
        phone
        source
        type
        website
        createdAt
        employees
        lastModifiedAt
        lastModifiedBy
        link
      }
    }
  }
`;
