import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  demoCommunityInitiatives,
  myCommunityInitiatives,
  suggestedCommunityInitiatives,
  CommunityInitiative,
  Initiative,
} from '@models';
import { convertObjectToArray } from '../helper';

@Injectable({
  providedIn: 'root',
})
export class CrowdfundingService {
  constructor() {}

  public getMyInitiatives(): Observable<CommunityInitiative[]> {
    return of(myCommunityInitiatives) as Observable<CommunityInitiative[]>;
  }

  public getSuggestedInitiatives(): Observable<CommunityInitiative[]> {
    return of(suggestedCommunityInitiatives) as Observable<
      CommunityInitiative[]
    >;
  }

  public getInitiative(initiativeId: string): Observable<Initiative> {
    return of(demoCommunityInitiatives[initiativeId]);
  }

  public getInitiativesByRelation(
    relatedId: string
  ): Observable<CommunityInitiative[]> {
    const matchingInitiatives = convertObjectToArray<CommunityInitiative>(
      demoCommunityInitiatives
    ).filter(i => i.relatedIds.includes(relatedId));

    return of(matchingInitiatives);
  }
}
