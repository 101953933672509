import * as faker from 'faker';

import { Company } from '.';
import { convertObjectToArray } from '../helper';
import { mockList, randomSalesforceId } from '../mocks/generator';
import { demoCompanies } from './company';
import { Email, mockEmail } from './email';
import { imagePlaceholders, SocialAuthProviders } from '@config';

export interface Favorite {
  id: string;
  title: string;
  url?: string;
  favorite: boolean;
}

export interface Profile {
  salesforceId: string;
  firstName: string;
  lastName: string;
  company?: Company;
  primaryEmail: string;
  emails: Email[] | [];
  username: string;
  timezone?: string;
  avatarUrl?: string;
  socialAccounts?: Identity[];
  isSocialLinksDisabled?: boolean;
  favorites?: Favorite[];
}

export interface Identity {
  userId: string;
  provider: SocialAuthProviders;
  connection?: string;
  isSocial?: boolean;
}

export const mockProfile = (): Profile => {
  const username = faker.internet.userName();

  return {
    salesforceId: randomSalesforceId(),
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    primaryEmail: faker.internet.email(),
    emails: mockList(mockEmail, { min: 1, max: 5 }),
    username,
    timezone: null,
    avatarUrl: imagePlaceholders.user,
    company: faker.random.arrayElement(convertObjectToArray(demoCompanies)),
  };
};

export const demoUsers: Record<string, Profile> = {
  'michelle.noorali': {
    ...mockProfile(),
    primaryEmail: 'michelle.noorali@microsoft.com',
    firstName: 'Michelle',
    lastName: 'Noorali',
    company: demoCompanies.microsoft,
  },
  'brandon.philips': {
    ...mockProfile(),
    primaryEmail: 'brandon@ifup.org',
    firstName: 'Brandon',
    lastName: 'Philips',
    company: demoCompanies.tvsd,
  },
  caseyo: {
    ...mockProfile(),
    primaryEmail: 'caseyo@jfrog.com',
    firstName: 'Casey',
    // eslint-disable-next-line prettier/prettier
    lastName: 'O\'Mara',
    company: demoCompanies.jfrog,
  },
  'rob.esker': {
    ...mockProfile(),
    primaryEmail: 'rob.esker@netapp.com',
    firstName: 'Rob',
    lastName: 'Esker',
    company: demoCompanies.netapp,
  },
  apsinha: {
    ...mockProfile(),
    primaryEmail: 'apsinha@google.com',
    firstName: 'Aparna',
    lastName: 'Sinha',
    company: demoCompanies.google,
  },
  aclark: {
    ...mockProfile(),
    primaryEmail: 'aclark@suse.com',
    firstName: 'Alan',
    lastName: 'Clark',
    company: demoCompanies.suse,
  },
  'huanjian.cx': {
    ...mockProfile(),
    primaryEmail: 'huanjian.cx@alibaba-inc.com',
    firstName: 'Robert',
    lastName: 'Chen',
    company: demoCompanies.alibaba,
  },
  caniszczyk: {
    ...mockProfile(),
    primaryEmail: 'caniszczyk@linuxfoundation.org',
    firstName: 'Chris',
    lastName: 'Aniszczyk',
    company: demoCompanies.linuxfoundation,
  },
  argu: {
    ...mockProfile(),
    primaryEmail: 'argu@amazon.com',
    firstName: 'Arun',
    lastName: 'Gupta',
    company: demoCompanies.aws,
  },
  'anni.lai': {
    ...mockProfile(),
    primaryEmail: 'anni.lai@futurewei.com',
    firstName: 'Anni',
    lastName: 'Lai',
    company: demoCompanies.huawei,
  },
  'dan.kohn': {
    ...mockProfile(),
    primaryEmail: 'dan@linuxfoundation.org',
    firstName: 'Dan',
    lastName: 'Kohn',
    company: demoCompanies.linuxfoundation,
  },
  cdibona: {
    ...mockProfile(),
    primaryEmail: 'cdibona@google.com',
    firstName: 'Chris',
    lastName: 'Dibona',
    company: demoCompanies.google,
  },
  'wei.ding': {
    ...mockProfile(),
    primaryEmail: 'wei.ding@jd.com',
    firstName: 'Wei',
    lastName: 'Ding',
    company: demoCompanies.jdcom,
  },
  'arjan.van.de.ven': {
    ...mockProfile(),
    primaryEmail: 'arjan.van.de.ven@intel.com',
    firstName: 'Arjan',
    lastName: 'Van de Ven',
    company: demoCompanies.intel,
  },
  lizrice: {
    ...mockProfile(),
    primaryEmail: 'liz@lizrice.com',
    firstName: 'Liz',
    lastName: 'Rice',
    company: demoCompanies.tvsd,
  },
  mcluckiec: {
    ...mockProfile(),
    primaryEmail: 'mcluckiec@vmware.com',
    firstName: 'Craig',
    lastName: 'McLuckie',
    company: demoCompanies.vmware,
  },
  'bernd.krannich': {
    ...mockProfile(),
    primaryEmail: 'bernd.krannich@sap.com',
    firstName: 'Bernd',
    lastName: 'Krannich',
    company: demoCompanies.sap,
  },
  eaw: {
    ...mockProfile(),
    primaryEmail: 'eaw@cisco.com',
    firstName: 'Ed',
    lastName: 'Warnicke',
    company: demoCompanies.cisco,
  },
  sytse: {
    ...mockProfile(),
    primaryEmail: 'sytse@gitlab.com',
    firstName: 'Sid',
    lastName: 'Sijbrandij',
    company: demoCompanies.gitlab,
  },
  'michael.h.payne': {
    ...mockProfile(),
    primaryEmail: 'michael.h.payne@jpmchase.com',
    firstName: 'Michael',
    lastName: 'Payne',
    company: demoCompanies.jpmorgan,
  },
  psharma: {
    ...mockProfile(),
    primaryEmail: 'psharma@gitlab.com',
    firstName: 'Priyanka',
    lastName: 'Sharma',
    company: demoCompanies.gitlab,
  },
  vijoy: {
    ...mockProfile(),
    primaryEmail: 'vijoy@cisco.com',
    firstName: 'Vijoy',
    lastName: 'Pandey',
    company: demoCompanies.cisco,
  },
  alena: {
    ...mockProfile(),
    primaryEmail: 'alena@rancher.com',
    firstName: 'Alena',
    lastName: 'Prokharchyk',
    company: demoCompanies.rancher,
  },
  rajp: {
    ...mockProfile(),
    primaryEmail: 'rajp@jfrog.com',
    firstName: 'Raj',
    lastName: 'Panchapakesan',
    company: demoCompanies.jfrog,
  },
  'j.cai': {
    ...mockProfile(),
    primaryEmail: 'j.cai@alibaba-inc.com',
    firstName: 'Junjie (Jack)',
    lastName: 'Cai',
    company: demoCompanies.alibaba,
  },
  'etou.yoshiya': {
    ...mockProfile(),
    primaryEmail: 'etou.yoshiya@jp.fujitsu.com',
    firstName: 'Yoshiya',
    lastName: 'Eto',
    company: demoCompanies.fujitsu,
  },
  bjliuhaifeng: {
    ...mockProfile(),
    primaryEmail: 'bjliuhaifeng@jd.com',
    firstName: 'Haifeng',
    lastName: 'Liu',
    company: demoCompanies.jdcom,
  },
  syara: {
    ...mockProfile(),
    primaryEmail: 'syara@pivotal.io',
    firstName: 'Scott',
    lastName: 'Yara',
    company: demoCompanies.pivotal,
  },
  twaggoner: {
    ...mockProfile(),
    primaryEmail: 'twaggoner@linuxfoundation.org',
    firstName: 'Taylor',
    lastName: 'Waggoner',
    company: demoCompanies.linuxfoundation,
  },
  tmmoore: {
    ...mockProfile(),
    primaryEmail: 'tmmoore@us.ibm.com',
    firstName: 'Todd',
    lastName: 'Moore',
    company: demoCompanies.ibm,
  },
  'thomas.di.giacomo': {
    ...mockProfile(),
    primaryEmail: 'thomas.di.giacomo@suse.com',
    firstName: 'Thomas',
    lastName: 'Di Giacomo',
    company: demoCompanies.suse,
  },
  'shannon.williams': {
    ...mockProfile(),
    primaryEmail: 'shannon@rancher.com',
    firstName: 'Shannon',
    lastName: 'Williams',
    company: demoCompanies.rancher,
  },
  'michael.wintergerst': {
    ...mockProfile(),
    primaryEmail: 'michael.wintergerst@sap.com',
    firstName: 'Michael',
    lastName: 'Wintergerst',
    company: demoCompanies.sap,
  },
  mdolan: {
    ...mockProfile(),
    primaryEmail: 'mdolan@linuxfoundation.org',
    firstName: 'Michael',
    lastName: 'Dolan',
    company: demoCompanies.linuxfoundation,
  },
  'jon.mittelhauser': {
    ...mockProfile(),
    primaryEmail: 'jon.mittelhauser@oracle.com',
    firstName: 'Jon',
    lastName: 'Mittelhauser',
    company: demoCompanies.oracle,
  },
  'jim.zemlin': {
    ...mockProfile(),
    primaryEmail: 'jim@linux.com',
    firstName: 'Jim',
    lastName: 'Zemlin',
    company: demoCompanies.linuxfoundation,
  },
  'gabe.monroy': {
    ...mockProfile(),
    primaryEmail: 'gabe.monroy@microsoft.com',
    firstName: 'Gabe',
    lastName: 'Monroy',
    company: demoCompanies.microsoft,
  },
  chrisw: {
    ...mockProfile(),
    primaryEmail: 'chrisw@redhat.com',
    firstName: 'Chris',
    lastName: 'Wright',
    company: demoCompanies.redhat,
  },
  't.tomo': {
    ...mockProfile(),
    primaryEmail: 't.tomo@fujitsu.com',
    firstName: 'Tomohiko',
    lastName: 'Tashiro',
    company: demoCompanies.fujitsu,
  },
  bcooks: {
    ...mockProfile(),
    primaryEmail: 'bcooks@digitalocean.com',
    firstName: 'Barry',
    lastName: 'Cooks',
    company: demoCompanies.digitalocean,
  },
  tomer: {
    ...mockProfile(),
    primaryEmail: 'tomer@apple.com',
    firstName: 'Tom',
    lastName: 'Doron',
    company: demoCompanies.apple,
  },
  wisebob: {
    ...mockProfile(),
    primaryEmail: 'wisebob@amazon.com',
    firstName: 'Bob',
    lastName: 'Wise',
    company: demoCompanies.aws,
  },
  'lachlan.evenson': {
    ...mockProfile(),
    primaryEmail: 'lachlan.evenson@microsoft.com',
    firstName: 'Lachlan',
    lastName: 'Evenson',
    company: demoCompanies.microsoft,
  },
};
