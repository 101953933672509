import gql from 'graphql-tag';

export const resendInviteMutation = gql`
  mutation resendInvite($inviteId: ID!, $inviteEmail: String!) {
    resendInvite(inviteId: $inviteId, inviteEmail: $inviteEmail) {
      code
      message
      success
      payload {
        status
        email
        type
      }
    }
  }
`;
