import * as faker from 'faker';

import { randomEnum } from '../mocks/generator';

export interface FundingGoalSummary {
  type: string;
  donations: number;
  expenses: number;
}

export enum FundingGoalType {
  Marketing = 'Marketing',
  Mentorship = 'Mentorship',
  Travel = 'Travel',
  Meetsup = 'Meetsup',
  Stipend = 'Stipend',
  Other = 'Other',
}

export enum BugsType {
  High = 'High Bug',
  Medium = 'Medium Bug',
  Low = 'Low Bug',
}

export const mockFundingGoalSummary = (
  types?: FundingGoalType[]
): FundingGoalSummary => ({
  type:
    types && types.length > 0
      ? faker.random.arrayElement(types)
      : randomEnum(FundingGoalType),
  donations: faker.random.number({ min: 5000, max: 20000 }),
  expenses: faker.random.number({ min: 1000, max: 5000 }),
});

export const mockBugsSummary = (types?: BugsType[]): FundingGoalSummary => ({
  type:
    types && types.length > 0
      ? faker.random.arrayElement(types)
      : randomEnum(BugsType),
  donations: faker.random.number({ min: 5000, max: 20000 }),
  expenses: faker.random.number({ min: 1000, max: 5000 }),
});
