import { Owner } from './owner';

export class FoundationEssentials {
  id: string;
  name: string;
  description: string;
  status: string;
  slug: string;
  colorBand: string;
  projectSector: string;
  ProjectLogo: string;
  owner: Owner;
  executiveDirector?: Owner;
  programManager?: Owner;
  opportunityOwner?: Owner;
}
