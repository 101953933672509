import gql from 'graphql-tag';

export const companyClaManagersQuery = gql`
  query companyClaManagers(
    $companySFID: String!
    $projectSFID: String!
    $withProjectLogoUrl: Boolean!
  ) {
    companyClaManagers(companySFID: $companySFID, projectSFID: $projectSFID) {
      approvedOn
      email
      lfUsername
      logoUrl
      name
      projectId
      projectName
      projectLogoUrl @include(if: $withProjectLogoUrl)
      projectSfid
      userSfid
    }
  }
`;
