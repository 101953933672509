import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import {
  demoStatisticsDashboardNav,
  demoStatisticsDashboards,
  StatisticsDashboard,
  StatisticsDashboardNav,
} from '../models/statistics';

@Injectable({ providedIn: 'root' })
export class StatisticsService {
  constructor() {}

  public getStatisticsDashboardNav(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    projectId: string
  ): Observable<StatisticsDashboardNav> {
    return of(demoStatisticsDashboardNav);
  }

  public getStatisticsDashboard(
    projectId: string,
    dashboardId: string
  ): Observable<StatisticsDashboard> {
    return of(demoStatisticsDashboards[dashboardId]);
  }
}
