export enum VulnerabilitySortOptions {
  COMMITS = 'commits',
  ISSUES_FOUND = 'vulnerabilities',
  ISSUES_FIXED = 'fixed',
  CONTRIBUTORS = 'contributors',
}

export const vulnerabilitySortItems = [
  {
    label: 'Vulnerabilities found',
    key: VulnerabilitySortOptions.ISSUES_FOUND,
  },
  { label: 'Commits', key: VulnerabilitySortOptions.COMMITS },
  { label: 'Vunerabilities fixed', key: VulnerabilitySortOptions.ISSUES_FIXED },
  { label: 'Contributors', key: VulnerabilitySortOptions.CONTRIBUTORS },
];

export enum projectSeverityLevels {
  HIGH_SEVERITY = 'HIGH SEVERITY',
  MEDIUM_SEVERITY = 'MEDIUM SEVERITY',
  LOW_SEVERITY = 'LOW SEVERITY',
}

export enum vulnerabilitiesLegenLabels {
  VULNERABILITIES_FOUND = 'VULNERABILITIES FOUND',
  RECOMMENDATIONS_ADOPTED = 'RECOMMENDATIONS ADOPTED',
  FIXES_RECOMMENDED = 'FIXES RECOMMENDED',
}

export const severityChartDataOptions = {
  highSeverity: {
    backgroundColor: '#FF595A',
    hoverBackgroundColor: '#FF595A',
    label: projectSeverityLevels.HIGH_SEVERITY,
  },
  mediumSeverity: {
    backgroundColor: '#FFAD5F',
    hoverBackgroundColor: '#FFAD5F',
    label: projectSeverityLevels.MEDIUM_SEVERITY,
  },
  lowSeverity: {
    backgroundColor: '#A1D683',
    hoverBackgroundColor: '#A1D683',
    label: projectSeverityLevels.LOW_SEVERITY,
  },
};

export const vulnerabilitiesChartDataOptions = {
  recommendationsAdopted: {
    label: vulnerabilitiesLegenLabels.RECOMMENDATIONS_ADOPTED,
    backgroundColor: '#0099CC',
    hoverBackgroundColor: '#0099CC',
    order: 0,
  },
  vulnerabilitiesFound: {
    label: vulnerabilitiesLegenLabels.VULNERABILITIES_FOUND,
    backgroundColor: '#8DD7F0',
    hoverBackgroundColor: '#8DD7F0',
    order: 1,
  },
  fixesRecommended: {
    color: '#000000',
  },
};
