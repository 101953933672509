import * as faker from 'faker';

import { convertObjectToArray } from '../helper';
import { imagePlaceholders } from '@config';

export const randomCompany = (): Company =>
  faker.random.arrayElement(convertObjectToArray(demoCompanies));

export const mockCompany = (): Company => {
  const name = faker.company.companyName(0);

  return {
    id: faker.random.uuid(),
    name,
    description: faker.lorem.paragraph(),
    logoUrl: imagePlaceholders.company,
    address: faker.address.streetAddress(),
    billingAddress: {
      billingStreet: faker.address.streetAddress(),
      billingCity: faker.address.city(),
      billingState: faker.address.state(),
      billingPostalCode: faker.address.zipCode(),
      billingCountry: faker.address.country(),
    },
    city: faker.address.city(),
    state: faker.address.state(),
    country: 'United States',
    zip: faker.address.zipCode(),
    phone: faker.phone.phoneNumberFormat(),
    website: faker.internet.url(),
    link: '',
  };
};

import { UserBlock, MemberInvite, Foundation } from '@models';

export interface CompanyAdministrators {
  administrators: UserBlock[];
}

export interface BillingAddress {
  billingStreet: string;
  billingCity: string;
  billingState: string;
  billingPostalCode: string;
  billingCountry: string;
}

export interface Address {
  id: string;
  title: string;
  street: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  billToName: string;
}

export type OrganizationAddress = Pick<
  Address,
  'billToName' | 'city' | 'country' | 'postalCode' | 'state' | 'street'
>;

export interface CreateAddressInput {
  organizationId: string;
  address: OrganizationAddress;
}

export interface UpdateAddressInput {
  organizationId: string;
  addressId: string;
  address: Partial<OrganizationAddress>;
}

export interface Company {
  id: string;
  sfId?: string;
  name: string;
  description?: string;
  logoUrl: string;
  address: string;
  address2?: string;
  billingAddress: BillingAddress;
  city: string;
  country: string;
  state: string;
  zip: string;
  phone: string;
  website: string;
  link?: string;
  lastModifiedAt?: string;
  admins?: UserBlock[];
  serviceUsers?: ServiceUser[];
  committeeMembersConnection?: CommitteeMembersConnection;
  invites?: MemberInvite[];
  foundations?: OrganizationFoundation[];
  committeeFoundations?: OrganizationFoundation[];
}
export interface ServiceUser {
  id: string;
  username: string;
  email: string;
  name: string;
  avatar: string;
  roles: ServiceUserRole[];
}

export interface ServiceUserRole {
  roleId: string;
  name: string;
  projectName: string;
  foundationName: string;
  scopeId: string;
  scopeTypeId: string;
  projectId: string;
}

export interface DeleteUserRoleScopeInput {
  organizationId: string;
  roleId: string;
  scopeId: string;
}

export interface CommitteeMemberContact {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  logoUrl: string;
}

export interface CommitteeMember {
  appointedBy: string;
  category: string;
  committeeID: string;
  committeeMemberID: string;
  name: string;
  role: string;
  roleStartDate: string;
  roleEndDate: string;
  status: string;
  contact: CommitteeMemberContact;
  project: OrganizationFoundation;
}

export interface CommitteeMembersConnection {
  committeeMembers: CommitteeMember[];
  totalCount: number;
  pageInfo: {
    hasNextPage: boolean;
    endCursor: number;
  };
}

export const demoCompanies: Record<string, Company> = {
  acme: {
    ...mockCompany(),
    id: 'acme',
    name: 'Acme Corp',
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/acme.png',
  },
  apple: {
    ...mockCompany(),
    id: 'apple',
    name: 'Apple',
    logoUrl: 'https://logo.clearbit.com/apple.com',
  },
  gitlab: {
    ...mockCompany(),
    id: 'gitlab',
    name: 'GitLab BV',
    logoUrl: 'https://logo.clearbit.com/gitlab.com',
  },
  jpmorgan: {
    ...mockCompany(),
    id: 'jpmorgan',
    name: 'JP Morgan Chase',
    logoUrl: 'https://logo.clearbit.com/jpmorgan.com',
  },
  rancher: {
    ...mockCompany(),
    id: 'rancher',
    name: 'Rancher',
    logoUrl: 'https://logo.clearbit.com/rancher.com',
  },
  digitalocean: {
    ...mockCompany(),
    id: 'digitalocean',
    name: 'DigitalOcean',
    logoUrl: 'https://logo.clearbit.com/digitalocean.com',
  },
  google: {
    ...mockCompany(),
    id: 'google',
    name: 'Google',
    logoUrl: 'https://logo.clearbit.com/google.com',
  },
  microsoft: {
    ...mockCompany(),
    id: 'microsoft',
    name: 'Microsoft',
    logoUrl: 'https://logo.clearbit.com/microsoft.com',
  },
  aws: {
    ...mockCompany(),
    id: 'aws',
    name: 'Amazon Web Services',
    logoUrl: 'https://logo.clearbit.com/aws.amazon.com',
  },
  cisco: {
    ...mockCompany(),
    id: 'cisco',
    name: 'Cisco',
    logoUrl: 'https://logo.clearbit.com/cisco.com',
  },
  dell: {
    ...mockCompany(),
    id: 'dell',
    name: 'Dell',
    logoUrl: 'https://logo.clearbit.com/dell.com',
  },
  jfrog: {
    ...mockCompany(),
    id: 'jfrog',
    name: 'JFrog Ltd',
    logoUrl: 'https://logo.clearbit.com/jfrog.com',
  },
  netapp: {
    ...mockCompany(),
    id: 'netapp',
    name: 'NetApp Inc',
    logoUrl: 'https://logo.clearbit.com/netapp.com',
  },
  suse: {
    ...mockCompany(),
    id: 'suse',
    name: 'SUSE',
    logoUrl: 'https://logo.clearbit.com/suse.com',
  },
  linuxfoundation: {
    ...mockCompany(),
    id: 'linuxfoundation',
    name: 'The Linux Foundation',
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/linuxfoundation.svg',
  },
  fujitsu: {
    ...mockCompany(),
    id: 'fujitsu',
    name: 'Fujitsu',
    logoUrl: 'https://logo.clearbit.com/fujitsu.com',
  },
  huawei: {
    ...mockCompany(),
    id: 'huawei',
    name: 'Huawei Technologies',
    logoUrl: 'https://logo.clearbit.com/huawei.com',
  },
  alibaba: {
    ...mockCompany(),
    id: 'alibaba',
    name: 'Alibaba Cloud',
    logoUrl: 'https://logo.clearbit.com/alibabacloud.com',
  },
  ibm: {
    ...mockCompany(),
    id: 'ibm',
    name: 'IBM',
    logoUrl: 'https://logo.clearbit.com/ibm.com',
  },
  intel: {
    ...mockCompany(),
    id: 'intel',
    name: 'Intel',
    logoUrl: 'https://logo.clearbit.com/intel.com',
  },
  jdcom: {
    ...mockCompany(),
    id: 'jdcom',
    name: 'JD.com',
    logoUrl: 'https://logo.clearbit.com/jd.com',
  },
  oracle: {
    ...mockCompany(),
    id: 'oracle',
    name: 'Oracle',
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/oracle.png',
  },
  pivotal: {
    ...mockCompany(),
    id: 'pivotal',
    name: 'Pivotal',
    logoUrl: 'https://logo.clearbit.com/pivotal.com',
  },
  redhat: {
    ...mockCompany(),
    id: 'redhat',
    name: 'Red Hat',
    logoUrl: 'https://logo.clearbit.com/redhat.com',
  },
  samsung: {
    ...mockCompany(),
    id: 'samsung',
    name: 'Samsung SDS',
    logoUrl: 'https://logo.clearbit.com/samsung.com',
  },
  sap: {
    ...mockCompany(),
    id: 'sap',
    name: 'SAP',
    logoUrl: 'https://logo.clearbit.com/sap.com',
  },
  vmware: {
    ...mockCompany(),
    id: 'vmware',
    name: 'VMware',
    logoUrl: 'https://logo.clearbit.com/vmware.com',
  },
  dreamworks: {
    ...mockCompany(),
    id: 'dreamworks',
    name: 'Dreamworks',
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/dreamworks.png',
  },
  netflix: {
    ...mockCompany(),
    id: 'netflix',
    name: 'Netflix',
    logoUrl: 'https://logo.clearbit.com/netflix.com',
  },
  blueskystudios: {
    ...mockCompany(),
    id: 'blueskystudios',
    name: 'Blue Sky Studios',
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/blueskystudios.jpg',
  },
  autodesk: {
    ...mockCompany(),
    id: 'autodesk',
    name: 'Autodesk',
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/autodesk.png',
  },
  animalogic: {
    ...mockCompany(),
    id: 'animallogic',
    name: 'Animal Logic',
    logoUrl: 'https://logo.clearbit.com/animallogic.com',
  },
  dneg: {
    ...mockCompany(),
    id: 'dneg',
    name: 'DNEG',
    logoUrl: 'https://lfx-dev-mocks.s3.amazonaws.com/dneg.jpg',
  },
  unreal: {
    ...mockCompany(),
    id: 'unreal',
    name: 'Unreal Engine',
    logoUrl: 'https://logo.clearbit.com/unrealengine.com',
  },
};

export type OrganizationFoundation = Pick<
  Foundation,
  'id' | 'name' | 'logoUrl'
>;

export interface Committee {
  Category: string;
  CollaborationName: string;
  ID: string;
  Project: OrganizationFoundation;
}
