import gql from 'graphql-tag';

export const changeUserPasswordMutation = gql`
  mutation changeUserPassword($userInfo: UserPasswordInput!) {
    changeUserPassword(input: $userInfo) {
      code
      message
      currentUser {
        id
        auth0Id
        username
        firstName
        lastName
        fullName
      }
    }
  }
`;
