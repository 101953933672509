import Chart from 'chart.js';

export const VisualizationOption = {
  animation: {
    duration: 1,
    onComplete() {
      const chartInstance = this.chart;
      const ctx = chartInstance.ctx;

      ctx.font = Chart.helpers.fontString(
        Chart.defaults.global.defaultFontSize,
        Chart.defaults.global.defaultFontStyle,
        Chart.defaults.global.defaultFontFamily
      );
      ctx.textAlign = 'center';
      ctx.textBaseline = 'bottom';
      this.data.datasets.forEach((dataset, i) => {
        const meta = chartInstance.controller.getDatasetMeta(i);

        meta.data.forEach((bar, index) => {
          const data = dataset.data[index];

          ctx.fillText(data, bar._model.x, bar._model.y - 5);
        });
      });
    },
  },
  legend: {
    fullWidth: true,
    position: 'bottom',
    labels: {
      fillStyle: 'rgb(255, 99, 132)',
      fontStyle: 'bold',
      boxWidth: 25,
      fontSize: 12,
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          offsetGridLines: true,
          color: 'rgba(0, 0, 0, 0)',
        },
        barThickness: 50,
      },
    ],
    yAxes: [{ ticks: { min: 1, max: 1000, maxTicksLimit: 7 } }],
  },
  plugins: {
    datalabels: {
      display: true,
      anchor: 'end',
      align: 'end',
      font: {
        size: 20,
        weight: 'bold',
      },
    },
  },
};

export const barChartOptions = (xLabel = '', yLabel = '') => ({
  animation: {
    duration: 1,
    onComplete() {
      const chartInstance = this.chart;
      const ctx = chartInstance.ctx;

      ctx.font = Chart.helpers.fontString(
        Chart.defaults.global.defaultFontSize,
        Chart.defaults.global.defaultFontStyle,
        Chart.defaults.global.defaultFontFamily
      );
      ctx.textAlign = 'center';
      ctx.textBaseline = 'bottom';
      this.data.datasets.forEach((dataset, i) => {
        const meta = chartInstance.controller.getDatasetMeta(i);

        meta.data.forEach((bar, index) => {
          const data = dataset.data[index];

          ctx.fillText(data, bar._model.x, bar._model.y - 5);
        });
      });
    },
  },
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          offsetGridLines: true,
          color: 'rgba(0, 0, 0, 0)',
        },
        scaleLabel: {
          display: true,
          labelString: xLabel,
          fontStyle: 'bold',
        },
        barThickness: 30,
      },
    ],
    yAxes: [
      {
        ticks: {
          min: 0,
          max: 100,
          maxTicksLimit: 7,
        },
        gridLines: {
          offsetGridLines: false,
        },
        scaleLabel: {
          display: true,
          labelString: yLabel,
          fontStyle: 'bold',
        },
      },
    ],
  },
  plugins: {
    datalabels: {
      display: true,
      anchor: 'end',
      align: 'end',
      font: {
        size: 20,
        weight: 'bold',
      },
    },
  },
});

export const horizontalBarChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    duration: 1,
    onComplete() {
      const chartInstance = this.chart;
      const ctx = chartInstance.ctx;

      ctx.textAlign = 'left';
      ctx.font = '40px "Source Sans Pro, sans-serif" bold';
      ctx.fillStyle = '#252525';

      let count = 0;
      const len = this.data.datasets[0].data.length;
      const sumMap = new Map();

      while (len > count) {
        for (const c in this.data.datasets) {
          if (sumMap.get(count)) {
            sumMap.get(count).value += this.data.datasets[c].data[count];
          } else {
            sumMap.set(count, { value: this.data.datasets[c].data[count] });
          }
        }
        count++;
      }

      [...sumMap.values()].forEach((data, index) => {
        const dataMeta = chartInstance.controller.getDatasetMeta(2).data[index];

        ctx.fillText(data.value, dataMeta._model.x + 5, dataMeta._model.y + 2);
      });

      Chart.helpers.each(
        this.data.datasets.forEach((dataset, i) => {
          const meta = chartInstance.controller.getDatasetMeta(i);

          Chart.helpers.each(
            meta.data.forEach((bar, index) => {
              const data = dataset.data[index];
              const dataPercentage = Number(
                ((data / sumMap.get(index).value) * 100).toFixed()
              );

              if (dataset.data.length === 5) {
                if (dataPercentage > 15) {
                  ctx.fillText(
                    `${dataPercentage}%`,
                    bar._model.x - 40,
                    bar._model.y + 2
                  );
                } else {
                  ctx.fillText(
                    `${dataPercentage}%`,
                    bar._model.x - 20,
                    bar._model.y + 2
                  );
                }
              }
            }),
            this
          );
        }),
        this
      );
    },
  },
  legend: {
    usePointStyle: true,
    display: true,
    position: 'bottom',
    labels: {
      fontColor: '#8492A6',
      boxWidth: 14,
      fontFamily: 'proximanova',
    },
  },
  scales: {
    yAxes: [
      {
        ticks: { ticks: { min: 0, max: 1000, maxTicksLimit: 7 } },
        stacked: true,
        scaleLabel: {
          display: false,
        },
        barPercentage: 0.3,
      },
    ],
    xAxes: [
      {
        stacked: true,
        display: false,
      },
    ],
  },
};

export const mixedHorizontalBarChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    duration: 1,
    onComplete() {
      const chartInstance = this.chart;
      const ctx = chartInstance.ctx;

      ctx.textAlign = 'left';
      ctx.font = '40px "Source Sans Pro, sans-serif" bold';
      ctx.fillStyle = '#252525';

      let count = 0;
      const len = this.data.datasets[0].data.length;
      const sumMap = new Map();

      while (len > count) {
        for (const c in this.data.datasets) {
          if (sumMap.get(count)) {
            sumMap.get(count).value += this.data.datasets[c].data[count];
          } else {
            sumMap.set(count, { value: this.data.datasets[c].data[count] });
          }
        }
        count++;
      }

      [...sumMap.values()].forEach((data, index) => {
        const dataMeta = chartInstance.controller.getDatasetMeta(1).data[index];

        ctx.fillText(data.value, dataMeta._model.x + 5, dataMeta._model.y + 2);
      });

      Chart.helpers.each(
        this.data.datasets.forEach((dataset, i) => {
          const meta = chartInstance.controller.getDatasetMeta(i);

          Chart.helpers.each(
            meta.data.forEach((bar, index) => {
              const data = dataset.data[index];

              ctx.fillText(data, bar._model.x - 40, bar._model.y + 2);
            }),
            this
          );
        }),
        this
      );
    },
  },
  legend: {
    usePointStyle: true,
    display: true,
    position: 'bottom',
    labels: {
      fontColor: '#8492A6',
      boxWidth: 14,
      fontFamily: 'proximanova',
    },
  },
  scales: {
    yAxes: [
      {
        ticks: { ticks: { min: 0, max: 1000, maxTicksLimit: 7 } },
        stacked: true,
        scaleLabel: {
          display: false,
        },
        barPercentage: 0.3,
      },
    ],
    xAxes: [
      {
        stacked: true,
        display: false,
      },
    ],
  },
};
