import gql from 'graphql-tag';

export const foundationCommitteesQuery = gql`
  query foundation($id: ID!) {
    foundation(id: $id) {
      committees {
        id
        name
        collaborationName
        category
        description
        url
      }
    }
  }
`;
