import gql from 'graphql-tag';

export const membershipHistoryQuery = gql`
  query membershipHistory($accountId: ID!) {
    membershipHistory(accountId: $accountId) {
      projectId
      projectName
      id
    }
  }
`;
