import gql from 'graphql-tag';

export const currentUserQuery = gql`
  query currentUser($withPermissions: Boolean = false, $basic: Boolean = true) {
    currentUser(basic: $basic) {
      id
      username
      firstName
      lastName
      avatarUrl
      fullName
      emails {
        emailAddress
        emailType
        active
        isDeleted
        isPrimary
        isVerified
      }
      permissions @include(if: $withPermissions) {
        context
        actions
        resource
        scopes {
          id
        }
      }
      roles
      auth0Id
      timezone
      socialAccounts {
        userId
        provider
        connection
        isSocial
      }
    }
  }
`;
