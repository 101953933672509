import gql from 'graphql-tag';

export const projectsDependencyStackDepthQuery = gql`
  query projectsDependencyStackDepth($input: ProjectsDependencyInput) {
    projectsDependencyStackDepth(input: $input) {
      maxDepth
      sfdcProjectId
    }
  }
`;
