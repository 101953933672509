/* eslint-disable @typescript-eslint/camelcase */
import { environment as defaultEnvironment } from './environment.defaults';

const pageQueryParams = window.location.search || '';

export const environment = {
  ...defaultEnvironment,
  auth0: {
    client_id: 'qIE4NMNqOiv6706tG9zygsgpw4S7dRl3',
    domain: 'linuxfoundation-dev.auth0.com',
    audience: 'https://linuxfoundation-dev.auth0.com/api/v2/',
    redirect_uri: `${window.location.origin}/auth${pageQueryParams}`,
    responseType: 'token id_token',
  },
  auth0Social: {
    domain: 'linuxfoundation-dev.auth0.com',
    audience: 'https://linuxfoundation-dev.auth0.com/api/v2/',
    client_id: 'QQrYjDj2JK6sGW2Tw59rQpjYtSQaBgCz',
    redirect_uri: `${window.location.origin}/user/account-settings`,
    responseType: 'token id_token',
    scope: 'openid profile email',
  },
  lfxHeaderUrl: 'https://cdn.dev.platform.linuxfoundation.org/lfx-header-v2.js',
  apiUrl: 'https://lfx-bff.dev.platform.linuxfoundation.org/api',
  graphqlUrl: 'https://lf-backend-cla.dev.platform.linuxfoundation.org/graphql',
  ldClientKey: '5e0a9aebc10956087c7d009c',
  joinFoundationUrl:
    'https://joinnow.dev.platform.linuxfoundation.org/?app=lfx&project=',
  cclaUrl: 'https://lfx-cla.s3.amazonaws.com/ccla.pdf',
  profileManagementUrl: 'https://myprofile.dev.platform.linuxfoundation.org/',
};
