import gql from 'graphql-tag';

export const foundationResourcesQuery = gql`
  query foundationResource($id: ID!) {
    foundationResource(id: $id) {
      id
      name
      description
      resourceUrl
      resourceType
    }
  }
`;
