import { Foundation, FoundationProject } from './models';

export const convertObjectToArray = <T>(obj: object): T[] =>
  Object.keys(obj).map(idx => obj[idx]);

export const uniqArray = <T>(arr: T[]): T[] => Array.from(new Set(arr));

export const subsetRecord = <T>(
  set: Record<string, T>,
  keys: string[]
): Record<string, T> => {
  const newSet = {};

  keys.filter(k => {
    if (set[k]) {
      newSet[k] = set[k];
    }
  });

  return newSet;
};

export const sortFoundationsOrProjectsByCommits = (
  first: Foundation | FoundationProject,
  second: Foundation | FoundationProject
) => {
  if (!first.organization && !second.organization) {
    return 0;
  }

  if (first.organization && second.organization) {
    return first.organization.totalCodeContribution <
      second.organization.totalCodeContribution
      ? 1
      : -1;
  }

  if (!second.organization) {
    return -1;
  }

  if (!first.organization) {
    return 1;
  }
};

export const sortFoundationsOrProjectsByName = (
  first: Foundation | FoundationProject,
  second: Foundation | FoundationProject
) => {
  if (!first.name && !second.name) {
    return 0;
  }

  if (first.name && second.name) {
    return first.name < second.name ? -1 : 1;
  }

  if (!second.name) {
    return 1;
  }

  if (!first.name) {
    return -1;
  }
};

export const sortProjectByTitle = (a, b) => (a.title < b.title ? -1 : 1);

export const replaceMultipleStrings = (str, regExp, matchingObject) =>
  str.replace(regExp, matched => `<strong>${matchingObject[matched]}</strong>`);
