import gql from 'graphql-tag';

export const organizationAdminsQuery = gql`
  query organizationAdmins($salesforceId: ID!) {
    organizationAdmins(salesforceId: $salesforceId) {
      id
      name
      logoUrl
      email
      roleId
      scopeId
    }
  }
`;
