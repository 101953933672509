import gql from 'graphql-tag';

export const projectActivityLogsQuery = gql`
  query projectActivityLogs($input: ProjectActivityLogsInput) {
    projectActivityLogs(input: $input) {
      events {
        message
        tip
        name
        logoUrl
        createdAt
      }
      nextKey
    }
  }
`;
