import Chart from 'chart.js';

export const vulnerabilitiesRemediationChartOptions = {
  animation: {
    duration: 1,
    onComplete() {
      const chartInstance = this.chart;
      const ctx = chartInstance.ctx;

      ctx.font = Chart.helpers.fontString(
        Chart.defaults.global.defaultFontSize,
        Chart.defaults.global.defaultFontStyle,
        Chart.defaults.global.defaultFontFamily
      );
      ctx.textAlign = 'center';
      ctx.textBaseline = 'bottom';
      const stack0Sum =
        this.data.datasets[0].data[0] + this.data.datasets[1].data[0];
      const stack0Meta = chartInstance.controller.getDatasetMeta(1);

      const stack0 = stack0Meta.data[0];

      const stack1Sum =
        this.data.datasets[0].data[1] + this.data.datasets[1].data[1];
      const stack1Meta = chartInstance.controller.getDatasetMeta(1);
      const stack1 = stack1Meta.data[1];

      ctx.font = '50px "Source Sans Pro, sans-serif" bold';
      ctx.fillStyle = '#252525';

      ctx.fillText(stack0Sum, stack0._model.x, stack0._model.y - 5);
      ctx.fillText(stack1Sum, stack1._model.x, stack1._model.y - 5);
    },
  },
  legend: {
    usePointStyle: true,
    display: true,
    position: 'bottom',
    labels: {
      fontColor: '#8492A6',
      boxWidth: 14,
      fontFamily: 'proximanova',
    },
  },
  scales: {
    yAxes: [
      {
        ticks: { ticks: { min: 1, max: 2000, maxTicksLimit: 7 } },
        stacked: true,
        scaleLabel: {
          display: true,
          labelString: 'Vulnerabilities Found Till Date',
        },
      },
    ],
    xAxes: [
      {
        stacked: true,
        display: false,
      },
    ],
  },
};
