import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { checkDomainAvailabiltyQuery } from '../queries';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { registerNewDomainMutation } from '../mutations';
import { DomainInfo } from '@lfx/core/models';

@Injectable({
  providedIn: 'root',
})
export class AdminServiceGql {
  constructor(private apollo: Apollo) {}

  checkDomainAvailability(domainName: string): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: checkDomainAvailabiltyQuery,
        variables: {
          domainName,
        },
      })
      .valueChanges.pipe(map(res => res.data.domainAvailability));
  }

  addNewDomain(domainInfo: DomainInfo): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: registerNewDomainMutation,
        variables: {
          domainInfo,
        },
      })
      .pipe(map(res => res.data.registerDomain));
  }
}
