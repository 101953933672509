import gql from 'graphql-tag';

export const updateAutoCreateEclaFlagMutation = gql`
  mutation updateAutoCreateEclaFlag($input: UpdateAutoCreateEclaFlagInput!) {
    updateAutoCreateEclaFlag(input: $input) {
      code
      message
      success
      data {
        autoCreateECLA
      }
    }
  }
`;
