import gql from 'graphql-tag';

export const foundationMembershipsQuery = gql`
  query foundation($id: ID!) {
    foundation(id: $id) {
      id
      name
      creationDate
      description
      logoUrl
      colorBand
      tag
      prorated
      autoRenew
      membershipTerm
      paymentFrequency
      tiers {
        sizeMax
        sizeMin
        name
        description
        id
        pricing
        isProrated
      }
    }
  }
`;
