import gql from 'graphql-tag';

export const securityLanguagesDistribution = gql`
  query securityLanguagesDistribution(
    $input: SecurityLanguagesDistributionInput
  ) {
    securityLanguagesDistribution(input: $input) {
      languages {
        language
        numberOfBytes
        sfdcProjectId
        externalId
      }
      summary {
        totalNumberOfBytes
        totalProjects
      }
    }
  }
`;
