export enum CoverageTypeEnum {
  EmailAddress = 'Email Address',
  Domain = 'Email Address Domain',
  GithubId = 'GitHub Username',
  GithubOrganization = 'GitHub Organization',
  GitlabId = 'GitLab Username',
  GitlabProject = 'GitLab Group URL',
}

export interface ApprovedContributor {
  username?: string;
  email?: string;
  image?: string;
  githubUsername?: string;
  githubOrgUrl?: string;
  gitlabUsername?: string;
  gitlabOrgUrl?: string;
  domainUrl?: string;
  coverageType: CoverageTypeEnum;
  dateCreated: string;
  autoCreateECLA: boolean;
}

export interface FormatedApprovedContributor {
  id: number;
  addedOn: string;
  coverageType: CoverageTypeEnum;
  approvedList: MappedApprovedList;
}

export interface MappedApprovedList {
  text: string;
  image: string;
  subtext: string;
}
