import gql from 'graphql-tag';

export const removeFileMutation = gql`
  mutation removeContractDoc($key: String) {
    removeContractDoc(key: $key) {
      code
      message
      success
    }
  }
`;
