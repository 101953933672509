import gql from 'graphql-tag';

export const updateProjectEssentialsMutation = gql`
  mutation updateProjectEssentials(
    $projectEssential: UpdateProjectEssentialsInput!
  ) {
    updateProjectEssentials(input: $projectEssential) {
      project {
        id
        name
        status
        description
        logoUrl
        colorBand
        slug
        projectSector
      }
    }
  }
`;
