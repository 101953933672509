export enum RoleTypes {
  BillingContact = 'Billing Contact',
  MarketingContact = 'Marketing Contact',
  TechnicalContact = 'Technical Contact',
  RepresentativeOrVotingContact = 'Representative/Voting Contact',
  AuthorizedSignatory = 'Authorized Signatory',
  EventSponsorship = 'Event Sponsorship',
  LegalContact = 'Legal Contact',
  PressContact = 'Press Contact',
  POContact = 'PO Contact',
}

export const enum MembershipStatuses {
  Active = 'Active',
  Expired = 'Expired',
}

export const enum MembershipTypes {
  Corporate = 'Corporate',
  Individual = 'Individual',
  EdxTraining = 'EdxTraining',
}

export interface MembershipFilters {
  status: MembershipStatuses;
  type: MembershipTypes;
}

export const allRoles = Object.values(RoleTypes);

export const repeatableMemberships: string[] = [
  RoleTypes.BillingContact,
  RoleTypes.MarketingContact,
  RoleTypes.TechnicalContact,
];

export const rolesRules = new Map([
  [RoleTypes.RepresentativeOrVotingContact, { min: 1, max: 1 }],
  [RoleTypes.AuthorizedSignatory, { min: 1, max: 1 }],
  [RoleTypes.BillingContact, { min: 1, max: Infinity }],
  [RoleTypes.EventSponsorship, { min: 0, max: 1 }],
  [RoleTypes.MarketingContact, { min: 1, max: Infinity }],
  [RoleTypes.LegalContact, { min: 0, max: 1 }],
  [RoleTypes.TechnicalContact, { min: 1, max: Infinity }],
  [RoleTypes.PressContact, { min: 0, max: 1 }],
  [RoleTypes.POContact, { min: 0, max: 1 }],
]);
