import gql from 'graphql-tag';

export const foundationQuery = gql`
  query foundation(
    $id: ID!
    $withAdminData: Boolean = false
    $withProjectsData: Boolean = false
    $withMembersData: Boolean = false
    $withContributionsData: Boolean = false
    $communityYearEvent: String = "all"
    $organizationYearEvent: String = "all"
  ) {
    foundation(id: $id) {
      id
      name
      creationDate
      description
      logoUrl
      colorBand
      tag
      status
      slug
      projectSector
      prorated
      autoRenew
      membershipTerm
      paymentFrequency
      autoJoinEnabled
      projectType
      members @include(if: $withMembersData) {
        id
        name
        logoUrl
        website
        tier {
          id
          name
        }
      }
      owner {
        id
        firstName
        lastName
        email
      }
      opportunityOwner {
        id
        firstName
        lastName
        email
      }
      programManager {
        id
        firstName
        lastName
        email
      }
      executiveDirector {
        id
        firstName
        lastName
        email
      }
      tiers @include(if: $withAdminData) {
        sizeMin
        sizeMax
        name
        description
        id
        pricing
        isProrated
      }
      community(filter: $communityYearEvent) {
        contributors
        commits
        members {
          id
          name
          description
          logoUrl
          membershipTier
        }
      }
      legal {
        legalEntity
        formationDate
        dissolutionDate
        entityType
        msaUrl
        charterUrl
        cocUrl
      }
      organization(filter: $organizationYearEvent) {
        contributors
        commits
        totalCodeContribution
      }
      projects @include(if: $withProjectsData) {
        badgeUrl
        autoJoinEnabled
        enabledServices
        membershipAutorenew
        name
        parent
        status
        category
        createdDate
        endDate
        id
        modifiedDate
        projectType
        systemModStamp
        type
        description
        logoUrl
        colorBand
        tag
        community {
          contributors
          commits
        }
        organization {
          contributors
          commits
          totalCodeContribution
        }
        groupName
      }
      committees @include(if: $withAdminData) {
        id
        name
        collaborationName
        description
        category
        url
      }
      finance @include(if: $withAdminData) {
        name
        internationalAddress
        phone
        emailFrom
        emailAddress
        website
        city
        country
        state
        postalCode
        netSuiteClass
        department
        incomeGlAccount
        deferredRevenueAccount
        jobCode
        projectCode
        subsidiaryId
      }
      contributionsData @include(if: $withContributionsData) {
        developers {
          name
          commits
        }
        organizations {
          name
          commits
        }
        organizationsOtherCommits
      }
      website
      foundation {
        id
        name
      }
      parent
    }
  }
`;
