import { UpdateOrganizationPayload } from '../gql/services/results/update-organization-result';
import { UpdateOrganizationInput } from '../gql/services/inputs/update-organization-input';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  Company,
  CompanyAdministrators,
  User,
  DeleteUserRoleScopeInput,
  MemberInvite,
  Address,
  CommitteeMembersConnection,
  CreateAddressInput,
  UpdateAddressInput,
  CompanySigningEntity,
} from '@models';

import {
  CreateAddressPayload,
  GetCommitteeMembersInput,
  OrganizationServiceGql,
  UpdateAddressPayload,
} from '../gql';

import {
  GetMyOrganizationInput,
  CreateUserRoleScopeInput,
} from '../gql/services/inputs';
import { MutationResponse } from '@lfx/core/models/mutation-response';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  signingEntityId: string;

  constructor(private organizationServiceGql: OrganizationServiceGql) {}

  getMyCompany(
    options?: GetMyOrganizationInput,
    noCache?: boolean
  ): Observable<Company> {
    return this.organizationServiceGql.getMyOrganization(options, noCache);
  }

  getCompanyById(salesforceId: string): Observable<Company> {
    return this.organizationServiceGql.getOrganizationById(salesforceId);
  }

  getCompanySigningEntity(
    signingEntityId: string
  ): Observable<CompanySigningEntity> {
    return this.organizationServiceGql
      .getOrganizationSigningEntity(signingEntityId)
      .pipe(tap(res => (this.signingEntityId = res && res.companyId)));
  }

  getAllCompanies(): Observable<Company[]> {
    return this.organizationServiceGql.getAllOrganizations();
  }

  public uploadLogo(file) {
    return this.organizationServiceGql.uploadLogo(file);
  }

  updateOrganizationProfile(
    organizationInfo: UpdateOrganizationInput
  ): Observable<UpdateOrganizationPayload> {
    return this.organizationServiceGql.updateOrganizationProfile(
      organizationInfo
    );
  }

  getCompanyAdmins(salesforceId: string): Observable<CompanyAdministrators> {
    return this.organizationServiceGql.getOrganizationAdmins(salesforceId);
  }

  getCompanyCommitteeMembers(
    options: GetCommitteeMembersInput
  ): Observable<CommitteeMembersConnection> {
    return this.organizationServiceGql.getOrganizationCommitteeMembers(options);
  }

  deleteUserRoleScope(input: DeleteUserRoleScopeInput) {
    return this.organizationServiceGql.deleteUserRoleScope(input);
  }

  createUserRoleScope(input: CreateUserRoleScopeInput) {
    return this.organizationServiceGql.createUserRoleScope(input);
  }

  createUserRoleScopes(input: CreateUserRoleScopeInput[]) {
    return this.organizationServiceGql.createUserRoleScopes(input);
  }

  searchUsers(input): Observable<User[]> {
    return this.organizationServiceGql.searchUsers(input);
  }

  sendNewMemberInvite(input: MemberInvite): Observable<any> {
    return this.organizationServiceGql.sendNewMemberEmailInvitation(input);
  }

  resendInvite(
    inviteId: string,
    inviteEmail: string
  ): Observable<MutationResponse<MemberInvite>> {
    return this.organizationServiceGql.resendEmailInvitation(
      inviteId,
      inviteEmail
    );
  }

  getAddresses(organizationId: string): Observable<Address[]> {
    return this.organizationServiceGql.getAddresses(organizationId);
  }

  createAddress(input: CreateAddressInput): Observable<CreateAddressPayload> {
    return this.organizationServiceGql.createAddress(input);
  }

  updateAddress(input: UpdateAddressInput): Observable<UpdateAddressPayload> {
    return this.organizationServiceGql.updateAddress(input);
  }
}
