import gql from 'graphql-tag';

export const suggestedFoundationsQuery = gql`
  query suggestedFoundations {
    suggestedFoundations {
      id
      name
      creationDate
      description
      logoUrl
      colorBand
      tag
      autoJoinEnabled
      projectType
      community {
        contributors
        commits
        members {
          id
          name
          description
          logoUrl
          membershipTier
        }
      }
      organization {
        contributors
        commits
        totalCodeContribution
      }
    }
  }
`;
