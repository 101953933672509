import * as faker from 'faker';
import { generalConstants } from '@config';

export interface FoundationFinance {
  id: string;
  name: string;
  internationalAddress: string;
  phone: string;
  emailFrom: string;
  website: string;
  emailAddress: string;
  city: string;
  country: string;
  state: string;
  w9Included: boolean;
  postalCode: string;
  netSuiteClass: string;
  department: string;
  incomeGlAccount: string;
  deferredRevenueAccount: string;
  jobCode: string;
  projectCode: string;
  subsidiaryId: string;
}

export const mockFoundationFinance = (): FoundationFinance => {
  const foundationFinance: FoundationFinance = {
    id: faker.random.uuid(),
    name: `${faker.name.firstName()} ${faker.name.lastName()}`,
    internationalAddress: faker.address.streetAddress(),
    phone: faker.phone.phoneNumber(),
    emailFrom: faker.internet.email(),
    website: faker.internet.url(),
    emailAddress: faker.internet.email(),
    city: faker.address.city(),
    country: faker.address.country(),
    state: faker.address.state(),
    postalCode: faker.address.countryCode(),
    netSuiteClass: 'NSClass',
    w9Included: false,
    department: faker.commerce.department(),
    incomeGlAccount: faker.random.arrayElement(
      generalConstants.incomeGLAccount
    ),
    deferredRevenueAccount: faker.random.arrayElement(
      generalConstants.deferredRevenueAccount
    ),
    jobCode: faker.random.number({ min: 1000, max: 3000 }),
    projectCode: faker.random.number({ min: 1000, max: 3000 }),
    subsidiaryId: faker.random.number({ min: 1000, max: 3000 }),
  };

  return foundationFinance;
};
