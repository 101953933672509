import gql from 'graphql-tag';

export const updateAddressMutation = gql`
  mutation updateAddress($input: UpdateAddressInput!) {
    updateAddress(input: $input) {
      code
      message
      success
      address {
        city
        country
        postalCode
        state
        street
      }
    }
  }
`;
