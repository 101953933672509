import gql from 'graphql-tag';

export const foundationCLAGroupsQuery = gql`
  query foundationCLAGroups($input: FoundationCLAGroupsInput!) {
    foundationCLAGroups(input: $input) {
      code
      message
      success
      foundationCLAGroups {
        claGroups {
          claGroupId
          projectSfidList
        }
        projectSignedAtFoundationLevel
      }
    }
  }
`;
