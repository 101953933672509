import { Component, OnInit } from '@angular/core';
import { ToastService } from '@services';
import { Router } from '@angular/router';

@Component({
  selector: 'lfx-gritter-notification',
  templateUrl: './gritter-notification.component.html',
  styleUrls: ['./gritter-notification.component.scss'],
})
export class GritterNotificationComponent implements OnInit {
  constructor(private router: Router, public toastService: ToastService) {}

  ngOnInit(): void {}

  gotoNotification(toast) {
    this.router.navigate(
      [
        '',
        {
          outlets: {
            notifications: ['notifications', toast.id],
          },
        },
      ],
      { skipLocationChange: true }
    );

    this.toastService.remove(toast);
  }
}
