import gql from 'graphql-tag';

export const searchFoundationsQuery = gql`
  query searchProjects($searchParams: SearchProjectsQueryParams) {
    searchProjects(searchParams: $searchParams) {
      id
      name
      description
      logoUrl
      colorBand
      tag
      autoJoinEnabled
      projectType
      community {
        contributors
        commits
      }
      organization {
        contributors
        commits
        totalCodeContribution
      }
      website
    }
  }
`;
