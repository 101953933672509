import gql from 'graphql-tag';

export const addNewClaManagerMutation = gql`
  mutation addNewClaManager($input: AddClaManagerInput!) {
    addNewClaManager(input: $input) {
      code
      message
      success
      newManager {
        existingUser
        approvedOn
        lfUsername
        name
        email
        claGroupName
        organizationName
        organizationSfid
        projectId
        projectName
        projectSfid
        userSfid
      }
    }
  }
`;
