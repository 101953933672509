import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {
  NgxPermissionsConfigurationService,
  NgxPermissionsPredefinedStrategies,
  NgxPermissionsService,
  NgxRolesService,
  StrategyFunction,
} from 'ngx-permissions';
import {
  from,
  merge,
  Observable,
  ObservableInput,
  of,
  Subscription,
} from 'rxjs';
import {
  catchError,
  first,
  map,
  mergeAll,
  skip,
  switchMap,
  take,
} from 'rxjs/operators';

@Directive({
  selector: '[lfxPermissionsAll]',
})
export class LfxPermissionsAllDirective
  implements OnInit, OnDestroy, OnChanges {
  @Input() lfxPermissionsAll: string | string[];
  @Input() ngxPermissionsExcept: string | string[];

  @Input() ngxPermissionsThen: TemplateRef<any>;
  @Input() ngxPermissionsElse: TemplateRef<any>;

  @Input() ngxPermissionsUnauthorisedStrategy: string | StrategyFunction;
  @Input() ngxPermissionsAuthorisedStrategy: string | StrategyFunction;

  @Output() permissionsAuthorized = new EventEmitter();
  @Output() permissionsUnauthorized = new EventEmitter();

  private initPermissionSubscription: Subscription;
  // skip first run cause merge will fire twice
  private firstMergeUnusedRun = 1;
  private currentAuthorizedState: boolean;
  private permissions;
  private permissionsSubscription: Subscription;
  private mergeSubscription: Subscription;

  constructor(
    private permissionsService: NgxPermissionsService,
    private configurationService: NgxPermissionsConfigurationService,
    private rolesService: NgxRolesService,
    private viewContainer: ViewContainerRef,
    private changeDetector: ChangeDetectorRef,
    private templateRef: TemplateRef<any>
  ) {}

  ngOnInit(): void {
    this.permissionsSubscription = this.permissionsService.permissions$.subscribe(
      permissions => {
        this.permissions = permissions;
      }
    );
    this.viewContainer.clear();
    this.initPermissionSubscription = this.validatePermissions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const allChanges = changes.lfxPermissionsAll;

    if (allChanges) {
      // Due to bug when you pass empty array
      if (allChanges && allChanges.firstChange) {
        return;
      }

      this.mergeSubscription = merge(
        this.permissionsService.permissions$,
        this.rolesService.roles$
      )
        .pipe(skip(this.firstMergeUnusedRun), take(1))
        .subscribe(() => {
          if (this.notEmptyValue(this.lfxPermissionsAll)) {
            this.validateAllPermissions();

            return;
          }

          this.handleAuthorisedPermission(this.getAuthorisedTemplates());
        });
    }
  }

  ngOnDestroy(): void {
    if (this.initPermissionSubscription) {
      this.initPermissionSubscription.unsubscribe();
    }

    if (this.permissionsSubscription) {
      this.permissionsSubscription.unsubscribe();
    }

    if (this.mergeSubscription) {
      this.mergeSubscription.unsubscribe();
    }
  }

  public hasPermission(permission: string | string[]): Promise<boolean> {
    if (!permission || (Array.isArray(permission) && permission.length === 0)) {
      return Promise.resolve(true);
    }

    permission = this.transformStringToArray(permission);

    return this.hasArrayPermission(permission);
  }

  private validatePermissions(): Subscription {
    return merge(this.permissionsService.permissions$, this.rolesService.roles$)
      .pipe(skip(this.firstMergeUnusedRun))
      .subscribe(() => {
        if (this.notEmptyValue(this.lfxPermissionsAll)) {
          this.validateAllPermissions();

          return;
        }

        this.handleAuthorisedPermission(this.getAuthorisedTemplates());
      });
  }

  private validateAllPermissions(): void {
    Promise.all([
      this.hasPermission(this.lfxPermissionsAll),
      this.rolesService.hasOnlyRoles(this.lfxPermissionsAll),
    ])
      .then(([hasPermissions, hasRoles]) => {
        if (hasPermissions || hasRoles) {
          if (!this.ngxPermissionsExcept) {
            this.handleAuthorisedPermission(this.getAuthorisedTemplates());
          } else {
            Promise.all([
              this.hasPermission(this.ngxPermissionsExcept),
              this.rolesService.hasOnlyRoles(this.ngxPermissionsExcept),
            ]).then(([hasPermission, hasRole]) => {
              if (hasPermission || hasRole) {
                this.handleUnauthorisedPermission(this.ngxPermissionsElse);

                return;
              }
            });
          }
        } else {
          this.handleUnauthorisedPermission(this.ngxPermissionsElse);
        }
      })
      .catch(() => {
        this.handleUnauthorisedPermission(this.ngxPermissionsElse);
      });
  }

  private hasArrayPermission(permissions: string[]): Promise<boolean> {
    const promises: Observable<boolean>[] = permissions.map(key => {
      if (this.hasPermissionValidationFunction(key)) {
        const immutableValue = { ...this.permissions };
        const validationFunction: (key, immutableValue) => any = this
          .permissions[key].validationFunction;

        return of(null).pipe(
          map(() => validationFunction(key, immutableValue)),
          switchMap(
            (promise: Promise<boolean> | boolean): ObservableInput<boolean> =>
              this.isBoolean(promise) ? of(promise) : promise
          ),
          catchError(() => of(false))
        );
      }

      // check for name of the permission if there is no validation function
      return of(!!this.permissions[key]);
    });

    return from(promises)
      .pipe(
        mergeAll(),
        first(data => data !== true, true),
        map(data => (data === false ? false : true))
      )
      .toPromise()
      .then((data: any) => data);
  }

  private hasPermissionValidationFunction(key: string): boolean {
    return (
      !!this.permissions[key] &&
      !!this.permissions[key].validationFunction &&
      this.isFunction(this.permissions[key].validationFunction)
    );
  }

  private handleUnauthorisedPermission(template: TemplateRef<any>): void {
    if (
      this.isBoolean(this.currentAuthorizedState) &&
      !this.currentAuthorizedState
    ) {
      return;
    }

    this.currentAuthorizedState = false;
    this.permissionsUnauthorized.emit();

    if (this.getUnAuthorizedStrategyInput()) {
      this.applyStrategyAccordingToStrategyType(
        this.getUnAuthorizedStrategyInput()
      );

      return;
    }

    if (
      this.configurationService.onUnAuthorisedDefaultStrategy &&
      !this.elseBlockDefined()
    ) {
      this.applyStrategy(
        this.configurationService.onUnAuthorisedDefaultStrategy
      );
    } else {
      this.showTemplateBlockInView(template);
    }
  }

  private handleAuthorisedPermission(template: TemplateRef<any>): void {
    if (
      this.isBoolean(this.currentAuthorizedState) &&
      this.currentAuthorizedState
    ) {
      return;
    }

    this.currentAuthorizedState = true;
    this.permissionsAuthorized.emit();

    if (this.getAuthorizedStrategyInput()) {
      this.applyStrategyAccordingToStrategyType(
        this.getAuthorizedStrategyInput()
      );

      return;
    }

    if (
      this.configurationService.onAuthorisedDefaultStrategy &&
      !this.thenBlockDefined()
    ) {
      this.applyStrategy(this.configurationService.onAuthorisedDefaultStrategy);
    } else {
      this.showTemplateBlockInView(template);
    }
  }

  private applyStrategyAccordingToStrategyType(
    strategy: string | ((templateRef: any) => void)
  ): void {
    if (this.isString(strategy)) {
      this.applyStrategy(strategy);

      return;
    }

    if (this.isFunction(strategy)) {
      this.showTemplateBlockInView(this.templateRef);
      (strategy as (templateRef) => void)(this.templateRef);

      return;
    }
  }

  private showTemplateBlockInView(template: TemplateRef<any>): void {
    this.viewContainer.clear();

    if (!template) {
      return;
    }

    this.viewContainer.createEmbeddedView(template);
    this.changeDetector.markForCheck();
  }

  private getAuthorisedTemplates(): TemplateRef<any> {
    return this.ngxPermissionsThen || this.templateRef;
  }

  private elseBlockDefined(): boolean {
    return !!this.ngxPermissionsElse;
  }

  private thenBlockDefined() {
    return !!this.ngxPermissionsThen;
  }

  private getAuthorizedStrategyInput() {
    return this.ngxPermissionsAuthorisedStrategy;
  }

  private getUnAuthorizedStrategyInput() {
    return this.ngxPermissionsUnauthorisedStrategy;
  }

  private applyStrategy(str: any) {
    if (str === NgxPermissionsPredefinedStrategies.SHOW) {
      this.showTemplateBlockInView(this.templateRef);

      return;
    }

    if (str === NgxPermissionsPredefinedStrategies.REMOVE) {
      this.viewContainer.clear();

      return;
    }

    const strategy = this.configurationService.getStrategy(str);

    this.showTemplateBlockInView(this.templateRef);
    strategy(this.templateRef);
  }

  private isFunction(functionToCheck: any): functionToCheck is () => void {
    const getType = {};

    return (
      !!functionToCheck &&
      functionToCheck instanceof Function &&
      getType.toString.call(functionToCheck) === '[object Function]'
    );
  }

  private isPlainObject(value: any): boolean {
    if (Object.prototype.toString.call(value) !== '[object Object]') {
      return false;
    } else {
      const prototype = Object.getPrototypeOf(value);

      return prototype === null || prototype === Object.prototype;
    }
  }

  private isString(value: any): value is string {
    return !!value && typeof value === 'string';
  }

  private isBoolean(value: any): value is boolean {
    return typeof value === 'boolean';
  }

  private isPromise(promise: any) {
    return Object.prototype.toString.call(promise) === '[object Promise]';
  }

  private notEmptyValue(value: any): boolean {
    if (Array.isArray(value)) {
      return value.length > 0;
    }

    return !!value;
  }

  private transformStringToArray(value: any): string[] {
    if (this.isString(value)) {
      return [value];
    }

    return value;
  }
}
