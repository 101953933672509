import gql from 'graphql-tag';

export const requestAddCLAManagerDesignee = gql`
  mutation requestAddCLAManagerDesignee(
    $input: RequestAddCLAManagerDesigneeInput!
  ) {
    requestAddCLAManagerDesignee(input: $input) {
      code
      message
      success
      data
    }
  }
`;
