import { WizardTabInfo } from '@models';

export const foundationCommitteesTabList: WizardTabInfo[] = [
  { title: 'Committee', description: '', stat: 1 },
];

export const foundationDetailsTabList: WizardTabInfo[] = [
  {
    title: 'Foundation Essentials',
    description: 'General details and branding',
    stat: 1,
  },
  {
    title: 'Foundation Legal',
    description: 'Legal entity and documents',
    stat: 2,
  },
  { title: 'Foundation Finance', description: 'Finance and billing', stat: 3 },
  { title: 'Foundation Membership', description: 'Membership tiers', stat: 4 },
];

export const projectDetailsTabList: WizardTabInfo[] = [
  {
    title: 'Project Essentials',
    description: 'General details and branding',
    stat: 1,
  },
  {
    title: 'Project Legal',
    description: 'Legal entity and documents',
    stat: 2,
  },
  { title: 'Project Finance', description: 'Finance and billing', stat: 3 },
  { title: 'Project Membership', description: 'Membership tiers', stat: 4 },
];
