import gql from 'graphql-tag';

export const createProjectCommitteesMutation = gql`
  mutation createProjectCommittees(
    $committeesInfo: CreateProjectCommitteesInput!
  ) {
    createProjectCommittees(input: $committeesInfo) {
      code
      message
      success
      committees {
        id
        collaborationName
        name
        description
        category
        url
      }
    }
  }
`;
