import { Component, OnInit, OnDestroy } from '@angular/core';
import { pageSettings } from '@config';

@Component({
  selector: 'lfx-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit, OnDestroy {
  pageSettings = pageSettings;

  constructor() {
    this.pageSettings.pageEmpty = true;
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.pageSettings.pageEmpty = false;
  }
}
