export const operationMessages = {
  profile: {
    loadError: 'Loading your profile failed.',
    updateError: 'Updating your profile failed.',
    updateSuccess: 'Profile updated successfully.',
    emailConflictError:
      'could not be added because it is associated with another user account.',
    partialUpdateEmailValidationError: 'is invalid',
    updateEmailGenericError: 'Something went wrong while trying to add email',
    updateEmptyEmailError: 'Email address cannot be an empty string',
  },
  avatar: {
    updateSuccess: 'Avatar updated successfully.',
    updateError: 'Updating your avatar failed.',
  },
  favorite: {
    loadError: 'Loading your Favorites failed.',
    updateSuccess: 'Favorite updated successfully.',
    updateError: 'Updating your Favorite failed.',
  },
  resetPassword: {
    updateSuccess: 'Reset password email sent successfully.',
    updateError: 'Reset password email failed to sent!',
  },
  toggleAccountLinking: {
    linkError: 'Could not link your account',
    unlinkError: 'Could not unlink this account, please try again later',
  },
  resendVerificationEmail: {
    resendSuccess: 'Verification email has been sent to',
    resendError: 'Sending verification email failed.',
  },
  newFoundation: {
    networkError:
      'Could not create a new foundation right now, please try again later',
  },
  newProject: {
    projectExistsError:
      'A Project with the same name exists, please choose another',
    networkError:
      'Could not create a new project right now, please try again later',
  },
  administrator: {
    addedSuccessfully: (name: string) =>
      `Admin role for ${name} was assigned successfully`,
    addedError: 'Error assigning admin role',
    resendInviteSuccessfully: (name: string) =>
      `Admin role invitation for ${name} was resent successfully`,
    resendInviteError: 'Error resending admin invite',
    removeRoleError: 'Error unassign admin role',
    removeRoleSuccessfully: (name: string) =>
      `Admin role for ${name} was unassigned successfully`,
    init: 'Loading organization administrators failed',
  },
  membershipContact: {
    resendInviteSuccessfully: (name: string) =>
      `Membership contact invitation for ${name} was sent successfully`,
  },
  committeeMembers: {
    genericError: 'Could not fetch Committee Members',
  },
};
