import gql from 'graphql-tag';

export const checkUserPermissionsQuery = gql`
  query checkUserPermissions($permissionRequest: [String]!) {
    checkUserPermissions(permissionRequest: $permissionRequest) {
      permission
      status
    }
  }
`;
