import gql from 'graphql-tag';

export const approvedContributorsQuery = gql`
  query getApprovedContributorsList($input: ApprovedContributorInput!) {
    approvedContributorsList(input: $input) {
      username
      email
      image
      githubUsername
      domainUrl
      coverageType
      githubOrgUrl
      dateCreated
      gitlabUsername
      gitlabOrgUrl
      autoCreateECLA
    }
  }
`;
