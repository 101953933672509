import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  currentUserQuery,
  rolesQuery,
  listUsersQuery,
  getInviteQuery,
  checkUserPermissionsQuery,
} from '../queries';
import {
  updateCurrentUserMutation,
  updateAvatarMutation,
  updateUserMutation,
  changeUserPasswordMutation,
  linkSocialIdentityMutation,
  unlinkSocialIdentityMutation,
} from '../mutations';
import {
  CurrentUserResult,
  UpdateCurrentUserResult,
  UpdateCurrentUserPayload,
  UpdateUserPayload,
  RolesResult,
  UpdateUserResult,
  LinkSocialIdentityPayload,
  LinkSocialIdentityResult,
  UnLinkSocialIdentityResult,
  PermissionResult,
} from './results';
import {
  UpdateCurrentUserInput,
  UserPasswordInput,
  UpdateUserInput,
  FilterInput,
} from './inputs';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { User, Role, Permission, MutationResponse } from '@models';
import { SocialAuthProviders } from '@config';
import { updateInviteMutation } from '../mutations/user/update-invitation';
import { deleteInviteMutation } from '../mutations/user/delete-invitation';

@Injectable({
  providedIn: 'root',
})
export class UserServiceGql {
  constructor(private apollo: Apollo) {}

  getCurrentUser(withPermissions = true): Observable<User> {
    return this.apollo
      .query<CurrentUserResult>({
        query: currentUserQuery,
        variables: {
          withPermissions,
          basic: !withPermissions,
        },
      })
      .pipe(
        catchError(error => {
          if (
            error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].extensions &&
            error.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
          ) {
            return throwError(new Error('UNAUTHENTICATED'));
          }

          return of(null);
        }),
        map((res: any) => {
          if (!res) {
            return null;
          }

          return res.data.currentUser;
        })
      );
  }

  updateUserAvatar(file: File): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: updateAvatarMutation,
        variables: { file },
        context: { useMultipart: true, hasUpload: true },
      })
      .pipe(map(res => res.data.updateUserAvatar));
  }

  updateCurrentUser(
    userInfo: UpdateCurrentUserInput
  ): Observable<UpdateCurrentUserPayload> {
    return this.apollo
      .mutate<UpdateCurrentUserResult>({
        mutation: updateCurrentUserMutation,
        variables: {
          userInfo,
        },
      })
      .pipe(map(res => res.data.updateCurrentUser));
  }

  updateUser(userInfo: UpdateUserInput): Observable<UpdateUserPayload> {
    return this.apollo
      .mutate<UpdateUserResult>({
        mutation: updateUserMutation,
        variables: {
          userInfo,
        },
      })
      .pipe(map(res => res.data.updateUser));
  }

  changeUserPassword(
    userInfo: UserPasswordInput
  ): Observable<UpdateCurrentUserPayload> {
    return this.apollo
      .mutate<UpdateCurrentUserResult>({
        mutation: changeUserPasswordMutation,
        variables: {
          userInfo,
        },
      })
      .pipe(map(res => res.data.updateCurrentUser));
  }

  getUserRoles(roleInput: FilterInput): Observable<Role[]> {
    return this.apollo
      .watchQuery<RolesResult>({
        query: rolesQuery,
        variables: {
          roleInput,
        },
      })
      .valueChanges.pipe(map(res => res.data.roles));
  }

  listUsers() {
    return this.apollo
      .watchQuery<any>({
        query: listUsersQuery,
      })
      .valueChanges.pipe(map(res => res.data.listUsers));
  }

  linkSocialIdentity(
    userId: string,
    socialProvider: SocialAuthProviders
  ): Observable<LinkSocialIdentityPayload> {
    return this.apollo
      .mutate<LinkSocialIdentityResult>({
        mutation: linkSocialIdentityMutation,
        variables: {
          input: {
            userId,
            socialProvider,
          },
        },
      })
      .pipe(map(res => res.data.linkUserIdentity));
  }

  unlinkSocialIdentity(
    userId: string,
    socialProvider: SocialAuthProviders
  ): Observable<LinkSocialIdentityPayload> {
    return this.apollo
      .mutate<UnLinkSocialIdentityResult>({
        mutation: unlinkSocialIdentityMutation,
        variables: {
          input: {
            userId,
            socialProvider,
          },
        },
      })
      .pipe(map(res => res.data.unlinkUserIndentity));
  }

  getInvitation(inviteId: string): Observable<any> {
    return this.apollo
      .query<any>({
        query: getInviteQuery,
        variables: { inviteId },
      })
      .pipe(map(res => res.data.getInvite.data));
  }

  updateInvitation(inviteId: string, status: string): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: updateInviteMutation,
        variables: {
          input: {
            inviteId,
            status,
          },
        },
      })
      .pipe(map(res => res.data));
  }

  deleteInvitation(inviteId: string): Observable<MutationResponse<null>> {
    return this.apollo
      .mutate<any>({
        mutation: deleteInviteMutation,
        variables: {
          input: {
            inviteId,
          },
        },
      })
      .pipe(map(res => res.data.deleteInvite));
  }

  checkUserPermissions(permissionRequest: string[]): Observable<Permission[]> {
    return this.apollo
      .query<PermissionResult>({
        query: checkUserPermissionsQuery,
        variables: { permissionRequest },
      })
      .pipe(map(res => res.data.checkUserPermissions));
  }
}
