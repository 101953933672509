import gql from 'graphql-tag';

export const foundationProjectVulnerabilityDetectedQuery = gql`
  query foundationProjectVulnerabilityDetected(
    $input: VulnerabilityStatisticsLeaderboardInput
  ) {
    foundationProjectVulnerabilityDetected(input: $input) {
      month
      severity
      totalFixableIssue
      year
      totalIssue
    }
  }
`;
