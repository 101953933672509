import { UserContext } from '../core/models/user-context';

export const pageMenus: MenuItem[] = [
  // {
  //   icon: 'fa fa-th-large',
  //   title: 'Dashboard',
  //   url: '/user/dashboard',
  //   context: UserContext.Individual,
  //   flag: 'my_projects_view_all'
  // },
  {
    icon: 'fas fa-th-large',
    title: 'Dashboard',
    url: '/admin',
    context: UserContext.Admin,
    permissions: {
      all: ['my_projects_view_all'],
    },
  },
  {
    title: 'My Organization',
    context: UserContext.Company,
    caret: true,
    submenu: [
      {
        icon: 'fad fa-th-large',
        title: 'Dashboard',
        url: '/company/dashboard',
        permissions: {
          all: ['feature_company-dashboard'],
        },
      },
      // {
      //   icon: 'fal fa-cog',
      //   title: 'Manage Organization',
      //   url: '/company/manage',
      //   permissions: {
      //     all: ['feature_manage-org'],
      //   },
      // },
    ],
  },
  {
    title: 'Projects',
    id: 'projects',
    caret: true,
    state: 'expand',
    context: UserContext.Company,
    autoComplete: true,
    toolTipText: 'Search for a project to view project specific information',
    permissions: {
      all: ['feature_foundation-discovery'],
    },
  },
  // {
  //   icon: 'fas fa-toolbox',
  //   title: 'Services',
  //   url: '',
  //   caret: true,
  //   context: UserContext.Company,
  //   permissions: {
  //     any: [
  //       'feature_statistics_landing',
  //       'feature_cla_landing',
  //       'feature_security_landing',
  //     ],
  //   },
  //   submenu: [
  //     {
  //       title: 'Statistics',
  //       url: '/statistics',
  //       permissions: {
  //         all: ['feature_statistics_landing'],
  //       },
  //     },
  //     {
  //       title: 'CLA',
  //       url: '/company/cla',
  //       permissions: {
  //         all: ['feature_cla_landing'],
  //       },
  //     },
  //     {
  //       title: 'Security',
  //       url: '/company/security',
  //       permissions: {
  //         all: ['feature_security_landing'],
  //       },
  //     },
  //   ],
  // },
  // {
  //   icon: 'fas fa-cog',
  //   title: 'Edit Profile',
  //   url: '/user/account-settings',
  //   context: UserContext.Individual,
  //   permissions: {
  //     all: ['feature_account-settings'],
  //   },
  // },
];

export interface MenuItem {
  id?: string;
  icon?: string;
  title: string;
  url?: string;
  flag?: string;
  context?: UserContext;
  favorite?: boolean;
  caret?: boolean;
  permissions?: MenuItemPermissions;
  submenu?: MenuItem[];
  state?: any;
  updateMenu?: boolean;
  autoComplete?: boolean;
  hoverList?: {
    text: string;
    list: {
      parent: Pick<MenuItem, 'id' | 'title' | 'url'>;
      list: Pick<MenuItem, 'id' | 'title' | 'url'>[];
    };
  };
  toolTipText?: string;
}

export interface MenuItemPermissions {
  all?: string | string[];
  any?: string | string[];
  except?: string | string[];
}
