import gql from 'graphql-tag';

export const createNewProjectMutation = gql`
  mutation createNewProject($projectInfo: CreateNewProjectInput!) {
    createNewProject(input: $projectInfo) {
      code
      message
      success
      newProject {
        id
      }
    }
  }
`;
