import gql from 'graphql-tag';

export const foundationDomainsQuery = gql`
  query foundationDomains($foundationId: ID!) {
    foundationDomains(foundationId: $foundationId) {
      name
      state
    }
  }
`;
