import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textShorten',
})
export class TextShortenPipe implements PipeTransform {
  constructor() {}

  transform(v: string, shortenBy: number): string {
    return v
      ? v.length > shortenBy
        ? v.slice(0, shortenBy).concat('...')
        : v
      : '';
  }
}
