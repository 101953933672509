import gql from 'graphql-tag';

export const rootVulnerableProjectsQuery = gql`
  query rootVulnerableProjects {
    rootVulnerableProjects {
      id
      name
      parent
      updatedAt
      description
      logoUrl
      colorBand
      vulnerability {
        projectId
        sfdcProjectId
        message
        totalRepositories
        totalScannedRepositories
        updatedAt
        securityIssues {
          high {
            totalCount
            fixableCount
          }
          medium {
            totalCount
            fixableCount
          }
          low {
            totalCount
            fixableCount
          }
          total {
            totalCount
            fixableCount
          }
        }
      }
    }
  }
`;
