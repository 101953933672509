import { SearchFoundationsInput } from '@lfx/core/gql';
import { Injectable } from '@angular/core';
import {
  ActivityLog,
  DomainAvailability,
  DomainInfo,
  DomainInfoResult,
  Foundation,
  FoundationDomain,
  FoundationFinance,
  FoundationMeeting,
  FoundationProject,
  ProjectType,
  Resource,
  Statistics,
  UserGroup,
  UserGroupType,
} from '@models';
import { Observable, Subject } from 'rxjs';
import {
  AdminServiceGql,
  CreateNewFoundationPayload,
  FoundationServiceGql,
  ProjectServiceGql,
  UpdateFoundationLegalInput,
  UpdateFoundationLegalPayload,
} from '../gql';
import { map, take } from 'rxjs/operators';
import { isEmpty } from 'lodash';
import { generalConstants } from '@lfx/config';

@Injectable({
  providedIn: 'root',
})
export class FoundationService {
  foundationAdminForm: Subject<string> = new Subject<string>();

  constructor(
    private foundationServiceGql: FoundationServiceGql,
    private adminServiceGql: AdminServiceGql,
    private projectServiceGql: ProjectServiceGql
  ) {}

  public getMyFoundations(
    withMembersData = true,
    withProjectsData = true,
    withProgramManagerData = false
  ): Observable<Foundation[]> {
    return this.foundationServiceGql.getMyFoundations(
      withMembersData,
      withProjectsData,
      withProgramManagerData
    );
  }

  public getSuggestedFoundations(): Observable<Foundation[]> {
    return this.foundationServiceGql.getSuggestedFoundations();
  }

  public getNonMemberFoundations(
    withMembersData = true,
    withProjectsData = true
  ): Observable<Foundation[]> {
    return this.foundationServiceGql.getNonMemberFoundations(
      withMembersData,
      withProjectsData
    );
  }

  public getLfSponsoredProjects(): Observable<FoundationProject[]> {
    return this.projectServiceGql.getLfSponsoredProjects();
  }

  public getFoundations(withMembersData = false): Observable<Foundation[]> {
    return this.foundationServiceGql.getAllFoundations(withMembersData);
  }

  public claEnabledFoundations(): Observable<Foundation[]> {
    return this.foundationServiceGql.claEnabledFoundations();
  }

  public searchFoundation(query?: SearchFoundationsInput) {
    return this.foundationServiceGql.searchFoundations(query);
  }

  public getFoundationDomains(foundationId): Observable<FoundationDomain[]> {
    return this.foundationServiceGql.getFoundationDomains(foundationId);
  }

  public checkDomainAvailability(
    domain: string
  ): Observable<DomainAvailability> {
    return this.adminServiceGql.checkDomainAvailability(domain);
  }

  public registerDomain(domain: DomainInfo): Observable<DomainInfoResult> {
    return this.adminServiceGql.addNewDomain(domain);
  }

  public uploadContractFile(file) {
    return this.foundationServiceGql.uploadContractDoc(file);
  }

  public removeUploadedContractFile(key) {
    return this.foundationServiceGql.removeUploadedContractDoc(key);
  }

  public getFoundation(
    id: string,
    withAdminData = false,
    withProjectsData = false,
    withMembersData = false,
    withContributionsData = false,
    communityYearEvent = 'all',
    organizationYearEvent = 'all'
  ): Observable<Foundation> {
    return this.foundationServiceGql.getFoundation(
      id,
      withAdminData,
      withProjectsData,
      withMembersData,
      withContributionsData,
      communityYearEvent,
      organizationYearEvent
    );
  }

  public getFoundationForStatistics(
    id: string,
    filter?: string
  ): Observable<Foundation> {
    return this.foundationServiceGql.getFoundationForStatistics(id, filter);
  }

  public getFoundationById(
    id: string,
    withAdminData = false,
    withProjectsData = false
  ): Observable<Foundation> {
    return this.foundationServiceGql.getFoundation(
      id,
      withAdminData,
      withProjectsData
    );
  }

  public updateFoundationEssential(input: any): Observable<Foundation> {
    return this.foundationServiceGql.updateFoundationEssential(input.data);
  }

  public getMyFoundationProjects(): Observable<FoundationProject[]> {
    return this.projectServiceGql.getAllProjects();
  }

  public getFoundationStatistics(id: string): Observable<Statistics> {
    return this.foundationServiceGql.getFoundationStatistics(id);
  }

  public getFoundationCommittees(id: string): Observable<UserGroup[]> {
    return this.foundationServiceGql.getFoundationCommittees(id);
  }

  public getFoundationResources(id: string): Observable<Resource[]> {
    return this.foundationServiceGql.getFoundationResources(id);
  }

  public getFoundationMeetings(id: string): Observable<FoundationMeeting> {
    return this.foundationServiceGql.getFoundationMeetings(id);
  }

  public updateFoundationLegal(
    input: UpdateFoundationLegalInput
  ): Observable<UpdateFoundationLegalPayload> {
    return this.foundationServiceGql.updateFoundationLegal(input);
  }

  public updateFoundationFinance(
    foundationId: string,
    foundationFinanceInfo: FoundationFinance
  ): Observable<FoundationFinance> {
    if (foundationFinanceInfo) {
      return this.foundationServiceGql.updateFoundationFinance({
        id: foundationId,
        ...foundationFinanceInfo,
      });
    } else {
      return null;
    }
  }

  public createNewFoundation(
    foundationName: string
  ): Observable<CreateNewFoundationPayload> {
    return this.foundationServiceGql.creatNewFoundation({ foundationName });
  }

  public createFoundationCommittees(
    foundationId: string,
    committees: UserGroup[]
  ): Observable<UserGroup[]> {
    const committeesInput = { foundationId, committees: [] };

    committees.forEach(committee => {
      const committeeInput = {
        category: Object.keys(UserGroupType).find(
          x => UserGroupType[x] === committee.category
        ),
        collaborationName: committee.collaborationName,
        description: committee.description,
        url: committee.url,
      };

      committeesInput.committees.push(committeeInput);
    });

    return this.foundationServiceGql.createFoundationCommittees(
      committeesInput
    );
  }

  public updateCommittee(
    foundationId: string,
    committee: UserGroup
  ): Observable<UserGroup> {
    const updateCommitteeObj = {
      category: committee.category,
      collaborationName: committee.collaborationName,
      description: committee.description,
      url: committee.url,
    };
    const updateCommitteeInput = {
      foundationId,
      committeeId: committee.id,
      committee: updateCommitteeObj,
    };

    return this.foundationServiceGql.updateFoundationCommittee(
      updateCommitteeInput
    );
  }

  public removeCommittee(
    foundationId: string,
    committeeId: string
  ): Observable<boolean> {
    const deleteCommitteeInput = {
      foundationId,
      committeeId,
    };

    return this.foundationServiceGql.deleteFoundationCommittee(
      deleteCommitteeInput
    );
  }

  getFoundationMemberShips(foundationId: string): Observable<Foundation> {
    return this.foundationServiceGql.getFoundationMemberShips(foundationId);
  }

  getFoundationActivityLogs(input): Observable<ActivityLog[]> {
    return this.foundationServiceGql.getFoundationActivityLogs(input).pipe(
      map(res => {
        if (res === null) {
          return [];
        }

        return res;
      })
    );
  }

  getProjectType(project: Foundation): ProjectType {
    if (
      project.projectType === ProjectType.projectGroup &&
      !project.foundation
    ) {
      return ProjectType.projectGroup;
    } else if (this.isStandAloneProject(project)) {
      if (project.projectType === ProjectType.projectGroup) {
        return ProjectType.standAloneProjectGroup;
      }

      return ProjectType.standAloneProject;
    }

    return ProjectType.project;
  }

  isStandAloneProject(project: Foundation) {
    return (
      !project.foundation ||
      isEmpty(project.foundation) ||
      (project.foundation &&
        project.foundation.name ===
          generalConstants.standAloneProjectLinuxFoundationName)
    );
  }

  getStandAloneProjectsLinuxFoundationId(): Observable<string> {
    return this.searchFoundation({
      name: [generalConstants.standAloneProjectLinuxFoundationName],
    }).pipe(
      take(1),
      map(foundations => {
        if (foundations && !isEmpty(foundations)) {
          return foundations[0].id;
        }
      })
    );
  }

  getStandAloneProjectsFoundationsIds(): Observable<string[]> {
    return this.searchFoundation({
      name: [generalConstants.standAloneProjectLinuxFoundationName],
    }).pipe(
      take(1),
      map(foundations => {
        if (foundations && !isEmpty(foundations)) {
          return foundations.reduce((previous, current) => {
            previous.push(current.id);

            return previous;
          }, []);
        }
      })
    );
  }
}
