'use strict';
export interface NavLink {
  name: string;
  fragment: string;
  permissions?: NavLinkPermissions;
}
export interface NavLinkPermissions {
  all?: string | string[];
  any?: string | string[];
  except?: string | string[];
}

export const dashboardNavLinks: NavLink[] = [
  {
    name: 'Foundations',
    fragment: 'foundations',
    permissions: {},
  },
  // {
  //   name: 'Crowdfunding',
  //   fragment: 'crowdfunding',
  //   flags: ['feature_crowdfunding']
  // },
  {
    name: 'CLA',
    fragment: 'cla',
    permissions: {
      any: ['feature_cla'],
    },
  },
  {
    name: 'Security',
    fragment: 'security',
    permissions: {
      any: ['feature_security'],
    },
  },
  {
    name: 'Code Contributions',
    fragment: 'contributions',
    permissions: {
      any: ['feature_statistics_widgets'],
    },
  },
];

export const foundationDetailNavLinks: NavLink[] = [
  {
    name: 'Overview',
    fragment: 'overview',
    permissions: {},
  },
  {
    name: 'Code Contributions',
    fragment: 'contributions',
    permissions: {
      all: [
        'feature_foundation-details_code-contributions',
        'feature_statistics_widgets',
      ],
    },
  },
  // {
  //   name: 'Statistics',
  //   fragment: 'statistics',
  //   permissions: {
  //     any: ['feature_statistics']
  //   },
  // },
  // {
  //   name: 'Governance',
  //   fragment: 'governance',
  //   permissions: {

  //   },
  // },
  // {
  //   name: 'Meetings',
  //   fragment: 'meetings',
  //   permissions: {
  //     any: ['feature_collaborations']
  //   },
  // },
  // {
  //   name: 'Mailing Lists + Chat',
  //   fragment: 'mailing-list-chat',
  //   permissions: {
  //     any: ['feature_collaborations']
  //   },
  // },
  {
    name: 'Projects',
    fragment: 'projects',
    permissions: {
      any: ['feature_foundation-details_projects'],
    },
  },
  // {
  //   name: 'Resources',
  //   fragment: 'resources',
  //   permissions: {
  //   },
  // },
  {
    name: 'Members',
    fragment: 'members',
    permissions: {
      any: ['feature_foundation-details_member-orgs'],
    },
  },
];

export const projectDetailNavLinks: NavLink[] = [
  {
    name: 'Overview',
    fragment: 'overview',
    permissions: {},
  },
  {
    name: 'Statistics',
    fragment: 'statistics',
    permissions: {
      any: ['feature_statistics_project'],
    },
  },
  // {
  //   name: 'Governance',
  //   fragment: 'governance',
  //   permissions: {
  //   },
  // },
  // {
  //   name: 'Mailing List + Chat',
  //   fragment: 'mailing-list-chat',
  //   permissions: {
  //     any: ['feature_collaborations']
  //   },
  // },
  // {
  //   name: 'Meetings',
  //   fragment: 'meetings',
  //   permissions: {
  //     any: ['feature_collaborations']
  //   },
  // },
  // {
  //   name: 'Resources',
  //   fragment: 'resources',
  //   permissions: {
  //   },
  // }
];

export const adminFoundationNavLinks: NavLink[] = [
  {
    name: 'Overview',
    fragment: 'overview',
    permissions: {},
  },
  {
    name: 'Committees',
    fragment: 'committees',
    permissions: {},
  },
  {
    name: 'Contacts',
    fragment: 'contacts',
    permissions: {
      any: ['feature_statistics'],
    },
  },
  {
    name: 'Infrastructure',
    fragment: 'infrastructure',
    permissions: {
      any: ['feature_infrastructure'],
    },
  },
];

export const adminProjectNavLinks: NavLink[] = [
  {
    name: 'Overview',
    fragment: 'overview',
    permissions: {},
  },
  {
    name: 'Committees',
    fragment: 'committees',
    permissions: {},
  },
  {
    name: 'Contacts',
    fragment: 'contacts',
    permissions: {},
  },
  {
    name: 'Infrastructure',
    fragment: 'infrastructure',
    permissions: {
      any: ['feature_infrastructure'],
    },
  },
];

export const securityNavLinks: NavLink[] = [
  {
    name: 'Usage Metrics + Adoption',
    fragment: 'usage_metrics_Adoption',
    permissions: {
      all: 'feature_security_landing_usage',
    },
  },
  {
    name: 'Severity of Vulnerabilities',
    fragment: 'severity_of_vulnerabilities',
    permissions: {
      all: 'feature_security_landing_severity',
    },
  },
  {
    name: 'Activity Log',
    fragment: 'activity_log',
    permissions: {
      all: 'feature_security_landing_activity-log',
    },
  },
  {
    name: 'Projects with Vulnerability Detection',
    fragment: 'projects_with_vulnerability_detection',
    permissions: {
      all: 'feature_security_landing_projects-with-vulnerability',
    },
  },
];
