import gql from 'graphql-tag';

export const deleteMembershipContactMutation = gql`
  mutation deleteMembershipContact($input: DeleteMembershipContactInput!) {
    deleteMembershipContact(input: $input) {
      message
      code
      success
    }
  }
`;
