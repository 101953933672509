<!-- begin panel -->
<div lfxMovable class="panel">
  <div class="header">
    <span>Help</span>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closeHelpSupportFrame()"
      ngbTooltip="dismiss help"
      placement="top"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="iframe-loader">
    <iframe
      height="100%"
      width="100%"
      [src]="url"
      name="help-support-frame"
    ></iframe>
  </div>
</div>
<!-- end panel -->
