import { Injectable } from '@angular/core';
import {
  FoundationProject,
  Meeting,
  ProjectStatistics,
  Resource,
  UserGroup,
  ActivityLogObject,
} from '@models';
import { Observable } from 'rxjs';
import {
  ProjectServiceGql,
  CreateNewProjectPayload,
  ProjectActivityLogsInput,
} from '../gql';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(private projectServiceGql: ProjectServiceGql) {}

  public getProjectForStatistics(
    id: string,
    filter?: string
  ): Observable<FoundationProject> {
    return this.projectServiceGql.getProjectForStatistics(id, filter);
  }

  public getAdminProjectDetail(
    id: string,
    withCommitteesData = false
  ): Observable<FoundationProject> {
    return this.projectServiceGql.getProject(id, withCommitteesData);
  }

  public getProjectById(
    id: string,
    withCommitteesData = false
  ): Observable<FoundationProject> {
    return this.projectServiceGql.getProject(id, withCommitteesData);
  }

  public getProjectStatistics(id: string): Observable<ProjectStatistics> {
    return this.projectServiceGql.getProjectStatistics(id);
  }

  public getProjectMeetings(id: string): Observable<Meeting[]> {
    return this.projectServiceGql.getProjectMeetings(id);
  }

  public getProjectResources(id: string): Observable<Resource[]> {
    return this.projectServiceGql.getProjectResources(id);
  }

  public updateProjectEssential(input: any): Observable<FoundationProject> {
    return this.projectServiceGql.updateProjectEssential(input.data);
  }

  public getProjectCommittees(id: string): Observable<UserGroup[]> {
    return this.projectServiceGql.getProjectCommittees(id);
  }

  public createProjectCommittees(
    projectId: string,
    committees: UserGroup[]
  ): Observable<UserGroup[]> {
    const committeesInput = { projectId, committees: [] };

    committees.forEach(committee => {
      const committeeInput = {
        category: committee.category,
        collaborationName: committee.collaborationName,
        description: committee.description,
        url: committee.url,
      };

      committeesInput.committees.push(committeeInput);
    });

    return this.projectServiceGql.createProjectCommittees(committeesInput);
  }

  public updateProjectCommittee(
    projectId: string,
    committee: UserGroup
  ): Observable<UserGroup> {
    const updateCommitteeObj = {
      category: committee.category,
      collaborationName: committee.collaborationName,
      description: committee.description,
      url: committee.url,
    };
    const updateCommitteeInput = {
      projectId,
      committeeId: committee.id,
      committee: updateCommitteeObj,
    };

    return this.projectServiceGql.updateProjectCommittee(updateCommitteeInput);
  }

  public removeProjectCommittee(
    projectId: string,
    committeeId: string
  ): Observable<boolean> {
    const deleteCommitteeInput = {
      projectId,
      committeeId,
    };

    return this.projectServiceGql.deleteProjectCommittee(deleteCommitteeInput);
  }

  public createNewProject(
    projectName: string,
    ownerId,
    parentId
  ): Observable<CreateNewProjectPayload> {
    return this.projectServiceGql.creatNewProject({
      projectName,
      ownerId,
      parentId,
    });
  }

  public getCommitteesCategories(): Observable<string[]> {
    return this.projectServiceGql.getCommitteesCategories();
  }

  getActivityLogs(
    input: ProjectActivityLogsInput,
    useCache?: boolean
  ): Observable<ActivityLogObject> {
    return this.projectServiceGql.getActivityLogs(input, useCache);
  }
}
