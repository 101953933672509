import gql from 'graphql-tag';

export const createMembershipContactMutation = gql`
  mutation createMembershipContact($input: CreateMembershipContactInput!) {
    createMembershipContact(input: $input) {
      message
      code
      success
      contact {
        role
        systemModstamp
        createdDate
        contact {
          id
        }
      }
    }
  }
`;
