export default {
  __schema: {
    types: [
      {
        kind: 'UNION',
        name: 'visualizationTypes',
        possibleTypes: [
          {
            name: 'StatisticsVisualization',
          },
          {
            name: 'NetworkVisualization',
          },
          {
            name: 'ChartsVisualization',
          },
          {
            name: 'GaugeVisualization',
          },
          {
            name: 'NumberVisualization',
          },
          {
            name: 'DataGridVisualization',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'Visualizations',
        possibleTypes: [
          {
            name: 'StatisticsVisualization',
          },
          {
            name: 'NetworkVisualization',
          },
          {
            name: 'ChartsVisualization',
          },
          {
            name: 'GaugeVisualization',
          },
          {
            name: 'NumberVisualization',
          },
          {
            name: 'DataGridVisualization',
          },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'MutationResponse',
        possibleTypes: [
          {
            name: 'UpdateCurrentUserPayload',
          },
          {
            name: 'CreateNewProjectPayload',
          },
          {
            name: 'UpdateProjectPayload',
          },
          {
            name: 'CreateProjectCommitteesPayload',
          },
          {
            name: 'UpdateProjectCommitteePayload',
          },
          {
            name: 'DeleteProjectCommitteePayload',
          },
          {
            name: 'CreateNewFoundationPayload',
          },
          {
            name: 'CreateCommitteesPayload',
          },
          {
            name: 'UpdateCommitteePayload',
          },
          {
            name: 'DeleteCommitteePayload',
          },
          {
            name: 'UpdateFoundationFinancePayload',
          },
          {
            name: 'UpdateFoundationLegalPayload',
          },
          {
            name: 'UpdateFoundationPayload',
          },
          {
            name: 'RemoveUploadPayload',
          },
          {
            name: 'CreateOrganizationPayload',
          },
          {
            name: 'UpdateOrganizationPayload',
          },
          {
            name: 'DeleteOrganizationPayload',
          },
        ],
      },
    ],
  },
};
