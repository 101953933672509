export interface WhitelistedDeveloper {
  firstName: string;
  lastName: string;
  requestStatus: WhitelistingStatus;
}

export enum WhitelistingStatus {
  Pending = 'pending',
  Approved = 'approved',
}
