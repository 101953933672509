import { Injectable } from '@angular/core';
import {
  SecurityIssues,
  SecurityProjectDetails,
  VulnerabilityPackageDetails,
  VulnerabilityPackagesQueryOptions,
  License,
  VulnerabilitiesOverview,
  Dependency,
  Foundation,
  VulnerableProjectModel,
  VulnerabilitiesSeverity,
  ActivityLog,
  VulnerabilityDetectionStatistics,
  VulnerabilityDetectionLeaderBoard,
  SecurityLanguagesDistributionInput,
} from '../models';
import { generalConstants } from '@config';
import { Observable, of, Subject } from 'rxjs';
import { SecurityServiceGql, SecurityProjectDetailsInput } from '../gql';
import { map, switchMap } from 'rxjs/operators';
import { MembershipService } from '@lfx/core/services/membership.service';
import {
  getMockFoundationProjectVulnerabilityDetected,
  getMockFoundationVulnerableProjects,
  getMockProjectsDependencyStackDepth,
  getMockProjectsRemediationRateDetails,
  getMockProjectTransitiveDependencies,
  getMockProjectVulnerabilityOverview,
  getMockSecurityActivityLogs,
  getMockSecurityLanguagesDistribution,
  getMockVulnerabilitiesSeverity,
  getMockVulnerabilityDetectionLeaderBoard,
  getMockVulnerabilityStatistics,
  getMockVulnerabilityUniqueLicensesDetails,
  getMockVulnerableProjects,
} from '@lfx/config/non-member-security-data';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  selectedSecurityNav = new Subject<string>();

  constructor(
    private securityServiceGql: SecurityServiceGql,
    private membershipService: MembershipService
  ) {}

  public getVulnerableProjects(): Observable<VulnerableProjectModel[]> {
    return this.checkMembership().pipe(
      switchMap(data =>
        !data
          ? of(getMockVulnerableProjects())
          : this.securityServiceGql
              .getVulnerableProjects()
              .pipe(map(projects => projects || []))
      )
    );
  }

  public getProjectVulnerabilityOverview(
    id,
    summaryType,
    years
  ): Observable<VulnerabilitiesOverview> {
    const vulnerabilityOverviewInput = { projectId: id, summaryType, years };

    return this.checkMembership().pipe(
      switchMap(data =>
        !data
          ? of(getMockProjectVulnerabilityOverview())
          : this.securityServiceGql.getProjectVulnerabilityOverview(
              vulnerabilityOverviewInput
            )
      )
    );
  }

  public getVulnerabilitiesSeverity(): Observable<VulnerabilitiesSeverity> {
    return this.checkMembership().pipe(
      switchMap(data =>
        !data
          ? of(getMockVulnerabilitiesSeverity())
          : this.securityServiceGql.getVulnerabilitiesSeverity()
      )
    );
  }

  public checkMembership() {
    return this.membershipService.getMyMemberships(true).pipe(
      map(memberships => memberships.filter(m => m.status === 'Active')),
      map(data => !!data.length)
    );
  }

  public getRepositoriesDependenciesVulnerabilities(
    id,
    repositoryIds,
    pathIds
  ): Observable<Dependency[]> {
    const vulnerabilitiesDependenciesInput = {
      projectId: id,
      repositoryIds,
      pathIds,
    };

    return this.securityServiceGql.getRepositoriesDependenciesVulnerabilities(
      vulnerabilitiesDependenciesInput
    );
  }

  public getSecurityIssuesByProjectId(
    projectId: string
  ): Observable<SecurityIssues[]> {
    return this.securityServiceGql
      .getSecurityIssues(projectId)
      .pipe(map(issues => issues || []));
  }

  public getSecurityProjectDetails(
    input: SecurityProjectDetailsInput
  ): Observable<SecurityProjectDetails> {
    return this.securityServiceGql.getSecurityProjectDetails(input);
  }

  public getSecurityProjectList(): Observable<Foundation[]> {
    return this.securityServiceGql
      .getSecurityProjectList()
      .pipe(map(projects => projects || []));
  }

  public getFoundationProjectVulnerabilityDetected(
    foundationId,
    projectId,
    offset?,
    limit?,
    sortOrder?,
    orderBy?
  ): Observable<any> {
    const vulnerabilitiesLicensesInput = {
      projectIds: projectId,
      foundationId,
      offset,
      limit,
      sortOrder,
      orderBy,
    };

    return this.checkMembership().pipe(
      switchMap(data =>
        !data
          ? of(getMockFoundationProjectVulnerabilityDetected())
          : this.securityServiceGql
              .getFoundationProjectVulnerabilityDetected(
                vulnerabilitiesLicensesInput
              )
              .pipe(map(project => project || []))
      )
    );
  }

  public getProjectPackages(
    projectId: string,
    query: VulnerabilityPackagesQueryOptions
  ): Observable<VulnerabilityPackageDetails[]> {
    return this.securityServiceGql
      .getProjectPackages(projectId, query)
      .pipe(map(packages => packages || []));
  }

  public getRepositoriesLicenses(
    id,
    offset?,
    limit?,
    sortOrder?,
    orderBy?
  ): Observable<License[]> {
    const vulnerabilitiesLicensesInput = {
      projectId: id,
      offset,
      limit,
      sortOrder,
      orderBy,
    };

    return this.securityServiceGql
      .getRepositoriesLicenses(vulnerabilitiesLicensesInput)
      .pipe(map(licenses => licenses || []));
  }

  getVulnerabilityStatistics(
    input
  ): Observable<VulnerabilityDetectionStatistics> {
    return this.checkMembership().pipe(
      switchMap(data =>
        !data
          ? of(getMockVulnerabilityStatistics())
          : this.securityServiceGql.getVulnerabilityStatistics(input)
      )
    );
  }

  getVulnerabilitiesDetectionLeaderBoard(
    foundationId = null,
    projectIds = 'all',
    fromDate = null,
    toDate = null
  ): Observable<VulnerabilityDetectionLeaderBoard[]> {
    const options = {
      foundationId,
      projectIds,
      fromDate,
      toDate,
      orderBy: 'total-issues',
      offset: 0,
      limit: 10,
      sortOrder: 'desc',
    };

    return this.checkMembership().pipe(
      switchMap(data =>
        !data
          ? of(getMockVulnerabilityDetectionLeaderBoard())
          : this.securityServiceGql.getVulnerabilityDetectionLeaderBoard(
              options
            )
      )
    );
  }

  getProjectTransitiveDependencies(projectIds = '') {
    // tslint:disable-next-line: rxjs-finnish
    const projectDependenciesSubject = new Subject();

    this.checkMembership()
      .pipe(
        switchMap(data =>
          !data
            ? of(getMockProjectTransitiveDependencies())
            : this.securityServiceGql.getProjectsTransitiveDependencies({
                projectIds,
              })
        )
      )
      .subscribe(dataSet => {
        if (dataSet) {
          const maxNumber =
            dataSet[0].dependencyCount >= 500
              ? 500
              : dataSet[0].dependencyCount;
          const projectTransitiveDependenciesMap = this.distributeProjectTransitiveDependenciesDataSet(
            maxNumber,
            5,
            dataSet
          );

          projectDependenciesSubject.next(projectTransitiveDependenciesMap);
        }
      });

    return projectDependenciesSubject.asObservable();
  }

  getProjectsDependencyStackDepthData(projectIds = '') {
    // tslint:disable-next-line: rxjs-finnish
    const projectDependenciesStackDepthSubject = new Subject();

    this.checkMembership()
      .pipe(
        switchMap(data =>
          !data
            ? of(getMockProjectsDependencyStackDepth())
            : this.securityServiceGql.getProjectsDependencyStackDepth({
                projectIds,
              })
        )
      )
      .subscribe(dataSet => {
        if (dataSet) {
          const projectsDependencyStackDepthData = this.distributeProjectsDependencyStackDepthData(
            dataSet
          );

          projectDependenciesStackDepthSubject.next(
            projectsDependencyStackDepthData
          );
        }
      });

    return projectDependenciesStackDepthSubject.asObservable();
  }

  getSecurityActivityLogs(input): Observable<ActivityLog[] | any> {
    return this.checkMembership().pipe(
      switchMap(data =>
        !data
          ? of(getMockSecurityActivityLogs())
          : this.securityServiceGql
              .getSecurityActivityLogs(input)
              .pipe(map(logs => logs || []))
      )
    );
  }

  getUniqueLicensesInStack(orderBy?, offset?, limit?, sortOrder = 'desc') {
    const options = { limit, sortOrder, orderBy, offset };

    return this.checkMembership().pipe(
      switchMap(data =>
        !data
          ? of(getMockVulnerabilityUniqueLicensesDetails())
          : this.securityServiceGql.getVulnerabilityUniqueLicensesDetails(
              options
            )
      )
    );
  }

  getProjectLanguagesDistribution(
    options: SecurityLanguagesDistributionInput = {
      sortOrder: 'desc',
      sortBy: 'language',
      offset: 0,
      limit: 0,
    }
  ) {
    return this.checkMembership().pipe(
      switchMap(data =>
        !data
          ? of(getMockSecurityLanguagesDistribution())
          : this.securityServiceGql.getSecurityLanguagesDistribution(options)
      )
    );
  }

  getVulnerabilitiesRemediationRate() {
    return this.checkMembership().pipe(
      switchMap(data =>
        !data
          ? of(getMockProjectsRemediationRateDetails())
          : this.securityServiceGql.getProjectRemediationRateDetails()
      )
    );
  }

  public getFoundationVulnerableProjects(
    input
  ): Observable<VulnerableProjectModel[]> {
    return this.checkMembership().pipe(
      switchMap(data =>
        !data
          ? of(getMockFoundationVulnerableProjects())
          : this.securityServiceGql
              .getFoundationVulnerableProjects(input)
              .pipe(map(projects => projects || []))
      )
    );
  }

  private distributeProjectTransitiveDependenciesDataSet(
    maxNumber: number,
    buckets = 5,
    data: any[]
  ): Map<string, any> {
    let max;
    let groupLiteralLow;

    if (maxNumber >= 500) {
      max = 500;
    } else {
      max = maxNumber;
    }

    const range = max / buckets;
    const rangeMap = new Map();

    for (let i = 0; i < max; i += range) {
      if (Math.ceil(i) !== i || i === 0) {
        groupLiteralLow = Math.ceil(i);
      } else {
        groupLiteralLow = Math.ceil(i) + 1;
      }

      rangeMap.set(Math.floor(range + i), {
        label: `${groupLiteralLow}-${Math.floor(range + i)}`,
        dataSet: [],
      });
    }

    rangeMap.get(max).label = `>=${max}`;

    for (const i of rangeMap.keys()) {
      for (const value of data) {
        if (
          Math.floor(value.dependencyCount / i) === 0 &&
          i - value.dependencyCount <= range
        ) {
          rangeMap.get(i).dataSet.push(value);
        } else if (value.dependencyCount > max) {
          rangeMap.get(max).dataSet.push(value);
        }
      }
    }

    rangeMap.get(max).dataSet = [...new Set(rangeMap.get(max).dataSet)];

    return rangeMap;
  }

  private distributeProjectsDependencyStackDepthData(
    data: any[]
  ): Map<string, any> {
    const bubbleMap = new Map();

    generalConstants.projectsStackDepthDataLabels.forEach(label => {
      bubbleMap.set(label, []);
    });

    for (const i of bubbleMap.keys()) {
      for (const dataItem of data) {
        if (
          i.split('-')[0] <= dataItem.maxDepth &&
          i.split('-')[1] >= dataItem.maxDepth
        ) {
          bubbleMap.get(i).push(dataItem);
        } else if (dataItem.maxDepth >= 12) {
          bubbleMap.get('>=12').push(dataItem);
        }
      }
    }

    return bubbleMap;
  }
}
