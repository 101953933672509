<div id="gritter-notice-wrapper">
  <div *ngFor="let toast of toastService.toasts">
    <ngb-toast
      [header]="toast.headertext"
      [class]="toast.classname"
      [autohide]="toast.autohide"
      [delay]="toast.delay"
      (hide)="toastService.remove(toast)">
      <div id="gritter-item-1" class="gritter-item-wrapper my-sticky-class" role="alert">
        <div class="gritter-top"></div>
        <div class="gritter-item">
          <a class="gritter-close" (click)="toastService.remove(toast)" tabindex="1" style="display: none;">Close
            Notification</a>
          <img *ngIf="toast.imageUrl" [src]="toast.imageUrl" class="gritter-image">
          <div [ngClass]="(toast.imageUrl) ?  'gritter-with-image' : 'gritter-without-image'">
            <span
              class="gritter-title notification-title-overflow">{{ toast.title | textShorten:30 }}</span>
            <p class="word-break toast-body" [innerHTML]="toast.description | textShorten:128 | sanitize"></p>
            <span>
              <a *ngIf="toast.showMore" href="javascript:;" (click)="gotoNotification(toast)">
                View More >>
              </a>
            </span>
          </div>
          <div style="clear:both"></div>
        </div>
        <div class="gritter-bottom"></div>
      </div>
    </ngb-toast>
  </div>
</div>
