/*
 * This data loads when a non-member visit the security page.
 */

export const getMockVulnerabilitiesSeverity = () => ({
  high: [0, 50, 10],
  medium: [0, 50, 10],
  low: [50, 10, 50],
});
export const getMockProjectVulnerabilityOverview = () => ({
  lastScannedAt: 0,
  openIssues: {
    high: {
      totalCount: 0,
      fixableCount: 0,
    },
    medium: {
      totalCount: 0,
      fixableCount: 0,
    },
    low: {
      totalCount: 0,
      fixableCount: 0,
    },
    total: {
      totalCount: 0,
      fixableCount: 0,
    },
  },
  overTimeIssues: [
    {
      label: 'Severity',
      total: 0,
      fixable: 0,
      severity: 'Low',
      month: 9,
      quarter: 9,
      year: 2020,
    },
  ],
});

export const getMockFoundationProjectVulnerabilityDetected = () => [
  {
    month: 10,
    severity: 'low',
    totalFixableIssue: 10,
    year: 2020,
    totalIssue: 100,
  },
  {
    month: 3,
    severity: 'medium',
    totalFixableIssue: 2,
    totalIssue: 33,
    year: 2020,
  },
  {
    month: 3,
    severity: 'high',
    totalFixableIssue: 2,
    totalIssue: 33,
    year: 2020,
  },
  {
    month: 3,
    severity: 'high',
    totalFixableIssue: 20,
    year: 2020,
    totalIssue: 100,
  },
];

export const getMockSecurityActivityLogs = () => [
  {
    id: 'a092M00001IV42DQAT',
    message:
      'https://github.com/kubernetes/kubernetes repository within  project was disabled.',
    activityType: 'change_repository_status',
    name: 'Kubernetes',
    logoUrl: null,
    createdAt: '1563319201',
  },
  {
    id: 'a092M00001IV42DQAT',
    message:
      'https://github.com/kubernetes/kubernetes repository within  project was disabled.',
    activityType: 'change_repository_status',
    name: 'Kubernetes',
    logoUrl: null,
    createdAt: '1563319201',
  },
];
export const getMockProjectTransitiveDependencies = () => [
  {
    dependencyCount: 100,
    sfdcProjectId: 'a092M00001IV42DQAT',
  },
  { dependencyCount: 400, sfdcProjectId: 'a092M00001IV42DQAT' },
  { dependencyCount: 200, sfdcProjectId: 'a092M00001IV42DQAT' },
];

export const getMockProjectsDependencyStackDepth = () => [
  { maxDepth: 200, sfdcProjectId: 'a092M00001IV42DQAT' },
  { maxDepth: 300, sfdcProjectId: 'a092M00001IV44KQAT' },
  { maxDepth: 400, sfdcProjectId: 'a092M00001IV42DQAT' },
  { maxDepth: 500, sfdcProjectId: 'a092M00001IV42DQAT' },
];

export const getMockVulnerabilityDetectionLeaderBoard = () => [
  {
    projectName: 'Fluentd',
    logoUrl: null,
    vulnerabilities: 0,
    fixed: 0,
    allVulnerabilityData: {
      externalId: '37a3f886-8f67-4614-b0cf-6499d6eef943',
      fixedHighIssues: 0,
      fixedLowIssues: 0,
      fixedMediumIssues: 0,
      openHighIssues: 2,
      openLowIssues: 1,
      openMediumIssues: 6,
      projectId: 'bc0b69ae-c667-4bde-8142-68b305aa5d59',
      sfdcProjectId: 'a092M00001IV49OQAT',
      totalFixableIssues: 7,
      totalFixedIssues: 0,
      totalHighIssues: 2,
      totalIssues: 9,
      totalLowIssues: 1,
      totalMediumIssues: 6,
      totalOpenIssues: 9,
    },
    metrics: {
      commits: 10,
      contributors: 20,
    },
  },
  {
    projectName: 'AllSeen Alliance, Inc.',
    logoUrl:
      'https://lf-master-project-logos-prod.s3.us-east-2.amazonaws.com/allseenalliance-stacked-color.svg',
    vulnerabilities: 0,
    fixed: 0,
    allVulnerabilityData: {
      externalId: '37a3f886-8f67-4614-b0cf-6499d6eef943',
      fixedHighIssues: 0,
      fixedLowIssues: 0,
      fixedMediumIssues: 0,
      openHighIssues: 2,
      openLowIssues: 1,
      openMediumIssues: 6,
      projectId: 'bc0b69ae-c667-4bde-8142-68b305aa5d59',
      sfdcProjectId: 'a092M00001IV49OQAT',
      totalFixableIssues: 7,
      totalFixedIssues: 0,
      totalHighIssues: 2,
      totalIssues: 9,
      totalLowIssues: 1,
      totalMediumIssues: 6,
      totalOpenIssues: 9,
    },
    metrics: {
      commits: 40,
      contributors: 30,
    },
  },
];

export const getMockSecurityLanguagesDistribution = () => ({
  languages: [
    {
      language: 'Vim script',
      numberOfBytes: 36,
      sfdcProjectId: 'a092M00001IV44KQAT',
      externalId: 'f9ecd0dc-51be-4efe-879a-cd07e79c9413',
    },
    {
      language: 'Smarty',
      numberOfBytes: 42,
      sfdcProjectId: 'a0941000002wBzDAAU',
      externalId: '0bb2b83f-a987-4d62-b6e3-ab7e40d755d6',
    },
    {
      language: 'Ruby',
      numberOfBytes: 46,
      sfdcProjectId: 'a092M00001IV44JQAT',
      externalId: '6a45cf94-c52c-4fda-b224-c84a414e3d94',
    },
    {
      language: 'Dockerfile',
      numberOfBytes: 160,
      sfdcProjectId: 'a092M00001IV42IQAT',
      externalId: 'e49c31f5-696f-4059-bfc8-a2f5571fe57a',
    },
    {
      language: 'Groovy',
      numberOfBytes: 170,
      sfdcProjectId: 'a0941000002wBz0AAE',
      externalId: '20a751f4-26dc-4843-bb83-81bc8e7b5e46',
    },
  ],
});

export const getMockVulnerabilitiesFixed = () => [
  {
    projectName: 'Aries',
    logoUrl: null,
    vulnerabilities: 45,
  },
  {
    projectName: 'SPDX',
    logoUrl:
      'https://lf-master-project-logos-prod.s3.us-east-2.amazonaws.com/spdx-color.svg',
    vulnerabilities: 97,
  },
  {
    projectName: 'Acumos',
    logoUrl: null,
    vulnerabilities: 128,
  },
];

export const getMockProjectsRemediationRateDetails = () => ({
  base: {
    totalFixableIssues: 200,
    totalFixedIssues: 20,
    totalIssues: 500,
    totalOpenIssues: 500,
  },
  upstream: {
    totalFixableIssues: 20000,
    totalFixedIssues: 10000,
    totalIssues: 50000,
    totalOpenIssues: 50000,
  },
});

export const getMockFoundationVulnerableProjects = () => [
  {
    colorBand: null,
    description: null,
    id: 'a0V4FMQA1',
    logoUrl: null,
    name: 'Aries',
    parent: 'a0941YAAU',
    updatedAt: 1588766720,
    vulnerability: {
      message: 'SCAN_PARTIALLY_COMPLETED',
      projectId: '98e12646cfc5',
      securityIssues: {
        high: { totalCount: 34, fixableCount: 0 },
        low: { totalCount: 4, fixableCount: 0 },
        medium: { totalCount: 9, fixableCount: 0 },
        total: { totalCount: 47, fixableCount: 2 },
      },
      sfdcProjectId: 'a092M00001IV4FMQA1',
      totalRepositories: 25,
      totalScannedRepositories: 5,
      updatedAt: 1588766720,
    },
  },
  {
    colorBand: null,
    description: null,
    id: 'a064FMQA1',
    logoUrl: null,
    name: 'Fabric',
    parent: 'a0941YAAU',
    updatedAt: 1588766720,
    vulnerability: {
      message: 'SCAN_PARTIALLY_COMPLETED',
      projectId: '98e152646cfc5',
      securityIssues: {
        high: { totalCount: 34, fixableCount: 0 },
        low: { totalCount: 4, fixableCount: 0 },
        medium: { totalCount: 9, fixableCount: 0 },
        total: { totalCount: 47, fixableCount: 2 },
      },
      sfdcProjectId: 'a092M050001IV4FMQA1',
      totalRepositories: 25,
      totalScannedRepositories: 5,
      updatedAt: 1588766720,
    },
  },
];

export const getMockVulnerabilityUniqueLicensesDetails = () => ({
  licenses: [
    {
      name: 'OTHERS',
      dependencyCount: 0,
    },
    {
      name: 'LGPL-3.0',
      dependencyCount: 0,
    },
    {
      name: 'OFL-1.1 ',
      dependencyCount: 2,
    },
    {
      name: ' CC-BY-3.0',
      dependencyCount: 2,
    },
    {
      name: 'CC-BY-4.0 ',
      dependencyCount: 2,
    },
  ],
  totalDependencies: 6,
  totalRecords: 5,
});

export const getMockVulnerabilityStatistics = () => ({
  projectScanned: 0,
  vulnerabilitiesFound: 0,
  fixesRecommended: 0,
  vulnerabilitiesFixed: 0,
  uniqueLicenses: 0,
  libraries: 10,
  totalRepositories: 10,
});

export const getMockVulnerableProjects = () => [
  {
    colorBand: null,
    description: null,
    id: 'a092M00001IV4FMQA1',
    logoUrl: null,
    name: 'Aries',
    parent: 'a0941000002wByYAAU',
    updatedAt: 1587643492,
    vulnerability: {
      message: 'SCAN_PARTIALLY_COMPLETED',
      projectId: 'f6d7b138-4860-4fca-ae49-98e12646cfc5',
      securityIssues: {
        high: { totalCount: 18, fixableCount: 17 },
        low: { totalCount: 4, fixableCount: 3 },
        medium: { totalCount: 11, fixableCount: 8 },
        total: { totalCount: 33, fixableCount: 28 },
      },
      sfdcProjectId: 'a092M00001IV43LQAT',
      totalRepositories: 19,
      totalScannedRepositories: 11,
      updatedAt: 1587557167,
    },
  },
];
