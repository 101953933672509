import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Membership,
  MembershipContacts,
  GetMembershipContactsQueryParams,
} from '@models';
import { MembershipServiceGql } from '../gql';
import { MembershipFilters } from '@lfx/config';

@Injectable({
  providedIn: 'root',
})
export class MembershipService {
  constructor(private membershipServiceGql: MembershipServiceGql) {}

  getMyMemberships(
    loadMainDataOnly?: boolean,
    filters?: MembershipFilters
  ): Observable<Membership[]> {
    // loadMainDataOnly, skips all the data returned excluding the name, status, endDate, id and createdAt
    return this.membershipServiceGql.getMyMemberships(
      loadMainDataOnly,
      filters
    );
  }

  getMembershipContacts(
    membershipId: string,
    queryParams?: GetMembershipContactsQueryParams,
    useCache?: boolean
  ): Observable<MembershipContacts> {
    return this.membershipServiceGql.getMembershipContacts(
      membershipId,
      {
        ...queryParams,
      },
      useCache
    );
  }

  getMembershipHistory(accountId: string): Observable<Membership[]> {
    return this.membershipServiceGql.getMembershipHistory(accountId);
  }

  createMembershipContact(input: any) {
    return this.membershipServiceGql.createMembershipContact(input);
  }

  updateMembershipContact(contact: any) {
    return this.membershipServiceGql.updateContact(contact);
  }

  deleteMembershipContact(contact) {
    return this.membershipServiceGql.deleteMembershipContact(contact);
  }
}
