import gql from 'graphql-tag';

export const myOrganizationQuery = gql`
  query myOrganization(
    $withAdmins: Boolean = false
    $withServiceUsers: Boolean = false
    $withInvites: Boolean = false
    $withOrganizationFoundations: Boolean = false
    $withOrganizationCommitteeFoundations: Boolean = false
  ) {
    myOrganization {
      id
      description
      billingAddress {
        billingCity
        billingCountry
        billingPostalCode
        billingState
        billingStreet
      }
      github
      gitlab
      industry
      logoUrl
      name
      phone
      source
      type
      website
      createdAt
      employees
      lastModifiedAt
      lastModifiedBy
      link
      owner {
        id
        name
      }
      ownerId
      admins @include(if: $withAdmins) {
        id
        name
        email
        logoUrl
        roleId
        scopeId
        roleName
        isAppointed
      }
      invites @include(if: $withInvites) {
        inviteId
        email
        firstName
        lastName
        roleName
        scope
        scopeId
        status
        expired
      }
      serviceUsers @include(if: $withServiceUsers) {
        id
        username
        email
        name
        avatar
        roles {
          roleId
          name
          projectName
          foundationName
          scopeId
          scopeTypeId
          projectId
          foundationId
        }
      }
      foundations @include(if: $withOrganizationFoundations) {
        id
        name
      }
      committeeFoundations @include(if: $withOrganizationCommitteeFoundations) {
        id
        name
        logoUrl
      }
    }
  }
`;
