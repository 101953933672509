/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  contextPriority,
  generalConstants,
  MenuItem,
  pageMenus,
  pageSettings,
} from '@config';
import {
  Company,
  Foundation,
  FoundationProject,
  Profile,
  ProjectType,
  UserContext,
} from '@models';
import {
  CompanyService,
  FeatureFlagManagerService,
  FoundationService,
  PermissionService,
  StorageService,
  UserService,
} from '@services';
import { NgxPermissionsObject } from 'ngx-permissions';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, Observable, of } from 'rxjs';
import { environment } from '@environment';
import { filter, flatMap, map, share, switchMap, take } from 'rxjs/operators';
import {
  AutoCompleteActionEventType,
  AutoCompleteSearchInputActionEvent,
} from '@lfx/shared/interfaces';
import { isEmpty } from 'lodash';

export const storedContextList = generalConstants.currentContext;

@Component({
  selector: 'lfx-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  // encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('expandCollapse', [
      state(
        'expand',
        style({ height: '*', overflow: 'hidden', display: 'block' })
      ),
      state(
        'collapse',
        style({ height: '0px', overflow: 'hidden', display: 'block' })
      ),
      transition('expand <=> collapse', animate(100)),
    ]),
  ],
})
export class SidebarComponent implements OnInit, AfterViewChecked {
  @Input() profile: Profile;
  @Input() isLoggedIn: boolean;
  @Input() pageSidebarTransparent;
  @Input() pageSidebarMinified;
  @Output() hideMobileSidebar = new EventEmitter<boolean>();
  navProfileState = 'collapse';
  menus = pageMenus;
  currentContext: UserContext = null;
  currentContexts: UserContext[] = [];
  contextImageUrl: string;
  contextName: string;
  pageSettings = pageSettings;
  minified = false;
  mobileMode: boolean;
  desktopMode: boolean;
  scrollTop: number;
  myCompany$: Observable<Company>;
  permissions: NgxPermissionsObject;
  userContextEnum = UserContext;
  profileUrl = environment.profileManagementUrl;
  isCollapsed = true;
  individualNoAccount = generalConstants.individualNoAccount;

  @ViewChild('sidebarScrollbar', { static: false })
  private sidebarScrollbar: ElementRef;

  private autoCompleteResultsObservables: {
    [key: string]: Observable<any>;
  } = {};

  private standAloneProjectFoundationId: string;
  private isLoading: { [key: string]: boolean } = {};
  private currentProjectId: string;

  constructor(
    private eRef: ElementRef,
    private storageService: StorageService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private permissionManager: PermissionService,
    private featureFlagManagerService: FeatureFlagManagerService,
    private companyService: CompanyService,
    private foundationService: FoundationService,
    private route: ActivatedRoute
  ) {
    if (window.innerWidth <= 767) {
      this.mobileMode = true;
      this.desktopMode = false;
    } else {
      this.mobileMode = false;
      this.desktopMode = true;
    }

    this.permissionManager.getPermissions().subscribe(perm => {
      this.permissions = perm;
    });
  }

  ngOnInit() {
    this.init();
  }

  ngAfterViewChecked() {
    if (typeof Storage !== 'undefined' && localStorage.sidebarScroll) {
      if (this.sidebarScrollbar && this.sidebarScrollbar.nativeElement) {
        this.sidebarScrollbar.nativeElement.scrollTop =
          localStorage.sidebarScroll;
      }
    }
  }

  toggleSubmenu(currentMenu, active) {
    if (!currentMenu.state) {
      currentMenu.state = 'expand';
    } else if (currentMenu.state === 'collapse') {
      currentMenu.state = 'expand';
    } else {
      currentMenu.state = 'collapse';
    }
  }

  expandCollapse(currentMenu, active) {
    if (active.isActive) {
      setTimeout(() => {
        currentMenu.state = 'expand';
      });

      return 'expand';
    } else {
      return 'collapse';
    }
  }

  onAutoCompleteSearchInput(
    autoCompletePayload: AutoCompleteSearchInputActionEvent,
    menuItem: MenuItem
  ) {
    switch (menuItem.id) {
      case 'projects':
        this.handleProjectsAutoCompleteSearchInput(
          autoCompletePayload,
          menuItem
        );

        return;
    }
  }

  getAutoCompleteSearchResultObservable(menuItem: MenuItem) {
    if (!this.autoCompleteResultsObservables.hasOwnProperty(menuItem.title)) {
      this.autoCompleteResultsObservables[menuItem.title] = null;
    }

    return this.autoCompleteResultsObservables[menuItem.title];
  }

  autoCompleteSelection(event, menuItem: MenuItem) {
    switch (menuItem.id) {
      case 'projects':
        this.handleProjectAutoCompleteSelection(event, menuItem);

        return;
    }
  }

  updateProjectMenu(projectId: string) {
    const menuItemIndex = this.getMenuIndexByName('Projects');
    const menuItem = this.menus[menuItemIndex];

    this.getAndInitProjectMenu(projectId, menuItem);
  }

  checkIsLoading(menu: MenuItem) {
    return this.isLoading[menu.title] || false;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.hideMobileSidebar.emit(true);
    }
  }

  @HostListener('scroll', ['$event'])
  onScroll(event) {
    this.scrollTop = this.pageSettings.pageSidebarMinified
      ? event.srcElement.scrollTop + 40
      : 0;

    if (typeof Storage !== 'undefined') {
      localStorage.setItem('sidebarScroll', event.srcElement.scrollTop);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth <= 767) {
      this.mobileMode = true;
      this.desktopMode = false;
    } else {
      this.mobileMode = false;
      this.desktopMode = true;
    }
  }

  private init() {
    /** spinner starts on init */
    this.spinner.show();
    this.myCompany$ = this.companyService.getMyCompany().pipe(share());
    this.foundationService.getStandAloneProjectsLinuxFoundationId().subscribe(
      foundationId => {
        this.standAloneProjectFoundationId = foundationId;
      },
      () => {}
    );
    this.addRouteListener();

    // Set context if one is not defined yet
    if (!!!this.currentContext) {
      // Get all user contexts based on permissions
      // Filter to the ones actually available (based on flags)
      const userContexts$ = this.userService.getUserContexts().pipe(
        flatMap(contexts => {
          const flags = this.featureFlagManagerService.flags;

          if (!!flags) {
            return of(contexts.filter(c => !!flags[`${c}-context`]));
          } else {
            return this.featureFlagManagerService.flagChange.pipe(
              map(newFlags => contexts.filter(c => !!newFlags[`${c}-context`]))
            );
          }
        })
      );

      // Get the last context used from localStorage
      const storedContext$ = this.storageService.getItem<UserContext[]>(
        storedContextList
      );

      forkJoin(userContexts$, storedContext$).subscribe(responses => {
        const userContexts = responses[0];
        const storedContext = !!responses[1] ? responses[1][0] : null;
        const defaultContext = contextPriority.find(c =>
          userContexts.includes(c)
        );

        // eslint-disable-next-line no-console
        console.debug(
          '(contexts)',
          `stored:${storedContext}`,
          `default:${defaultContext}`,
          `available:${userContexts}`
        );

        // Is the stored context still available?
        if (!!storedContext && userContexts.includes(storedContext)) {
          // Stored context is still good
          this.changeContext(storedContext);
          this.spinner.hide();
        } else if (!!defaultContext) {
          // Use the default context based on priority
          this.changeContext(defaultContext);
          this.spinner.hide();
        } else {
          // TODO: What if no context is available?
          // eslint-disable-next-line no-console
          console.error('(contexts)', 'No available contexts for user');
          this.router.navigate([generalConstants.routerForRedirectForbidden]);
        }
      });
    }
  }

  private handleProjectsAutoCompleteSearchInput(
    autoCompletePayload: AutoCompleteSearchInputActionEvent,
    menuItem: MenuItem
  ) {
    const { text, type } = autoCompletePayload;

    if (type === AutoCompleteActionEventType.focus || !text || !text.length) {
      this.autoCompleteResultsObservables[menuItem.title] = of([]);

      return;
    }

    this.autoCompleteResultsObservables[menuItem.title] = this.foundationService
      .searchFoundation({
        name: [text],
      })
      .pipe(
        map(projects =>
          projects.filter(
            project =>
              project.name !==
              generalConstants.standAloneProjectLinuxFoundationName
          )
        )
      );
  }

  private handleProjectAutoCompleteSelection(event, menuItem: MenuItem) {
    const selectedProject: Foundation = event && event.item;

    if (!selectedProject) {
      return;
    }
    this.getAndInitProjectMenu(selectedProject.id, menuItem);
  }

  private getAndInitProjectMenu(projectId: string, menuItem: MenuItem) {
    this.startLoading(menuItem);
    this.foundationService
      .getFoundation(projectId, false, true)
      .pipe(take(1))
      .subscribe(
        project => {
          this.currentProjectId = projectId;
          this.stopLoading(menuItem);
          this.constructProjectMenu(project, menuItem);
        },
        () => this.stopLoading(menuItem)
      );
  }

  private changeContext(context: UserContext) {
    // eslint-disable-next-line no-console
    console.debug('(contexts)', `${this.currentContext} --> ${context}`);
    this.currentContext = context;
    this.currentContexts[0] = context;
    this.storageService.setItem(storedContextList, this.currentContexts);
    this.menus = pageMenus.filter(
      menu => !menu.context || menu.context === context
    );
  }

  private constructProjectMenu(project: Foundation, menuItem: MenuItem) {
    const projectType = this.foundationService.getProjectType(project);

    if (projectType === ProjectType.project) {
      this.constructProjectMenuProjectType(project, menuItem);
    } else {
      this.constructProjectMenuProjectGroupStandAloneType(project, menuItem);
    }
  }

  private constructProjectMenuProjectType(
    project: Foundation,
    menuItem: MenuItem
  ) {
    let menu: MenuItem = {
      title: project.name,
      caret: true,
      state: 'expand',
      submenu: this.constructProjectMenuLinks(project),
    };

    this.startLoading(menuItem);
    this.foundationService
      .getFoundation(project.foundation.id, false, true)
      .pipe(take(1))
      .subscribe(
        parent => {
          this.stopLoading(menuItem);
          menu = {
            id: parent.id,
            updateMenu: true,
            title: parent.name,
            state: 'expand',
            submenu: [menu],
            url: this.getDefaultMenuLink(parent),
            hoverList: {
              text: `${parent.projects.length}`,
              list: {
                parent: {
                  id: parent.id,
                  title: parent.name,
                  url: this.getDefaultMenuLink(parent),
                },
                list: this.constructToolTipProjectArray(
                  project,
                  parent.projects
                ),
              },
            },
          };
          menuItem.submenu = [menu];
        },
        () => this.stopLoading(menuItem)
      );
  }

  private constructProjectMenuProjectGroupStandAloneType(
    project: Foundation,
    menuItem: MenuItem
  ) {
    const menu: MenuItem = {
      title: project.name,
      caret: true,
      state: 'expand',
      submenu: this.constructProjectMenuLinks(project),
    };

    if (project.projects && !isEmpty(project.projects)) {
      menu.hoverList = {
        text: `${project.projects.length}`,
        list: {
          parent: {
            id: project.id,
            title: project.name,
            url: this.getDefaultMenuLink(project),
          },
          list: this.constructToolTipProjectArray(project, project.projects),
        },
      };
      menu.caret = false;
    }
    menuItem.submenu = [menu];
  }

  private constructProjectMenuLinks(project: Foundation) {
    return [
      {
        icon: 'fal fa-file-contract',
        title: 'EasyCLA',
        url: this.getDefaultMenuLink(project),
      },
    ];
  }

  private getDefaultMenuLink(project: Foundation) {
    return this.getMenuLinkUrl(project, 'EasyCla');
  }

  private getMenuLinkUrl(project: Foundation, type: string) {
    const baseLink = this.getMenuLinkUrlBase(project);

    switch (type) {
      case 'EasyCla':
        return `${baseLink}/cla`;
    }
  }

  private getMenuLinkUrlBase(project: Foundation) {
    const projectType = this.foundationService.getProjectType(project);

    switch (projectType) {
      case ProjectType.projectGroup:
      case ProjectType.standAloneProjectGroup:
        return `foundation/${project.id}`;
      case ProjectType.project:
        return `foundation/${
          project.foundation && project.foundation.id
        }/project/${project.id}`;
      case ProjectType.standAloneProject:
        return `foundation/${
          (project.foundation && project.foundation.id) ||
          this.standAloneProjectFoundationId
        }/project/${project.id}`;
    }
  }

  private getMenuIndexByName(name) {
    for (let index = 0; index < this.menus.length; index++) {
      if (this.menus[index].title === name) {
        return index;
      }
    }

    return -1;
  }

  private constructToolTipProjectArray(
    project: Foundation,
    projects: FoundationProject[]
  ) {
    const orderedProjects = projects
      .sort((a, b) => a.name.localeCompare(b.name))
      .reduce((previous, item) => {
        if (item.id === project.id) {
          previous.unshift(item);
        } else {
          previous.push(item);
        }

        return previous;
      }, []);

    return orderedProjects.map(project => ({
      id: project.id,
      title: project.name,
      url: this.getDefaultMenuLink(project),
    }));
  }

  private addRouteListener() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.route.root),
        switchMap(firstChild =>
          firstChild ? of(this.getRouteRootLastChild(firstChild)) : of(null)
        ),
        filter(route => route.snapshot.data.updateSideNavProject || false),
        filter(route => route.snapshot.params.id || false),
        filter(route => route.snapshot.params.id !== this.currentProjectId)
      )
      .subscribe(route => {
        this.updateProjectMenu(route.snapshot.params.id);
      });
  }

  private getRouteRootLastChild(root: ActivatedRoute) {
    return !root.firstChild
      ? root
      : this.getRouteRootLastChild(root.firstChild);
  }

  private startLoading(menu: MenuItem) {
    this.isLoading[menu.title] = true;
    this.spinner.show(`menu-${menu.title}-spinner`);
  }

  private stopLoading(menu: MenuItem) {
    this.isLoading[menu.title] = false;
  }
}
