import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import {
  ClaInsights,
  ActiveClaProject,
  NewClaManager,
  FoundationProject,
  CompanyClaManagers,
  CompanyProjectMetrics,
  ClaGroupsForProject,
  RemoveClaManager,
  ApprovedContributor,
  FoundationLevelCla,
  ContributorAcknowledgements,
  ListIndividualSignaturesForCLAGroupQueryParms,
  ListIndividualSignaturesForCLAGroupResponse,
  UpdateAutoCreateEclaFlagInput,
  UpdateAutoCreateEclaFlagResponse,
  MutationResponse,
  ListClaGroupCorporateContributorsQueryParms,
  ListClaGroupCorporateContributorsResponse,
} from '@models';
import {
  ClaServiceGql,
  AssignCLAManagerDesigneeInput,
  RequestAddCLAManagerDesigneeInput,
} from '../gql/services';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class ClaService {
  contributorsAcknowledgmentRefresherEventSource = new Subject<boolean>();
  contributorsAcknowledgmentRefresherEvent$ = this.contributorsAcknowledgmentRefresherEventSource.asObservable();

  clagroupIdEventSource = new Subject<string>();
  clagroupIdEvent$ = this.clagroupIdEventSource.asObservable();
  clagroupId: string;

  constructor(private claServiceGql: ClaServiceGql) {}

  emitContributorAcknowledgementRefresherEvent(event: boolean) {
    this.contributorsAcknowledgmentRefresherEventSource.next(event);
  }

  emitClagroupIdEvent(clagroupId: string) {
    this.clagroupIdEventSource.next(clagroupId);
    this.clagroupId = clagroupId;
  }

  getAutoCreateECLAStatus(
    companyId: string,
    projectId: string
  ): Observable<boolean> {
    return this.getApprovedContributors(companyId, projectId).pipe(
      map(contributor =>
        contributor.some(contr => contr.autoCreateECLA === true)
      )
    );
  }

  getClaInsights(): Observable<ClaInsights> {
    return this.claServiceGql.getClaInsights();
  }

  getActiveClaList(
    organizationId: string,
    projectId: string
  ): Observable<ActiveClaProject[]> {
    return this.claServiceGql.getActiveClaList(organizationId, projectId);
  }

  getClaGroupsForProjectList(
    organizationId: string,
    projectId: string,
    organizationInternalId: string,
    noCache?: boolean
  ): Observable<ClaGroupsForProject[]> {
    return this.claServiceGql
      .getClaGroupsForProjectList(
        organizationId,
        projectId,
        organizationInternalId,
        noCache
      )
      .pipe(
        map(list => {
          if (list === null) {
            return [
              {
                signedClaList: [],
                unsignedProjectList: [],
              },
            ];
          }

          return list;
        })
      );
  }

  getCompanyClaManagers(
    companySFID: string,
    projectSFID: string,
    withProjectLogoUrl: boolean
  ): Observable<CompanyClaManagers[]> {
    return this.claServiceGql.getCompanyClaManagers(
      companySFID,
      projectSFID,
      withProjectLogoUrl
    );
  }

  addNewClaManager(newManager: NewClaManager) {
    return this.claServiceGql.addNewClaManager(newManager);
  }

  removeClaManager(claManagerOptions: RemoveClaManager) {
    return this.claServiceGql.removeClaManager(claManagerOptions);
  }

  getApprovedContributors(
    companyId: string,
    projectId: string
  ): Observable<ApprovedContributor[]> {
    return this.claServiceGql.getApprovedContributors(companyId, projectId);
  }

  getClaEnabledProjects(id: string): Observable<FoundationProject[]> {
    return this.claServiceGql
      .getClaEnabledProjects(id)
      .pipe(map(projects => projects || []));
  }

  listCompanyProjectMetrics(
    companyId: string,
    projectId: string
  ): Observable<CompanyProjectMetrics[]> {
    return this.claServiceGql.listCompanyProjectMetrics(companyId, projectId);
  }

  getContributorAcknowledgements(input: {
    projectId: string;
    companyId: string;
    pageSize?: number;
    nextKey?: string;
    searchTerm?: string;
  }): Observable<ContributorAcknowledgements> {
    return this.claServiceGql.getContributorAcknowledgements(input);
  }

  getRequestDocumentSignature(input): Observable<any> {
    return this.claServiceGql.getRequestDocumentSignature(input);
  }

  getAssignCLAManagerDesignee(
    input: AssignCLAManagerDesigneeInput
  ): Observable<any> {
    return this.claServiceGql.getAssignCLAManagerDesignee(input);
  }

  requestAddCLAManagerDesignee(
    input: RequestAddCLAManagerDesigneeInput
  ): Observable<any> {
    return this.claServiceGql.requestAddCLAManagerDesignee(input);
  }

  validUrl(row): Observable<any> {
    const request = new XMLHttpRequest();

    request.timeout = 15000;
    request.open('GET', row.download);
    request.send();

    return of(request);
  }

  getFoundationCLAGroups(foundationId: string): Observable<any> {
    return this.claServiceGql.getFoundationCLAGroups({ foundationId });
  }

  updateApprovalList(data: any): Observable<any> {
    return this.claServiceGql.updateApprovalList(data);
  }

  getFoundationLevelCLA(foundationId: string): Observable<FoundationLevelCla> {
    return this.claServiceGql.getFoundationLevelCLA(foundationId);
  }

  listIndividualSignaturesForCLAGroup(
    claGroupID: string,
    queryParams: ListIndividualSignaturesForCLAGroupQueryParms
  ): Observable<ListIndividualSignaturesForCLAGroupResponse> {
    return this.claServiceGql.listIndividualSignaturesForCLAGroup(
      claGroupID,
      queryParams
    );
  }

  listClaGroupCorporateContributors(
    claGroupID: string,
    queryParams: ListClaGroupCorporateContributorsQueryParms
  ): Observable<ListClaGroupCorporateContributorsResponse> {
    return this.claServiceGql.listClaGroupCorporateContributors(
      claGroupID,
      queryParams
    );
  }

  updateAutoCreateEclaFlag({
    autoCreateEcla,
    claGroupID,
    companyID,
  }: UpdateAutoCreateEclaFlagInput): Observable<
    MutationResponse<UpdateAutoCreateEclaFlagResponse>
  > {
    return this.claServiceGql.updateAutoCreateEclaFlag({
      autoCreateEcla,
      claGroupID,
      companyID,
    });
  }
}
