import gql from 'graphql-tag';

export const checkDomainAvailabiltyQuery = gql`
  query domainAvailability($domainName: String!) {
    domainAvailability(domainName: $domainName) {
      action
      available
      domain
      premium
      price
    }
  }
`;
