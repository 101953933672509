import gql from 'graphql-tag';

export const repositoriesLicensesQuery = gql`
  query vulnerabilityLicensesDetails(
    $input: VulnerabilityLicensesDetailsInput!
  ) {
    vulnerabilityLicensesDetails(input: $input) {
      licenseName
      dependencyCount
      repositoryId
      repositoryName
      dependencies {
        dependencyId
        dependencyName
        dependencyVersion
        repositoryId
        repositoryName
      }
    }
  }
`;
