import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toasts: any[] = [];

  // Push new Toasts to array with content and options
  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  notification(
    title: string,
    description: string,
    id: number,
    imageUrl = '',
    showMore
  ) {
    const toastLoad = {
      id,
      delay: 10000,
      autohide: true,
      title,
      description,
      imageUrl,
      notificationType: 'no-action',
      showMore,
    };

    this.toasts.push(toastLoad);
  }

  // Callback method to remove Toast DOM element from view
  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
}
