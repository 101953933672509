import gql from 'graphql-tag';

export const securityProjectListQuery = gql`
  query rootSecurityProjectList {
    rootSecurityProjectList {
      id
      name
      description
      logoUrl
      colorBand
      projectType
      parent
      projects {
        id
        name
        description
        logoUrl
        colorBand
        projectType
        parent
      }
      securitySummary {
        projectId
        sfdcProjectId
        message
        updatedAt
        securityIssues {
          high {
            totalCount
            fixableCount
          }
          medium {
            totalCount
            fixableCount
          }
          low {
            totalCount
            fixableCount
          }
          total {
            totalCount
            fixableCount
          }
        }
      }
    }
  }
`;
