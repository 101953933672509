/* eslint-disable @typescript-eslint/camelcase */
export const environment = {
  production: false,
  log: true,
  featureFlags: true,
  auth0: {
    domain: 'linuxfoundation-ENV.auth0.com',
    audience: 'https://linuxfoundation-ENV.auth0.com/api/v2/',
    client_id: 'TO_BE_PROVIDED',
    redirect_uri: `${window.location.origin}/auth`,
    responseType: 'token id_token',
  },
  auth0Social: {
    domain: 'linuxfoundation-ENV.auth0.com',
    audience: 'https://linuxfoundation-ENV.auth0.com/api/v2/',
    client_id: 'TO_BE_PROVIDED',
    redirect_uri: `${window.location.origin}/user/account-settings`,
    responseType: 'token id_token',
    scope: 'openid profile email',
  },
  lfxHeaderUrl: 'https://cdn.dev.platform.linuxfoundation.org/lfx-header-v2.js',
  ldClientKey: 'TO_BE_PROVIDED',
  apiUrl: 'http://localhost:8080/api',
  graphqlUrl: 'http://localhost:5000/graphql',
  joinFoundationUrl:
    'https://joinnow.ENV.platform.linuxfoundation.org/?app=lfx&project=',
  cclaUrl: 'https://joinnow.ENV.platform.linuxfoundation.org/?app=lfx&project=',
  gtmId: null,
  profileManagementUrl: 'https://myprofile.dev.platform.linuxfoundation.org/',
};
