import gql from 'graphql-tag';

export const createNewFoundationMutation = gql`
  mutation createNewFoundation($foundationInfo: CreateNewFoundationInput!) {
    createNewFoundation(input: $foundationInfo) {
      code
      message
      success
      newFoundation {
        id
      }
    }
  }
`;
