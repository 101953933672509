import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import {
  allFoundationsQuery,
  foundationQuery,
  foundationMembershipsQuery,
  foundationCommitteesQuery,
  foundationForStatisticsQuery,
  foundationDomainsQuery,
  myFoundationsQuery,
  suggestedFoundationsQuery,
  foundationMeetingsQuery,
  foundationResourcesQuery,
  foundationStatisticsQuery,
  nonMemberFoundationsQuery,
  foundationActivityLogsQuery,
  claEnabledFoundationsQuery,
  searchFoundationsQuery,
} from '../queries';
import {
  FoundationResult,
  FoundationDomainsResult,
  AllFoundationsResult,
  CreateNewFoundationPayload,
  CreateNewFoundationResult,
  UpdateFoundationFinanceResult,
  UpdateFoundationEssentialsResult,
  UpdateFoundationLegalPayload,
  UpdateFoundationLegalResult,
  FoundationCommitteesResult,
  CreateFoundationCommitteesResult,
  UpdateFoundationCommitteeResult,
  DeleteFoundationCommitteeResult,
  MyFoundationsResult,
  SuggestedFoundationsResult,
  FoundationMeetingResult,
  FoundationResourceResult,
  FoundationStatisticsResult,
  NonMemberFoundationsResult,
  FoundationActivityLogResult,
  SearchFoundationsResult,
} from './results';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Foundation,
  FoundationDomain,
  FoundationFinance,
  UserGroup,
  FoundationMeeting,
  Resource,
  Statistics,
  ActivityLog,
} from '@models';
import {
  UpdateFoundationFinanceInput,
  UpdateFoundationLegalInput,
  UpdateFoundationEssentialsInput,
  CreateNewFoundationInput,
  CreateFoundationCommitteesInput,
  UpdateCommitteeInput,
  DeleteCommitteeInput,
  FoundationActivityLogsInput,
  SearchFoundationsInput,
} from './inputs';
import {
  updateFoundationFinanceMutation,
  updateFoundationLegalMutation,
  updateFoundationEssentialsMutation,
  createNewFoundationMutation,
  createFoundationCommitteesMutation,
  updateFoundationCommitteeMutation,
  deleteFoundationCommitteeMutation,
  uploadFileMutation,
  removeFileMutation,
} from '../mutations';

@Injectable({
  providedIn: 'root',
})
export class FoundationServiceGql {
  private StatisticsQuery: QueryRef<FoundationResult> = null;

  constructor(private apollo: Apollo) {}

  getAllFoundations(withMembersData = false): Observable<Foundation[]> {
    return this.apollo
      .watchQuery<AllFoundationsResult>({
        query: allFoundationsQuery,
        variables: {
          withMembersData,
        },
      })
      .valueChanges.pipe(map(res => res.data.foundations));
  }

  claEnabledFoundations(): Observable<Foundation[]> {
    return this.apollo
      .query<{ claEnabledFoundations: Foundation[] }>({
        query: claEnabledFoundationsQuery,
      })
      .pipe(map(res => res.data.claEnabledFoundations));
  }

  getFoundation(
    id: string,
    withAdminData = false,
    withProjectsData = false,
    withMembersData = false,
    withContributionsData = false,
    communityYearEvent = 'all',
    organizationYearEvent = 'all'
  ): Observable<Foundation> {
    return this.apollo
      .watchQuery<FoundationResult>({
        query: foundationQuery,
        variables: {
          id,
          withAdminData,
          withProjectsData,
          withMembersData,
          withContributionsData,
          communityYearEvent,
          organizationYearEvent,
        },
      })
      .valueChanges.pipe(map(res => res.data.foundation));
  }

  getFoundationForStatistics(id, filter) {
    if (!this.StatisticsQuery) {
      this.StatisticsQuery = this.getFoundationForStatisticsQuery(id, filter);
    } else {
      this.StatisticsQuery.setVariables({
        id,
        filter,
      });
      this.StatisticsQuery.refetch();
    }

    return this.StatisticsQuery.valueChanges.pipe(
      map(res => res.data.foundation)
    );
  }

  getFoundationMemberShips(id: string): Observable<Foundation> {
    return this.apollo
      .watchQuery<FoundationResult>({
        query: foundationMembershipsQuery,
        variables: {
          id,
        },
      })
      .valueChanges.pipe(map(res => res.data.foundation));
  }

  getFoundationDomains(foundationId: string): Observable<FoundationDomain[]> {
    return this.apollo
      .watchQuery<FoundationDomainsResult>({
        query: foundationDomainsQuery,
        variables: {
          foundationId,
        },
      })
      .valueChanges.pipe(map(res => res.data.foundationDomains));
  }

  getFoundationMeetings(id: string): Observable<FoundationMeeting> {
    return this.apollo
      .watchQuery<FoundationMeetingResult>({
        query: foundationMeetingsQuery,
        variables: {
          id,
        },
      })
      .valueChanges.pipe(map(res => res.data.foundationMeeting));
  }

  getFoundationResources(id: string): Observable<Resource[]> {
    return this.apollo
      .watchQuery<FoundationResourceResult>({
        query: foundationResourcesQuery,
        variables: {
          id,
        },
      })
      .valueChanges.pipe(map(res => res.data.foundationResource));
  }

  getFoundationStatistics(id: string): Observable<Statistics> {
    return this.apollo
      .watchQuery<FoundationStatisticsResult>({
        query: foundationStatisticsQuery,
        variables: {
          id,
        },
      })
      .valueChanges.pipe(map(res => res.data.foundationStatistics));
  }

  updateFoundationEssential(
    foundationEssential: UpdateFoundationEssentialsInput
  ): Observable<Foundation> {
    return this.apollo
      .mutate<UpdateFoundationEssentialsResult>({
        mutation: updateFoundationEssentialsMutation,
        variables: {
          foundationEssential,
        },
      })
      .pipe(map(res => res.data.updateFoundationEssentials.currentFoundation));
  }

  updateFoundationFinance(
    financeInfo: UpdateFoundationFinanceInput
  ): Observable<FoundationFinance> {
    return this.apollo
      .mutate<UpdateFoundationFinanceResult>({
        mutation: updateFoundationFinanceMutation,
        variables: {
          financeInfo,
        },
      })
      .pipe(map(res => res.data.updateFoundationFinance.foundationFinance));
  }

  creatNewFoundation(
    foundationInfo: CreateNewFoundationInput
  ): Observable<CreateNewFoundationPayload> {
    return this.apollo
      .mutate<CreateNewFoundationResult>({
        mutation: createNewFoundationMutation,
        variables: {
          foundationInfo,
        },
      })
      .pipe(map(res => res.data.createNewFoundation));
  }

  getFoundationCommittees(id: string): Observable<UserGroup[]> {
    return this.apollo
      .watchQuery<FoundationCommitteesResult>({
        query: foundationCommitteesQuery,
        variables: {
          id,
        },
      })
      .valueChanges.pipe(map(res => res.data.foundation.committees));
  }

  createFoundationCommittees(
    committeesInfo: CreateFoundationCommitteesInput
  ): Observable<UserGroup[]> {
    return this.apollo
      .mutate<CreateFoundationCommitteesResult>({
        mutation: createFoundationCommitteesMutation,
        variables: {
          committeesInfo,
        },
      })
      .pipe(map(res => res.data.createCommittees.committees));
  }

  updateFoundationCommittee(
    committeeInfo: UpdateCommitteeInput
  ): Observable<UserGroup> {
    return this.apollo
      .mutate<UpdateFoundationCommitteeResult>({
        mutation: updateFoundationCommitteeMutation,
        variables: {
          committeeInfo,
        },
      })
      .pipe(map(res => res.data.updateCommittee.committee));
  }

  deleteFoundationCommittee(
    committeeInfo: DeleteCommitteeInput
  ): Observable<boolean> {
    return this.apollo
      .mutate<DeleteFoundationCommitteeResult>({
        mutation: deleteFoundationCommitteeMutation,
        variables: {
          committeeInfo,
        },
      })
      .pipe(map(res => res.data.deleteCommittee._empty));
  }

  updateFoundationLegal(
    legalInfo: UpdateFoundationLegalInput
  ): Observable<UpdateFoundationLegalPayload> {
    return this.apollo
      .mutate<UpdateFoundationLegalResult>({
        mutation: updateFoundationLegalMutation,
        variables: {
          legalInfo,
        },
      })
      .pipe(map(res => res.data.updateFoundationLegal));
  }

  // Todo Change the types form `any` to the appropriate type.
  uploadContractDoc(file: File): Observable<any> {
    return this.apollo
      .mutate<any>({
        mutation: uploadFileMutation,
        variables: { file },
        context: { useMultipart: true, hasUpload: true },
      })
      .pipe(map(res => res.data.uploadContractDoc));
  }

  removeUploadedContractDoc(key: string): Observable<any> {
    return this.apollo
      .mutate({
        mutation: removeFileMutation,
        variables: { key },
      })
      .pipe(map(res => res.data));
  }

  getMyFoundations(
    withMembersData = true,
    withProjectsData = true,
    withProgramManagerData = false
  ): Observable<Foundation[]> {
    return this.apollo
      .watchQuery<MyFoundationsResult>({
        query: myFoundationsQuery,
        variables: {
          withMembersData,
          withProjectsData,
          withProgramManagerData,
        },
      })
      .valueChanges.pipe(map(res => res.data.myFoundations));
  }

  getSuggestedFoundations(): Observable<Foundation[]> {
    return this.apollo
      .watchQuery<SuggestedFoundationsResult>({
        query: suggestedFoundationsQuery,
      })
      .valueChanges.pipe(map(res => res.data.suggestedFoundations));
  }

  getNonMemberFoundations(
    withMembersData = true,
    withProjectsData = true
  ): Observable<Foundation[]> {
    return this.apollo
      .watchQuery<NonMemberFoundationsResult>({
        query: nonMemberFoundationsQuery,
        variables: {
          withMembersData,
          withProjectsData,
        },
      })
      .valueChanges.pipe(map(res => res.data.nonMemberFoundations));
  }

  getFoundationActivityLogs(
    input: FoundationActivityLogsInput
  ): Observable<ActivityLog[]> {
    return this.apollo
      .watchQuery<FoundationActivityLogResult>({
        query: foundationActivityLogsQuery,
        variables: {
          input,
        },
      })
      .valueChanges.pipe(map(res => res.data.claActivityLogs));
  }

  searchFoundations(
    searchParams?: SearchFoundationsInput
  ): Observable<Foundation[]> {
    return this.apollo
      .watchQuery<SearchFoundationsResult>({
        query: searchFoundationsQuery,
        variables: {
          searchParams,
        },
      })
      .valueChanges.pipe(map(res => res.data.searchProjects));
  }

  private getFoundationForStatisticsQuery(
    id: string,
    filter?: string
  ): QueryRef<FoundationResult> {
    const query = this.apollo.watchQuery<FoundationResult>({
      query: foundationForStatisticsQuery,
      variables: {
        id,
        filter,
      },
    });

    return query;
  }
}
