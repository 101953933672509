import { mockList } from '@lfx/core/mocks/generator';
import * as faker from 'faker';

import { randomEnum } from '../mocks/generator';
import {
  ConversationThread,
  generateConversationThread,
} from './mailing-list-chat';
import { mockProfile, Profile } from './profile';

export enum UserGroupType {
  Board = 'Governing Board',
  Committee = 'Committee',
  SIG = 'Special Interest Group',
  // eslint-disable-next-line @typescript-eslint/camelcase
  Working_Group = 'Working Group',
  Project = 'Project',
  Other = 'Other',
  Technical = 'Technical',
  Marketing = 'Marketing',
  Finance = 'Finance',
}

export enum AppointedBy {
  Community = 'community',
  BoardVote = 'board',
  SilverVote = 'silver',
  GoldVote = 'gold',
  GeneralMemberVote = 'general',
  EndUserMemberVote = 'enduser',
  AssociateVote = 'associate',
  TOCVote = 'toc',
  TSCVote = 'tsc',
  EndUserCommitteeVote = 'enduser',
  MarketingCommitteeVote = 'marketing',
  MembershipEntitlement = 'membership',
  AcademicVote = 'academic',
  LabVote = 'lab',
}

export enum UserGroupRole {
  ViceChair = 'vicechair',
  Chair = 'chair',
  Treasurer = 'treasurer',
  Secretary = 'secretary',
  Developer = 'developer',
  Lead = 'lead',
  Counsel = 'counsel',
}

export enum VotingStatus {
  Observer = 'observer',
  VotingRep = 'voting',
  AltVotingRep = 'alt-voting',
}

export enum UserGroupStatus {
  Pending = 'Pending',
  Active = 'Active',
  Inactive = 'Inactive',
  Retired = 'Retired',
}

export enum MemberStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export interface UserGroup {
  id: string;
  name: string;
  description: string;
  collaborationName: string;
  category: UserGroupType;
  formationDate?: string;
  dissolutionDate?: string;
  status: UserGroupStatus;
  url: string;
  members: UserGroupMember[];
  votingEnabled: boolean;
  conversations?: ConversationThread[];
}

export const generateUserGroup = (category?: UserGroupType): UserGroup => ({
  id: faker.random.uuid(),
  name: `${faker.hacker.noun()} Commitee`,
  description: faker.hacker.phrase(),
  collaborationName: faker.random.number(),
  category: category || randomEnum(UserGroupType),
  status: UserGroupStatus.Active,
  url: faker.internet.url(),
  members: mockList(generateUserGroupMember),
  votingEnabled: faker.random.boolean(),
  conversations: mockList(generateConversationThread),
});

export interface UserGroupMember extends Profile {
  appointedBy?: AppointedBy;
  role?: UserGroupRole;
  roleStartDate?: Date;
  roleEndDate?: Date;
  votingStatus: VotingStatus;
  votingStartDate?: Date;
  votingEndDate?: Date;
  status: MemberStatus;
}

export const generateUserGroupMember = (): UserGroupMember => {
  const user = mockProfile();

  return {
    ...user,
    votingStatus: randomEnum(VotingStatus),
    status: MemberStatus.Active,
  };
};
