import gql from 'graphql-tag';

export const claGroupsForProjectListQuery = gql`
  query claGroupsForProjectListQuery(
    $organizationId: String!
    $projectId: String!
    $organizationInternalId: String
  ) {
    claGroupsForProjectList(
      organizationId: $organizationId
      projectId: $projectId
      organizationInternalId: $organizationInternalId
    ) {
      signedClaList {
        cclaUrl
        projectLogo
        projectName
        projectSfid
        projectType
        signatoryName
        signedOn
        claGroupName
        subProjects
      }
      unsignedProjectList {
        canSign
        claGroupId
        claGroupName
        projectName
        projectSfid
        cclaEnabled
        iclaEnabled
        subProjects
      }
    }
  }
`;
