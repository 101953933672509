import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  authClientConfig,
  authClientToken,
  localStorageToken,
  socialAuthClientConfig,
  socialAuthClientToken,
} from '@config';
import { environment } from '@environment';
import { AppComponent } from '@lfx/core/containers/app.component';
import { CoreModule } from '@lfx/core/core.module';
import { ApolloClientsModule } from '@lfx/apollo-clients.module';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { WebAuth } from 'auth0-js';
import { NgxPermissionsModule } from 'ngx-permissions';

import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './modules/auth/auth.module';
import { metaReducers, reducers } from './state';
import { InitService } from './init.service';

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    CoreModule,
    ApolloClientsModule,
    AuthModule,
    AppRoutingModule,
    NgxPermissionsModule.forRoot(),
    /**
     * StoreModule.forRoot is imported once in the root module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),

    /**
     * EffectsModule.forRoot() is imported once in the root module and
     * sets up the effects class to be initialized immediately when the
     * application starts.
     *
     * See: https://ngrx.io/guide/effects#registering-root-effects
     */
    EffectsModule.forRoot([]),

    /**
     * @ngrx/router-store keeps router state up-to-date in the store.
     */
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
    }),

    /**
     * Store devtools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */
    !environment.production
      ? StoreDevtoolsModule.instrument({
          name: 'LFX',
          // In a production build you would want to disable the Store Devtools
          logOnly: environment.production,
          maxAge: 30,
        })
      : [],
  ],
  providers: [
    {
      provide: socialAuthClientToken,
      useValue: new WebAuth(socialAuthClientConfig),
    },
    {
      provide: authClientToken,
      useValue: new WebAuth(authClientConfig),
    },
    { provide: localStorageToken, useValue: localStorage },
    { provide: 'googleTagManagerId', useValue: environment.gtmId },
    {
      provide: APP_INITIALIZER,
      useFactory: (initService: InitService) => () => initService.init(),
      deps: [InitService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
