import gql from 'graphql-tag';

export const searchUserQuery = gql`
  query searchUser($input: SearchUserInput) {
    searchUser(input: $input) {
      id
      firstName
      lastName
      primaryEmail
      username
      emails {
        emailAddress
        isPrimary
      }
    }
  }
`;
