import gql from 'graphql-tag';

export const organizationQuery = gql`
  query organization($salesforceId: ID!) {
    organization(salesforceId: $salesforceId) {
      id
      description
      billingAddress {
        billingCity
        billingCountry
        billingPostalCode
        billingState
        billingStreet
      }
      github
      gitlab
      industry
      logoUrl
      name
      phone
      source
      type
      website
      createdAt
      employees
      lastModifiedAt
      lastModifiedBy
      link
      owner {
        id
        name
      }
      ownerId
    }
  }
`;
