import gql from 'graphql-tag';

export const vulnerabilityUniqueLicensesDetailsQuery = gql`
  query vulnerabilityUniqueLicensesDetails(
    $input: VulnerabilityPackagesDetailsQueryInput
  ) {
    vulnerabilityUniqueLicensesDetails(input: $input) {
      licenses {
        name
        dependencyCount
      }
      totalDependencies
      totalRecords
    }
  }
`;
