import gql from 'graphql-tag';

export const allFoundationsQuery = gql`
  query allFoundations($withMembersData: Boolean = false) {
    foundations {
      id
      name
      creationDate
      description
      logoUrl
      colorBand
      tag
      autoJoinEnabled
      projectType
      members @include(if: $withMembersData) {
        name
        logoUrl
        tier {
          name
        }
      }
      community {
        contributors
        commits
        members {
          id
          name
          description
          logoUrl
          membershipTier
        }
      }
      organization {
        contributors
        commits
        totalCodeContribution
      }
      projects {
        badgeUrl
        autoJoinEnabled
        enabledServices
        membershipAutorenew
        name
        parent
        status
        category
        createdDate
        endDate
        id
        modifiedDate
        projectType
        systemModStamp
        type
        description
        logoUrl
        colorBand
        tag
        community {
          contributors
          commits
        }
        organization {
          contributors
          commits
          totalCodeContribution
        }
        groupName
      }
      website
    }
  }
`;
