import gql from 'graphql-tag';

export const deleteUserRoleScopeMutation = gql`
  mutation deleteUserRoleScope($input: DeleteUserRoleScopeInput!) {
    deleteUserRoleScope(input: $input) {
      code
      message
      success
    }
  }
`;
