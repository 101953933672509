import gql from 'graphql-tag';

export const projectVulnerabilityOverviewQuery = gql`
  query getProjectVulnerabilityOverview(
    $vulnerabilityOverviewInput: VulnerabilityOverviewInput!
  ) {
    projectVulnerabilityOverview(
      vulnerabilityOverviewInput: $vulnerabilityOverviewInput
    ) {
      lastScannedAt
      openIssues {
        high {
          fixableCount
          totalCount
        }
        medium {
          fixableCount
          totalCount
        }
        low {
          fixableCount
          totalCount
        }
        total {
          fixableCount
          totalCount
        }
      }
      overTimeIssues {
        label
        total
        fixable
        severity
        month
        quarter
        year
      }
    }
  }
`;
