import gql from 'graphql-tag';

export const requestCorporateSignatureMutation = gql`
  mutation requestCorporateSignature($input: RequestClaSignatureInput!) {
    requestCorporateSignature(input: $input) {
      code
      message
      success
      data {
        signatureId
        signUrl
      }
    }
  }
`;
