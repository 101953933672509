import gql from 'graphql-tag';

export const membershipContactsQuery = gql`
  query membershipContacts($input: MembershipContactsInput!) {
    membershipContacts(input: $input) {
      data {
        role
        primaryContact
        contact {
          email
          firstName
          id
          lastName
          photoUrl
        }
        id
      }
    }
  }
`;
