import gql from 'graphql-tag';

export const rolesQuery = gql`
  query roles($roleInput: FilterInput) {
    roles(input: $roleInput) {
      roleId
      roleName
      roleTitle
      isAdmin
      description
      roleObjects {
        objectRoleId
        roleId
        objectId
        objectName
      }
    }
  }
`;
