import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import {
  allProjectsQuery,
  projectQuery,
  projectCommitteesQuery,
  projectStatisticsQuery,
  projectForStatisticsQuery,
  projectResourcesQuery,
  projectMeetingsQuery,
  lfSponsoredProjectsQuery,
  projectActivityLogsQuery,
  projectCommitteeCategoriesQuery,
} from '../queries';
import {
  AllProjectsResult,
  ProjectResult,
  UpdateProjectEssentialsResult,
  CreateNewProjectPayload,
  CreateNewProjectResult,
  ProjectCommitteesResult,
  CreateProjectCommitteesResult,
  UpdateProjectCommitteeResult,
  DeleteProjectCommitteeResult,
  ProjectStatisticsResult,
  ProjectResourceResult,
  ProjectMeetingResult,
  LfSponsoredProjectsResult,
  ProjectActivityLogResult,
  ProjectCommitteesCategoriesResult,
} from './results';
import { Observable } from 'rxjs';
import {
  FoundationProject,
  UserGroup,
  Statistics,
  Resource,
  Meeting,
  ActivityLogObject,
} from '@models';
import { map } from 'rxjs/operators';
import {
  UpdateProjectEssentialsInput,
  CreateNewProjectInput,
  CreateProjectCommitteesInput,
  UpdateProjectCommitteeInput,
  DeleteProjectCommitteeInput,
  ProjectActivityLogsInput,
} from './inputs';
import {
  updateProjectEssentialsMutation,
  createNewProjectMutation,
  createProjectCommitteesMutation,
  updateProjectCommitteeMutation,
  deleteProjectCommitteeMutation,
} from '../mutations';

@Injectable({
  providedIn: 'root',
})
export class ProjectServiceGql {
  private projectStatisticsQuery: QueryRef<ProjectResult> = null;

  constructor(private apollo: Apollo) {}

  getProjectForStatistics(id, filter) {
    if (!this.projectStatisticsQuery) {
      this.projectStatisticsQuery = this.getProjectForStatisticsQuery(
        id,
        filter
      );
    } else {
      this.projectStatisticsQuery.setVariables({
        id,
        filter,
      });
      this.projectStatisticsQuery.refetch();
    }

    return this.projectStatisticsQuery.valueChanges.pipe(
      map(res => res.data.project)
    );
  }

  creatNewProject(
    projectInfo: CreateNewProjectInput
  ): Observable<CreateNewProjectPayload> {
    return this.apollo
      .mutate<CreateNewProjectResult>({
        mutation: createNewProjectMutation,
        variables: {
          projectInfo,
        },
      })
      .pipe(map(res => res.data.createNewProject));
  }

  getAllProjects(): Observable<FoundationProject[]> {
    return this.apollo
      .watchQuery<AllProjectsResult>({
        query: allProjectsQuery,
      })
      .valueChanges.pipe(map(res => res.data.projects));
  }

  getProject(
    id: string,
    withCommitteesData = false
  ): Observable<FoundationProject> {
    return this.apollo
      .watchQuery<ProjectResult>({
        query: projectQuery,
        variables: {
          id,
          withCommitteesData,
        },
      })
      .valueChanges.pipe(map(res => res.data.project));
  }

  updateProjectEssential(
    projectEssential: UpdateProjectEssentialsInput
  ): Observable<FoundationProject> {
    return this.apollo
      .mutate<UpdateProjectEssentialsResult>({
        mutation: updateProjectEssentialsMutation,
        variables: {
          projectEssential,
        },
      })
      .pipe(map(res => res.data.updateProjectEssentials.project));
  }

  getProjectCommittees(id: string): Observable<UserGroup[]> {
    return this.apollo
      .watchQuery<ProjectCommitteesResult>({
        query: projectCommitteesQuery,
        variables: {
          id,
        },
      })
      .valueChanges.pipe(map(res => res.data.project.committees));
  }

  createProjectCommittees(
    committeesInfo: CreateProjectCommitteesInput
  ): Observable<UserGroup[]> {
    return this.apollo
      .mutate<CreateProjectCommitteesResult>({
        mutation: createProjectCommitteesMutation,
        variables: {
          committeesInfo,
        },
      })
      .pipe(map(res => res.data.createProjectCommittees.committees));
  }

  updateProjectCommittee(
    committeeInfo: UpdateProjectCommitteeInput
  ): Observable<UserGroup> {
    return this.apollo
      .mutate<UpdateProjectCommitteeResult>({
        mutation: updateProjectCommitteeMutation,
        variables: {
          committeeInfo,
        },
      })
      .pipe(map(res => res.data.updateProjectCommittee.committee));
  }

  deleteProjectCommittee(
    committeeInfo: DeleteProjectCommitteeInput
  ): Observable<boolean> {
    return this.apollo
      .mutate<DeleteProjectCommitteeResult>({
        mutation: deleteProjectCommitteeMutation,
        variables: {
          committeeInfo,
        },
      })
      .pipe(map(res => res.data.deleteProjectCommittee._empty));
  }

  getProjectMeetings(id: string): Observable<Meeting[]> {
    return this.apollo
      .watchQuery<ProjectMeetingResult>({
        query: projectMeetingsQuery,
        variables: {
          id,
        },
      })
      .valueChanges.pipe(map(res => res.data.projectMeeting));
  }

  getProjectResources(id: string): Observable<Resource[]> {
    return this.apollo
      .watchQuery<ProjectResourceResult>({
        query: projectResourcesQuery,
        variables: {
          id,
        },
      })
      .valueChanges.pipe(map(res => res.data.projectResource));
  }

  getProjectStatistics(id: string): Observable<Statistics> {
    return this.apollo
      .watchQuery<ProjectStatisticsResult>({
        query: projectStatisticsQuery,
        variables: {
          id,
        },
      })
      .valueChanges.pipe(map(res => res.data.projectStatistics));
  }

  getLfSponsoredProjects(): Observable<FoundationProject[]> {
    return this.apollo
      .watchQuery<LfSponsoredProjectsResult>({
        query: lfSponsoredProjectsQuery,
      })
      .valueChanges.pipe(map(res => res.data.lfSponsoredProjects));
  }

  getActivityLogs(
    input: ProjectActivityLogsInput,
    useCache: boolean
  ): Observable<ActivityLogObject> {
    return this.apollo
      .watchQuery<ProjectActivityLogResult>({
        query: projectActivityLogsQuery,
        fetchPolicy: !useCache ? 'no-cache' : 'cache-and-network',
        variables: {
          input,
        },
      })
      .valueChanges.pipe(map(res => res.data.projectActivityLogs));
  }

  getCommitteesCategories() {
    return this.apollo
      .watchQuery<ProjectCommitteesCategoriesResult>({
        query: projectCommitteeCategoriesQuery,
      })
      .valueChanges.pipe(map(res => res.data.projectsCommitteeCategory));
  }

  private getProjectForStatisticsQuery(
    id: string,
    filter?: string
  ): QueryRef<ProjectResult> {
    const query = this.apollo.watchQuery<ProjectResult>({
      query: projectForStatisticsQuery,
      variables: {
        id,
        filter,
      },
    });

    return query;
  }
}
