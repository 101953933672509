import gql from 'graphql-tag';

export const deleteProjectCommitteeMutation = gql`
  mutation deleteProjectCommittee(
    $committeeInfo: DeleteProjectCommitteeInput!
  ) {
    deleteProjectCommittee(input: $committeeInfo) {
      code
      message
      success
    }
  }
`;
