import gql from 'graphql-tag';

export const deleteFoundationCommitteeMutation = gql`
  mutation deleteFoundationCommittee($committeeInfo: DeleteCommitteeInput!) {
    deleteCommittee(input: $committeeInfo) {
      code
      message
      success
    }
  }
`;
