import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CollaborationService {
  constructor(private httpClient: HttpClient) {}

  // public getCollaborations(): Observable<MemberCollaboration> {
  //   const route = `${environment.apiUrl}/dashboard/collaborations`;
  //   return this.httpClient.get<MemberCollaboration>(route);
  // }
}
