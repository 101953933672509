import gql from 'graphql-tag';

export const projectQuery = gql`
  query project($id: ID!, $withCommitteesData: Boolean = false) {
    project(id: $id) {
      badgeUrl
      autoJoinEnabled
      enabledServices
      membershipAutorenew
      name
      parent
      status
      category
      createdDate
      endDate
      id
      modifiedDate
      projectType
      systemModStamp
      type
      description
      logoUrl
      colorBand
      tag
      community {
        contributors
        commits
      }
      foundation {
        id
        name
      }
      committees @include(if: $withCommitteesData) {
        id
        category
        collaborationName
        description
        url
        name
      }
      organization {
        contributors
        commits
        totalCodeContribution
      }
      groupName
      opportunityOwner {
        firstName
        lastName
      }
      programManager {
        firstName
        lastName
      }
      owner {
        lastName
        firstName
      }
    }
  }
`;
