import gql from 'graphql-tag';

export const updateMembershipContactMutation = gql`
  mutation updateMembershipContact($input: UpdateMembershipContactInput!) {
    updateMembershipContact(input: $input) {
      message
      code
      success
      contact {
        role
        primaryContact
        contact {
          id
        }
      }
    }
  }
`;
